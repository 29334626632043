<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-4xl font-redHat font-bold">Form Beban Operasional</h2>
        </div>
        <form @submit.prevent>
            <div class="mb-20 px-6">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                    <!-- <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nama Produk</label>
                        <input type="text" v-model="currentData.name"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required>
                    </div> -->
                    <div class="">
                        <label class="block font-redHat text-[#8F   8F8F] font-medium font-2xl mb-2">No Form</label>
                        <input type="text" v-model="currentData.no_form"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" disabled>
                    </div>

                    <div class="">
                        <label class="block font-redHat text-black font-medium font-2xl mb-2">Tanggal Transaksi</label> <!-- #8F8F8F -->
                        <input type="date" v-model="currentData.date" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"  placeholder="" required>
                        <!-- <multiselect v-model="currentData.id_category" :options="dataCategory"
                            :custom-label="nameWithLangCategory" placeholder="" label="name" track-by=""
                            :selectLabel="''" :deselectLabel="''" class="multiselect-full-width"></multiselect> -->
                    </div>

                    <div class="">
                        <label class="block font-redHat text-black font-medium font-2xl mb-2">Nama Tempat</label><!-- #8F8F8F -->
                        <input type="text" v-model="currentData.name" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"  placeholder="" required>
                        <!-- <multiselect v-model="currentData.type_sale" :options="dataTipeProduk"
                            :custom-label="nameWithLang" placeholder="" label="name" track-by="" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect> -->
                    </div>

                    <div class="">
                        <label class="block font-redHat text-black font-medium font-2xl mb-2">Jumlah</label><!-- [#8F8F8F] -->

                        <input type="text" v-model="currentData.amount" @input="formatCurrency('amount', this.currentData.amount)" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"  placeholder="Rp." required>
                        <!-- <multiselect v-model="currentData.id_supplier" :options="dataSupplier"
                            :custom-label="nameWithLang" placeholder="" label="name" track-by="" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect> -->

                    </div>
                    
                    
                    <!-- <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Harga Jual Net</label>
                        <input type="text" v-model="currentData.net_price"
                            @input="formatCurrency('net_price', currentData.net_price)"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-"
                            required>
                    </div> -->

                </div>

                <div class="grid grid-cols-1 md:grid-cols-12 gap-0 mb-4">

                    <div class="col-span-12">
                        <label class="block font-redHat text-black font-medium font-2xl mb-2">Keterangan</label> <!-- [#8F8F8F] -->
                        <input type="text" v-model="currentData.info" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2 " placeholder="">
                        <!-- <input type="number" step="0.01" v-model="currentData.weight"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="0" required> -->

                        <!-- Absolute positioned "Kg" text -->
                        <!-- <span class="absolute right-6 top-10 text-gray-500">gram</span> -->
                    </div>

                </div>


            
                <!-- <div class="relative overflow-x-auto">
                    
                </div> -->
                <!-- <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Varian
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Keterangan
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    QTY
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Biaya Tambahan
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Aksi
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                v-for="(item, index) in currentData.product_detail">
                                <th scope="row"
                                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <input type="text" v-model="item.color"
                                        class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                                        @keydown.enter="focusNext" required>
                                </th>
                                <td class="px-6 py-2 d-flex gap-2" v-for="(sizeItem, sizeIndex) in item.sizeqty">
                                    <div class="w-full">
                                        <label
                                            class="block font-redHat text-[#8F8F8F] font-medium font-2xl text-xs mb-2">size</label>
                                        <input type="text" v-model="sizeItem.size"
                                            @input="sizeChange(index, sizeIndex, 'size', sizeItem.size)"
                                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                                            @keydown.enter="focusNext" required>
                                    </div>
                                    <div>
                                        <label
                                            class="block font-redHat text-[#8F8F8F] font-medium font-2xl text-xs mb-2">qty</label>
                                        <input type="number" v-model="sizeItem.qty"
                                            @input="qtyChange(index, sizeIndex, 'qty', sizeItem.qty)"
                                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                                            @keydown.enter="focusNext2(index, sizeIndex, $event)" required>

                                    </div>
                                    <div class="w-full">
                                        <label
                                            class="block font-redHat text-[#8F8F8F] font-medium font-2xl text-xs mb-2">Add-on
                                            price</label>
                                        <input type="text" v-model="sizeItem.price"
                                            @input="formatCurrency2(index, sizeIndex, 'price', sizeItem.price)"
                                            @keydown.enter="addProductSizeAndFocus(index, $event);"
                                            v-show="checkingLastRowProductDetail(index, sizeIndex) && checkingMaxRowProductDetail(index) && !checkSizeDisabled()"
                                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                            placeholder="Rp. 0,-" required>

                                        <input type="text" v-model="sizeItem.price"
                                            @input="formatCurrency2(index, sizeIndex, 'price', sizeItem.price)"
                                            @keydown.enter="focusNext"
                                            v-show="!(checkingLastRowProductDetail(index, sizeIndex) && checkingMaxRowProductDetail(index) && !checkSizeDisabled())"
                                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                            placeholder="Rp. 0,-" required>
                                    </div>

                                    <div class="flex flex-column justify-center">
                                        <div v-show="checkingLastRowProductDetail(index, sizeIndex) && checkingMaxRowProductDetail(index) && !checkSizeDisabled()"
                                            @click="addProductSize(index);" class="cursor-pointer"> <i
                                                class="bi bi-plus-circle text-[15px] text-[#5FBAE6]"></i></div>

                                        <div v-show="checkingMinRowProductDetail(index) && !checkSizeDisabled()"
                                            @click="deleteProductSize(index, sizeIndex);" class="cursor-pointer"><i
                                                class="bi bi-trash text-danger"></i></div>
                                    </div>
                                </td>

                                <td class="">
                                    <div class="flex me-2">
                                        <button type="button" v-if="checkingLastRow(index)" @click="addProductDetailRow"
                                            class="icon w-full h-full ">
                                            <i class="bi bi-plus-circle text-[#5FBAE6]"></i>
                                        </button>
                                        <button type="button" @click="openModalDelete(index)"
                                            class="inline-flex items-center gap-x-2 text-lg font-semibold ms-2 text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none"
                                            :disabled="currentData.product_detail.length === 1">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>

                                </td>
                            </tr>

                        </tbody>
                    </table> -->


                <!-- <div class="grid grid-cols-12 gap-4 mb-3" v-for="(item, index) in currentData.product_detail"
                    :key="index"> -->



                <!-- <div class=" col-span-3 me-3">
                        <label for="warna"
                            class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Warna</label>
                        <input type="text" v-model="item.color"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="warna" placeholder="">
                    </div>
                    <div class=" col-span-8 ">
                        <div class="block mb-3">
                            <label for="size"
                                class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Size</label>
                            <div class="inline-block" v-for="(sizeItem, sizeIndex) in item.sizeqty" :key="sizeIndex">
                                <div class="d-flex">
                                    <input type="text" v-model="sizeItem.size"
                                        class=" w-14 border-1 border-[#5FBAE6] rounded-md p-2 me-2 text-xl text-center text-black"
                                        name="size" placeholder="" :disabled="checkSizeDisabled()">
                                    <div class="d-flex flex-column">
                                        <div v-show="checkingLastRowProductDetail(index, sizeIndex) && checkingMaxRowProductDetail(index) && !checkSizeDisabled()"
                                            @click="addProductSize(index);" class="cursor-pointer"> <i
                                                class="bi bi-plus-circle text-[15px] text-[#5FBAE6]"></i></div>
                                        <div v-show="checkingLastRowProductDetail(index, sizeIndex) && checkingMinRowProductDetail(index) && !checkSizeDisabled()"
                                            @click="deleteProductSize(index, sizeIndex);" class="cursor-pointer"><i
                                                class="bi bi-trash text-danger"></i>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="block">
                            <label for="size"
                                class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Qty</label>
                            <div class="inline-block" v-for="(sizeItem, sizeIndex) in item.sizeqty" :key="sizeIndex">
                                <input type="number" min="0" v-model="sizeItem.qty"
                                    class=" w-14 border-1 border-[#5FBAE6] rounded-md p-2 me-2 text-xl text-center"
                                    name="size" placeholder="">
                            </div>

                        </div>


                    </div> -->

                <!-- <div class="flex items-center justify-center">
                        <button type="button" v-if="checkingLastRow(index)" @click="addProductDetailRow"
                            class="icon w-full h-full ">
                            <i class="bi bi-plus-circle text-[40px] text-[#5FBAE6]"></i>
                        </button>
                        <button type="button" @click="openModalDelete(index)"
                            class="inline-flex items-center gap-x-2 text-lg font-semibold ms-2 text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none"
                            :disabled="currentData.product_detail.length === 1">
                            <i class="bi bi-trash"></i>
                        </button>
                    </div> -->

                <!-- </div> -->




            </div>

            <div class="mb-5 space-x-5 flex justify-end px-10">
                <button type="button" @click="Cancel"
                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">
                    Batal</button>
                <button type="button" @click="insert"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
            </div>
        </form>


    </div>
    <!-- modal delete -->
    <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <!-- Modal content -->
        <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full" @click.stop>
            <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold">Delete Produk</h2>
                <button @click="closeModal" class="text-red-600 hover:text-gray-900">
                    ✕
                </button>
            </div>
            <p class="text-gray-700 mb-10">
                Apakah anda yakin ingin menghapus data ini ?
            </p>

            <div class="space-y-3">
                <div aria-hidden="true" class="border-t px-2"></div>
                <div class="button-group flex justify-end">
                    <button @click="closeModal"
                        class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
                        Close
                    </button>
                    <button @click="confirmDelete"
                        class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.text-xs {
    font-size: 11px !important;
}
</style>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, rupiahInput, rupiahoutput, currencyInput } from "@/assets/js/function";
// import {ShowLoading, CloseLoading} from "@/assets/js/function";
import Cookies from "js-cookie";
// import Multiselect from 'vue-multiselect';
// import { setLocale } from 'yup';


export default {
    components: {
        NavBar,
        // Multiselect,

    },

    data() {
        return {
            currentData: {
                date: this.getTodayDate(),
            },
            // dataTipeProduk: [
            //     { name: "STORY IG", value: "STORY_IG" },
            //     { name: "LIVE IG", value: "LIVE_IG" },
            // ],
            // dataSupplier: [],
            // dataCategory: [],
            // isModalOpen: false,
            // currentDeleteIndex: null,
            // statusLoad: false,
            isSamePriceSelected: false,
            isDifferentPriceSelected: true,
            currency: "",

            normalPrice: true,
            nettPrice: false,
            // newRecord: '',
        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }

        // this.dataSupplier = JSON.parse(localStorage.getItem('data_supplier'));
        // this.dataCategory = JSON.parse(localStorage.getItem('data_category'));
        // this.currency = localStorage.getItem('currency');

        // if (localStorage.getItem('product_size') != null && localStorage.getItem('product_size') != undefined && localStorage.getItem('product_size') != '' && localStorage.getItem('product_size') != '[]') {
        //     const product_default = JSON.parse(localStorage.getItem('product_size'));
        //     let item_prepare = {};
        //     item_prepare.color = '';
        //     item_prepare.sizeqty = product_default;
        //     this.currentData.product_detail.push(item_prepare);

        // } else {

        //     this.currentData.product_detail.push({
        //         color: '',
        //         sizeqty: [
        //             { size: "FS", qty: 0, price: 0 },
        //         ]
        //     })



        // }

        // console.log(this.config);
        // this.index();
        this.prepareData()
    },

    // updated() {
    //     this.statusLoad = true;
    // },
    

    // computed: {
    //     isSamePrice() {
    //         return this.selectedCheckbox === "samePrice";
    //     },
    //     isDifferentPrice() {
    //         return this.selectedCheckbox === "differentPrice";
    //     },
    // },

    methods: {
        // checkOnlyOne(selected) {
        //     if (selected === "samePrice") {
        //         this.isSamePriceSelected = true;
        //         this.isDifferentPriceSelected = false;
        //         this.currentData.product_detail.forEach((item, index) => {
        //             item.sizeqty = [{ size: "FS", qty: 0, price: 0 }];
        //         });
        //     } else if (selected === "differentPrice") {
        //         this.isSamePriceSelected = false;
        //         this.isDifferentPriceSelected = true;

        //         let id = 0;
        //         if (this.currentData.id_category != undefined) {
        //             id = this.currentData.id_category.id;
        //         }


        //         const index = this.dataCategory.findIndex((record) => record.id == id);
        //         if (index > -1) {

        //             const sizeArray = this.dataCategory[index].size.split(", ");
        //             const dataSize = sizeArray.map(size => ({ size: size, qty: 0, price: 0 }));


        //             this.currentData.product_detail.forEach((item, index) => {
        //                 item.sizeqty = dataSize
        //             });

        //         } else {

        //             this.currentData.product_detail.forEach((item, index) => {
        //                 item.sizeqty = [{ size: "FS", qty: 0, price: 0 }];
        //             });

        //         }


        //     }
        // },
        // focusNext(event) {
        //     const inputs = Array.from(document.querySelectorAll('input')); // Get all input elements
        //     const currentIndex = inputs.indexOf(event.target); // Find the current input's index

        //     if (currentIndex !== -1 && inputs[currentIndex + 1]) {
        //         // Focus on the next input if it exists
        //         inputs[currentIndex + 1].focus();
        //     }
        // },
        // focusNext2(index, index2, event) {

        //     let plus = 1;
        //     if (this.checkingLastRowProductDetail(index, index2) == false) {
        //         plus = 2;
        //     }

        //     const inputs = Array.from(document.querySelectorAll('input')); // Get all input elements
        //     const currentIndex = inputs.indexOf(event.target); // Find the current input's index

        //     if (currentIndex !== -1 && inputs[currentIndex + plus]) {
        //         // Focus on the next input if it exists
        //         inputs[currentIndex + plus].focus();
        //     }
        // },
        // // addProductDetail() {
        // //     this.currentData.product_detail.push({
        // //         color: "",
        // //     });
        // // },
        // removeProductDetail(index) {
        //     if (this.currentData.product_detail.length > 1) {
        //         this.currentData.product_detail.splice(index, 1);
        //     }
        // },

        getTodayDate() {
            const today = new Date();
            const yyyy = today.getFullYear();
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
            const dd = String(today.getDate()).padStart(2, '0');
            return `${yyyy}-${mm}-${dd}`; // Format: YYYY-MM-DD
        },

        prepareData() {
            this.currentData = JSON.parse(localStorage.getItem('currentData'));

            this.currentData.amount = rupiahInput(this.currentData.amount)

            // customer(old) / bidang industri
            // const needData1 = JSON.parse(localStorage.getItem('needData1'));
            // console.log(needData1)
            // needData1.forEach((item, index) => {
            //     const element = { value: item.id, text: item.nama }
            //     // console.log(element)
            //     this.needData1.push(element);
            // });
        },


        // AddSupplier() {
        //     localStorage.setItem('historyPage', 'addproduk');
        //     goPage('addsupplier');
        // },
        // handleCategoryChange() {
        //     this.dataCategory
        // },
        Cancel() {
            // localStorage.removeItem('product_size', 'test');
            goPage('bebanoperasional');

        },
        // nameWithLang({ name }) {
        //     return `${name}`
        // },
        // nameWithLangCategory({ name }) {
        //     return `${name}`
        // },
        formatCurrency(label, number) {
            this.currentData[label] = rupiahInput(number);
        },
        // formatForeignCurrency(label, number) {
        //     this.currentData[label] = currencyInput(number, this.currency);
        // },
        // formatCurrency2(index, sizeIndex, label, number) {
        //     this.currentData.product_detail[index].sizeqty[sizeIndex][label] = rupiahInput(number);
        // },
        
        
        insert() {
            
            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            

            // formData.delete("product_detail");
            // formData.append("product_detail", JSON.stringify(data));
            // formData.delete("cost_price");
            // formData.append('cost_price', rupiahoutput(this.currentData.cost_price));
            // formData.delete("normal_price");
            // formData.append('normal_price', rupiahoutput(this.currentData.normal_price));
            formData.delete("amount");
            formData.append('amount', rupiahoutput(this.currentData.amount));
            formData.append('id_preorder', localStorage.getItem('id_preorder'));

            // if (this.isSamePriceSelected == true) {
            //     formData.append("type", 'SAME_PRICE');
            // } else {
            //     formData.append("type", 'DIFFERENT_PRICE');
            // }

            // if (this.normalPrice == true) {
            //     formData.append("type_price", 'NORMAL_PRICE');
            // } else {
            //     formData.append("type_price", 'NET_PRICE');
            // }

            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            // CloseLoading()
            // return true

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'operational_expense_update';
            } else {
                this.api = base_url + 'operational_expense_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        goPage("bebanoperasional");
                        // localStorage.removeItem('product_size');
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        openModalDelete(index) {
            this.currentDeleteIndex = index;

            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        confirmDelete() {
            // Handle the actual deletion here
            if (this.currentDeleteIndex !== null) {
                this.currentData.product_detail.splice(this.currentDeleteIndex, 1);  // Delete the item
                this.closeModal();  // Close the modal after deletion
            }
        },
    },
}
</script>