<template>

  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 h-screen bg-[#5FBAE6] overflow-y-hidden">
    <div class="left-side bg-[#5FBAE6] flex justify-center items-center py-4 hidden md:flex">

      <div class="flex flex-col items-center">
        <div class="top-logo-banner">
          <img src="@/assets/img/logo-hwang.png" alt="logo-hwang" class="h-[128px] w-[128px]">
        </div>
        <div class="banner-img">

          <img src="@/assets/img/login-img-edit.png" alt="banner-login" class="h-[465px] w-[812px]">
        </div>
      </div>
    </div>
    <div class="right-side p-4 md:p-0 lg:p-0">
      <div class="flex flex-col items-center  h-full pt-2 justify-center">
        <div class="w-full bg-[#F5CA40F2]/[0.95] mt-8 rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div class="text-center mb-8 pt-6">
              <h1 class="text-sm  md:text-[50px] font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                login
              </h1>
            </div>
            <form class="" @submit.prevent="onSubmit">
              <div class="mb-3">
                <label for="username" class="block mb-2 text-lg font-redHat text-gray-900 ">Username</label>
                <input type="text" v-model="username" name="username" id="username"
                  class="bg-gray-50 border border-white text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                  placeholder="" required="">

                <!-- <div class="invalid-feedback">{{ errors.email }}</div> -->
                <!-- <div class="emailshow text-danger" id="email"></div>
                            <div class="position-absolute me-3 end-0 top-0 h-100 d-flex align-items-center"
                            v-if="!(errors.email)">
                            <i class='bx bx-envelope text-secondary' alt="img"></i>
                            </div> -->
              </div>
              <div>
                <label for="password"
                  class="block mb-2 text-lg font-redHat  text-gray-900 dark:text-white">Password</label>
                <!-- <input type="password" v-model="password" name="password" id="password" placeholder=""
                  class="bg-gray-50 border border-white text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 mb-8"
                  required=""> -->
                <div class="relative">
                  <input :type="showPassword ? 'text' : 'password'" v-model="password" name="password" id="password"
                    placeholder=""
                    class="bg-gray-50 border border-white text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 mb-8"
                    required="">
                  <button type="button" @click="toggleShow"
                    class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <!-- <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2.94 2.94a1 1 0 011.42 0L17.06 15.06a1 1 0 01-1.42 1.42L2.94 4.36a1 1 0 010-1.42z" />
                        <path d="M3.78 10c.733-1.262 2.258-3.125 6.22-3.125S15.487 8.738 16.22 10c-.733 1.262-2.258 3.125-6.22 3.125S4.513 11.262 3.78 10z" />
                      </svg> -->
                    <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                      fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                      <path
                        d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                      <path
                        d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-eye-fill" viewBox="0 0 16 16">
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                      <path
                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                    </svg>
                    <!-- <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.522 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.478 0-8.268-2.943-9.542-7z" />
                      </svg> -->
                  </button>
                </div>

              </div>

              <!-- for test only -->
              <!-- <RouterLink :to="{ name: 'dashboard' }">
                        </RouterLink> -->
              <div class="mb-8">
                <button type="submit"
                  class="w-full mb-6 text-black bg-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-[20px] text-sm px-5 py-2.5 text-center">Masuk</button>

              </div>

            </form>
            <div class="privacy-policy mb-8 text-center flex flex-col items-center">
              <RouterLink to="forgotpassword" class="text-center mb-4 font-redHat text-md">Forgot Password?</RouterLink>
              <p class="text-center">By continuing, you are agree to our <br>
                <a href="#" class="font-bold hover:text-white">Privacy Policy</a>
              </p>
            </div>
            <div class="scbd-bottom flex items-center justify-center mt-8">
              <img src="@/assets/img/mini-scbd-logo.png" alt="scbd-mini" class="w-[96px] h-[22px]">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>


<script>
// import { ref } from "vue";
// import { useStore } from "vuex";
// import { Form, } from "vee-validate";
// import { router } from "../../../router";
// import VueRouter from "vue-router";
// import * as Yup from "yup";
// import { ref } from 'vue';
import axios from "axios";
// import Cookies from "js-cookie";
import Swal from 'sweetalert2';

import { goPage, ShowLoading, CloseLoading, base_url, ErrorConnectionTimeOut, AlertPopup } from "@/assets/js/function";
import Cookies from "js-cookie";

export default {

  data() {
    return {
      showPassword: false,
      password: null,
      username: "",
      //   useremailphone: "",
      //   showAdmin: true,
      //   showOwner: false,
      //   bodyAllowNotification: "",
      //   statusAllow: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },


  created() {
    // const token = Cookies.get("token_refresh");
    this.config = {
      timeout: 100000,
    }

    localStorage.setItem('id_preorder', '');
    localStorage.removeItem('records', '');
    localStorage.removeItem('records_lo', '');
    // this.index();
  },

  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    // setTipeLogin(status) {
    //   ShowLoading();
    //   localStorage.setItem('tipe_login', status); goPage('dashboard');
    // },


    onSubmit() {
      ShowLoading(); // Show a loading spinner or message

      // Set the API based on the environment

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'login';
      } else {
        this.api = base_url + 'login';
      }

      // Create FormData object
      let formData = new FormData();
      formData.append('username', this.username);
      formData.append('password', this.password);

      // Make a POST request to the login API
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          // Check if login is successful
          if (response.data.status === true) {
            // Login successful, handle response data
            Swal.fire('Success', 'Login successful', 'success');

            // You can store token or user data here if needed
            // localStorage.setItem('token', response.data.token);
            Cookies.set("token", response.data.token);
            localStorage.setItem('username', response.data.name);
            localStorage.setItem('type', response.data.type);

            if (response.data.preorder_count > 0) {
              goPage('dashboard');

            } else {
              goPage('pobatch')
            }

            // Redirect to dashboard or another page
            // this.$router.push({ name: 'dashboard' });


            CloseLoading(); // Close loading spinner
          } else if (response.data.status === false) {
            // Handle login failure
            CloseLoading(); // Close loading spinner
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          // Handle any errors such as network issues or server downtime
          CloseLoading(); // Close loading spinner
          ErrorConnectionTimeOut(error);
        });
    },


    showModal(modal_name) {
      this.$refs[modal_name].classList.add('show');
      this.$refs[modal_name].style.display = 'block';
    },
    hideModal(modal_name) {
      this.$refs[modal_name].classList.remove('show');
      this.$refs[modal_name].style.display = 'none';

    },

    // signUp() {

    //   window.open('https://arthaskyline.com/contact.html', "_blank");
    //   CloseLoading();

    // },

    // changePassword() {
    //   ShowLoading();
    //   let formData = new FormData();
    //   formData.append("phone", this.useremailphone);

    //   let api = "";
    //   if (process.env.NODE_ENV === "development") {
    //     api = base_url + 'send_otp';
    //   } else {
    //     api = base_url + 'send_otp';
    //   }
    //   axios
    //     .post(api, formData)
    //     .then((response) => {
    //       console.log(response.data);
    //       if (response.data.status == true) {
    //         Cookies.set("token", response.data.token);
    //         Cookies.set("useremailphone", this.useremailphone);
    //         goPage('dashboard');
    //       }

    //       if (response.data.status == false) {
    //         AlertPopup("error", "", response.data.message, 1500, false);
    //       }
    //     })
    //     .catch((error) => {

    //       ErrorConnectionTimeOut(error);
    //     });

    // }
  },


};

</script>