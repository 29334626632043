<template>
  <NavBar />
  <header v-if="showAll" class="lg:px-16 px-5 bg-white flex flex-wrap items-center py-3">
    <div class="flex-1 flex justify-between items-center">
      <div class="relative">
        <a href="javascript.void(0)" class="block text-3xl font-redHat text-[#5FBAE6] font-bold">
          Laporan Laba Rugi
        </a>
        <!-- <p class="block text-xl font-redHat text-[#F5CA40] font-bold">Verifikasi Data</p> -->
      </div>
      <!-- <p class="block text-xl font-redHat text-[#F5CA40] font-bold">Story Instagram</p> -->
    </div>

    <div class="hidden md:flex md:items-center md:w-auto w-full" id="menu">
      <nav>

        <ul class="md:flex items-center justify-between text-base text-gray-700 pt-3 md:pt-0 gap-1">

          <!-- <li>
              <div
                class="relative flex items-center p-0 rounded-[8px] w-[250px] max-w-lg h-[40px] border-[0.5px] border-[#00000]">
                <input type="text" v-model="searchQuery" @input="filterTable"
                  class="rounded-[8px] p-2.5 px-6 w-full h-full focus:border-blue-500 focus:ring focus:ring-blue-200 focus:outline-none"
                  name="search" placeholder="Cari">
  
                <button type="submit"
                  class="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 bg-white  p-2 rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                  </svg>
                </button>
              </div>
            </li> -->

          <li v-if="this.records && this.records.length > 0">
            <div @click="convertToExcel"
              class="font-redHat text-base  h-[40px] w-[150px] rounded-md text-green-600  border-2 border-green-600 me-2 cursor-pointer">
              <div class="flex justify-center items-center h-full gap-2">
                <img src="../../../assets/img/icon_excel.png" alt="">
                Export Excel
              </div>
            </div>
          </li>

          <!-- <li>
              <Datepicker v-model="dateRange" range="true" lang="id" class="" position="right" />
            </li> -->

          <!-- <div class="relative p-0 rounded-[20px] w-[250px] max-w-lg"> -->
          <!-- <input type="text" class="rounded-[20px] p-3 w-full" name="search" placeholder="Cari"> -->
          <!-- </div> -->
          <!-- <li>
                <RouterLink to="addcustomer"
                  class="md:p-4 py-3 block flex items-center justify-center w-[200px] h-[40px] rounded-md bg-[#50C996] text-black py-2.5 font-semibold"
                >
                  Customer Baru
                </RouterLink>
              </li> -->

          <!-- <input type="date" v-model="date" class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" name="date"> -->
          <!-- <li>
              <button @click="filterDropdown = !filterDropdown" id="dropdownHoverButton"
                class="md:p-4 py-3 px-0 block flex items-center justify-center w-[100px] h-[40px] rounded-md bg-[#F5CA40] text-black px-6 py-2.5 font-semibold gap-2">
                Filter By <i class="bi bi-funnel-fill"></i>
              </button>
              <ul v-if="filterDropdown"
                class="absolute transform -translate-x-2  bg-slate-100 shadow-lg mt-2 rounded-lg w-30 space-y-2 p-1"> 
                <li
                  class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 hover:rounded-lg cursor-pointer"
                  :class="{ 'bg-[#5FBAE6]/80 rounded-lg': selectedType === 'STORY_IG' }"
                  @click="filterRecords('STORY_IG')">
                  <div>List Order</div>
                </li>
                <li
                  class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 hover:rounded-lg cursor-pointer"
                  :class="{ 'bg-[#5FBAE6]/80 rounded-lg': selectedType === 'LIVE_IG' }" @click="filterRecords('LIVE_IG')">
                  <div>Live Instagram</div>
                </li>
              </ul>
            </li> -->
        </ul>
      </nav>
    </div>
  </header>

  <div class="px-5 pt-5 mb-2" v-if="showAll">
    <!-- <div class="flex flex-col">
                <div class=" overflow-x-auto">
                    <div class="p-1.5 min-w-full inline-block align-middle">
                        <div class="">
                        </div>
                    </div>
                </div>
            </div> -->

    <!-- <div class="flex justify-between items-center mb-2">
            <p class="text-[#8F8F8F]">PO TANGGAL 17 Oktober 2024</p>
            <p class="text-[#8F8F8F] ms-auto">SALDO</p>
        </div> -->

    <div class="mb-44">
      <div class="space-y-4">
        <!-- Pendapatan Section -->
        <div class="bg-[#F7F7F7] p-4 rounded-md">
          <p class="font-bold">Pendapatan</p>
        </div>
        <div class="space-y-2 ">
          <div class="flex justify-between border-b border-[#8F8F8F] pl-[55px] pr-5 py-2">
            <span>Penjualan Live Ig</span>
            <span>{{ rupiah(currentData.total_penjualan_live) }}</span>
          </div>
          <div class="flex justify-between border-b border-[#8F8F8F] pl-[55px] pr-5 py-2">
            <span>Penjualan Story Ig</span>
            <span>{{ rupiah(currentData.total_penjualan_story) }}</span>
            <!-- <span>Rp. 21.000.000,-</span> -->
          </div>
          <div class="flex justify-between border-b border-[#8F8F8F] pl-[55px] pr-5 py-2">
            <span>Diskon Penjualan</span>
            <span>( {{ rupiah(currentData.total_discount) }})</span>
          </div>
          <div class="flex justify-between border-b border-[#8F8F8F] pl-[55px] pr-5 py-2">
            <span>Total Penjualan</span>
            <span class="font-bold">{{ rupiah(currentData.total_penjualan) }}</span>
          </div>
        </div>

        <!-- Pengeluaran Section -->
        <div class="bg-[#F7F7F7] p-4 rounded-md">
          <p class="font-bold">Pengeluaran</p>
        </div>
        <div class="space-y-2">
          <div class="flex justify-between border-b border-[#8F8F8F] pl-[55px] pr-5 py-2">
            <span>Modal Usaha</span>
            <span>( {{ rupiah(currentData.total_modal) }})</span>
          </div>
          <div class="flex justify-between border-b border-[#8F8F8F] pl-[55px] pr-5 py-2">
            <span>Total Beban Operasional</span>
            <span>( {{ rupiah(currentData.total_biaya) }} )</span>
          </div>
        </div>

        <!-- Laba Rugi Usaha Section -->
        <div class="bg-[#F7F7F7] p-4 rounded-md font-bold">
          <div class="flex justify-between ">
            <span>Laba Rugi Usaha</span>
            <span>{{ rupiah(currentData.profit_loss) }}</span>
          </div>
        </div>
      </div>

      <!-- <table>
                <thead>
                    <tr class="bg-">
                        <th>Pendapatan</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table> -->
    </div>






  </div>








</template>

<script>
import NavBar from '@/components/NavBar.vue'
import axios from "axios";
// import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, rupiahInput, rupiah, goPage, DateFormat } from "@/assets/js/function";
// import {ErrorConnectionTimeOut,  ShowLoading, CloseLoading, base_url, AlertPopup,  rupiahInput,  rupiah } from "@/assets/js/function";
import Cookies from "js-cookie";
// import Multiselect from 'vue-multiselect';
// import 'vue-datepicker-ui/lib/vuedatepickerui.css';
// import VueDatepickerUi from 'vue-datepicker-ui';
import XLSX from "xlsx/dist/xlsx.full.min.js";

export default {
  components: {
    NavBar,
    // Multiselect,
    // Datepicker: VueDatepickerUi
  },

  data() {
    return {
      searchQuery: '',

      records: [
        {
          total_deposit: '',
        }
      ],
      currentData: [

      ],
      listStatusPesanan: [],
      isModalOpen: false,
      currentDeleteIndex: null,
      config: '',
      filterDropdown: false,
      selectedType: 'LIVE_IG',

      date: '',
      dateRange: [
        new Date(),
        new Date(new Date().getTime() + 9 * 24 * 60 * 60 * 1000)],

      showAll: true,

    }
  },

  watch: {
    // date: {
    //   handler(newVal) {
    //     this.index();
    //   },
    // }
    // dateRange: {
    //   handler(newVal) {
    //     this.index();
    //   },
    // }
  },

  created() {
    const token = Cookies.get("token");
    // console.log(token)
    this.config = {
      headers: { Authorization: `Bearer ` + token },
      timeout: 100000,
    }

    const date = new Date();
    this.date = date.toISOString().split('T')[0]; // Output: '2024-05-01'


    // console.log(this.config);
    this.index();
    // this.prepareData();


    // this.showMultiselect = false;

    // Load from localStorage when the component is created
    // const savedLiveIg = localStorage.getItem('records');
    // if (savedLiveIg) {
    // this.records = JSON.parse(savedLiveIg); // Load saved data into records
    // console.log('Stored records:', JSON.parse(savedLiveIg));
    // }else {
    // console.log('No records found in localStorage.');
    // }
  },

  computed: {
    filteredRecords() {
      // Check if searchQuery is valid (not undefined or empty string)
      if (!this.searchQuery || this.searchQuery.length < 4) {
        return this.currentData;
        // return this.records;
      }

      // Proceed with filtering if searchQuery is valid
      // return this.records.filter(item => {
      return this.currentData.filter(item => {
        const searchLower = this.searchQuery.toLowerCase();
        return (
          (item.total_penjualan_live ? item.total_penjualan_live.toLowerCase().includes(searchLower) : false) ||
          (item.total_penjulan_story ? item.total_penjulan_story.toLowerCase().includes(searchLower) : false) ||
          (item.total_discount ? item.total_discount.toLowerCase().includes(searchLower) : false) ||
          (item.total_penjualan ? item.total_penjualan.toLowerCase().includes(searchLower) : false) ||
          (item.total_modal ? item.total_modal.toLowerCase().includes(searchLower) : false) ||
          (item.total_biaya ? item.total_biaya.toLowerCase().includes(searchLower) : false) ||
          (item.profit_loss ? item.profit_loss.toLowerCase().includes(searchLower) : false)
          // (item.price ? String(item.price).includes(searchLower) : false)
        );
      });
    }

  },


  // mounted() {
  //     this.$root.initializeDataTable(); 

  // },

  methods: {
    rupiah,

    nameWithLang({ name }) {
      return `${name}`;
    },
    // handleOrderChange(item, index) {
    //   ShowLoading();
    //   let formData = new FormData();

    //   Object.entries(item).forEach(([key, value]) => {   // for looping in table
    //     formData.append(key, value);
    //   });

    //   // for (let [key, value] of formData.entries()) {
    //   //     console.log(`${key}: ${value}`);
    //   // }
    //   formData.delete('id_order_status');
    //   formData.append('id_order_status', item.id_order_status.id);

    //   if (process.env.NODE_ENV === "development") {
    //     this.api = base_url + 'live_ig_update';
    //   } else {
    //     this.api = base_url + 'live_ig_update';
    //   }

    //   axios
    //     .post(this.api, formData, this.config)
    //     .then((response) => {
    //       // console.log(response)
    //       if (response.data.status == true) {
    //         AlertBottom(response.data.message);

    //         CloseLoading();
    //       }
    //       if (response.data.status == false) {
    //         this.records[index].status_order = '';
    //         AlertPopup("error", "", response.data.message, 1500, false);
    //       }
    //     })
    //     .catch((error) => {
    //       ErrorConnectionTimeOut(error);
    //     });
    // },

    // addRow() {
    //   // Mark the row as "Added"
    //   //   this.record[index].isAdded = true

    //   // Add a new row with a unique id
    //   this.records.push({
    //     id_customer: '',
    //     id_produk: '',
    //     color: '',
    //     size: '',
    //     qty: '',
    //     price: '',
    //     deposit: '',
    //     isAdded: false
    //   })
    // },

    // getIconClass(item) {
    //   // Determine the icon class based on whether the row is the last one
    //   // return item.total_deposit > item.price ? 'bi bi-check-circle-fill text-gray-400' : 'bi bi-check-circle-fill text-[#50C996]' ;
    //   // console.log(item.total_deposit)
    //   // console.log(item.price)
    //   if (item.total_deposit >= item.price) {
    //     //return 'bi bi-check-circle-fill text-[#50C996]'; // Green verified
    //     return true
    //   }
    //   else {
    //     //return 'bi bi-check-circle-fill text-gray-400'; // Gray unverified
    //     return false
    //   }
    // },

    // checkingLastRow(index) {
    //     if(index == this.records.length - 1) {
    //         // this.records[index].isAdded = true;
    //         return true;

    //             // this.records.splice(index + 1, 0, );
    //     }else {
    //         // this.records[index].isAdded = false;
    //         return false;

    //     }
    // },

    formatCurrency(label, number, index) {
      this.records[index][label] = rupiahInput(number);

    },


    addNumberToObjects(objArray) {
      // const { total_biaya, total_discount, total_penjualan_live, total_penjualan_story, total_modal, profit_loss, total_penjualan, ...rest } = objArray[i];  // Extract fields to be renamed
      let dataArray = []

      const newObj = {

        "Penjualan Live IG": objArray.total_penjualan_live,  // Rename id_customer to customer_id
        "Penjualan Story IG": objArray.total_penjualan_story,  // Rename id_customer to customer_id
        "Diskon Penjualan": objArray.total_discount,  // Rename id_customer to customer_id
        "Total Penjualan": objArray.total_penjualan,
        "Modal Usaha": objArray.total_modal,
        "Total Beban Operasional": objArray.total_biaya,// - qty_pending
        "Laba Rugi Usaha": objArray.profit_loss,


      }

      // dataArray.push(newObj)

      // Convert to an array of arrays for horizontal layout
      // for (const [key, value] of Object.entries(newObj)) {
      //   dataArray.push([key, value]); // Each row: [Label, Value]
      // }

      for (const [key, value] of Object.entries(newObj)) {
        if (key === "Total Penjualan" || key === "Laba Rugi Usaha") {
          // Push an empty value for column B and the value into column C
          dataArray.push([key, null, value]);
        } else {
          // Default: Push the value into column B
          dataArray.push([key, value, null]);
        }
      }


      return dataArray;

    },
    convertToExcel() {
      ShowLoading();



      // Create a deep copy of `this.records` to avoid modifying the original data
      // const data = JSON.parse(JSON.stringify(this.records));
      // const data = JSON.parse(JSON.stringify(this.filteredRecords));
      // console.log('cek currentData aja:', this.currentData)
      const data = this.currentData;
      const ArrExport = this.addNumberToObjects(data);

      if (ArrExport.length === 0) {
        throw new Error('No valid data after processing.');
      }



      // for (let i = 0; i < ArrExport.length; i++) {
      //   delete ArrExport[i]["id"];
      //   delete ArrExport[i]["category"];
      //   delete ArrExport[i]["cost_price"];
      //   delete ArrExport[i]["supplier_name"];
      //   delete ArrExport[i]["name"];
      //   delete ArrExport[i]["id_supplier"];
      //   delete ArrExport[i]["id_product"];
      //   delete ArrExport[i]["id_category"];
      //   delete ArrExport[i]["qty_buy"];
      //   delete ArrExport[i]["qty_pending"];
      //   delete ArrExport[i]["buy_status"];
      // }

      // const footerRow = {
      //   No: '', // Leave the No column empty for the footer
      //   Supplier: '', // Empty for footer
      //   Kategori: '', // Empty for footer
      //   "Kode Produk": '', // Empty for footer
      //   Varian: '', // Empty for footer
      //   Size: 'TOTAL', // Empty for footer
      //   "Qty": this.totalQty,// Total of qty_buy
      //   "Qty pending": '', // Empty for footer
      //   "Qty diterima": '',
      //   "Harga Modal": '', // Empty for footer
      //   Total: this.currencyInput(this.total, this.currency), // Total cost formatted as currency
      //   Status: '' // Empty for footer
      // };

      // ArrExport.push(footerRow);

      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Create a new worksheet
      // const ws = XLSX.utils.json_to_sheet(ArrExport);
      const ws = XLSX.utils.aoa_to_sheet(ArrExport);

      // Adjust column widths for better readability
      ws['!cols'] = [
        { wch: 30 }, // Column A width (for labels)
        { wch: 20 }, // Column B width (for values)
        { wch: 20 }, // Column C width (for values)
      ];
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'REKAP');
      // Convert the workbook to an XLSX file and download it
      XLSX.writeFile(wb, 'Laporan Laba Rugi' + ".xlsx");
      CloseLoading();
    },


    prepareData() {
      // this.records.total_deposit = rupiahInput(this.records.total_deposit);
      this.records.forEach((item) => {
        item.total_deposit = rupiahInput(item.total_deposit);
      });

    },
    editData(item) {
      // for batal edit customer page
      localStorage.setItem('currentData', JSON.stringify(item));
      goPage('editverifikasiliveig');
    },
    editCustomer(item) {
      // for batal edit customer page
      this.$router.push({ name: 'editcustomer' });
      sessionStorage.setItem('lastPage', 'verifikasi-live-ig');

      // console.log(item)
      localStorage.setItem('currentData', JSON.stringify(item.customer_detail));

      // Navigate to the Add Customer page
      this.$router.push({ name: 'editcustomer' });
    },

    filterRecords(type) {
      this.selectedType = type; // Save the selected filter type
      this.index(); // Call the index method to fetch filtered data
    },

    index() {
      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'))

      // const startDate = this.dateRange[0];
      // const StartformattedDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;
      // formData.append('date_from', StartformattedDate);

      // const beforeDate = this.dateRange[1];
      // const BeforeformattedDate = `${beforeDate.getFullYear()}-${String(beforeDate.getMonth() + 1).padStart(2, '0')}-${String(beforeDate.getDate()).padStart(2, '0')}`;
      // formData.append('date_end', BeforeformattedDate);

      // formData.append('type', 'LIVE_IG');
      // formData.delete("total_deposit");
      // formData.append('total_deposit', rupiahoutput(item.total_deposit));

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'report_profit_loss';
      } else {
        this.api = base_url + 'report_profit_loss';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          console.log(response.data)
          if (response.data.status === true) {

            this.showAll = true;

            this.currentData = response.data.data[0];

            // console.log('cek :', this.currentData)
            // Step 1: Calculate total prices for each customer
            // const totalAmounts = this.records.reduce((acc, order) => {
            //   const { id_customer, amount } = order;

            //   // Initialize the total amount if the id_customer doesn't exist in acc
            //   if (!acc[id_customer]) {
            //     acc[id_customer] = 0; // Initialize total amount
            //   }

            //   // Accumulate total amount
            //   acc[id_customer] += amount;

            //   return acc;
            // }, {});


            // Step 2: Create a new array and insert orders and totals
            // const result = [];

            // let number = 1;
            // // Iterate through the original orders
            // for (let i = 0; i < this.records.length; i++) {
            //   // console.log(this.records[i]);
            //   const order = this.records[i];
            //   if (this.records[i - 1] != undefined && this.records[i - 1].id_customer == this.records[i].id_customer) {
            //     order.number = '';
            //   } else {
            //     order.number = number;
            //     number++;
            //   }
            //   result.push(order); // Push the original order

            //   const { id_customer } = order;

            //   // Check if this is the last order for this customer
            //   const isLastOrder = (i === this.records.length - 1) || (this.records[i + 1].id_customer !== id_customer);

            //   // If it is the last order for this customer, add the total row
            //   if (isLastOrder) {
            //     const totalOrder = {
            //       id: 0, // Set id to 0
            //       id_customer: id_customer,
            //       instagram: order.instagram,
            //       name: order.name,
            //       name_account: "",
            //       deposit_date: "",
            //       amount: totalAmounts[id_customer], // Total price for this customer
            //     };

            //     result.push(totalOrder); // Insert total order immediately after the last order for this customer
            //   }
            // }
            // // console.log(result);
            // this.records = result;

            // // this.filteredRecords = this.records.filter(
            // //   (item) => item.type === this.selectedType
            // // );


            // this.dataCustomer = response.data.customer; //to fetch customer
            // this.dataProduct = response.data.product; //to fetch product
            // localStorage.setItem('data_status_order', JSON.stringify(response.data.status_order));
            // this.listStatusPesanan = response.data.status_order;
            // this.listStatusPesanan.push({ id: 0, name: 'PENDING' });
            // this.dataColor = response.data.data; //to fetch color
            //this.dataSize = response.data.data; //to fetch size

            // this.records.forEach((item) => {
            //   const index = this.listStatusPesanan.findIndex((status) => status.id == item.id_order_status); // Use `status` instead of `item`
            //   if (index !== -1) {
            //     item.id_order_status = this.listStatusPesanan[index];  // Assign the matched status to item.id_order_status
            //   }
            // });





            // save to local storage
            // localStorage.setItem('data_supplier', JSON.stringify(response.data.data_supplier));
            // localStorage.getItem('id_preorder')
            // localStorage.setItem('data_product_detail', JSON.stringify(response.data.product_detail));


            // if (response.data.status == true) {
            //     this.data = [];

            //     response.data.data.forEach((item, index) => {
            //         let item_prepare = { ...item, no: (index + 1), };
            //         this.data.push(item_prepare);
            //     });

            //     CloseLoading();
            // }


            CloseLoading();
          } else {
            CloseLoading();
            this.showAll = false;
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          this.showAll = false;
          ErrorConnectionTimeOut(error);
        });
    },

    openModalDelete() {
      // this.currentDeleteIndex = index;

      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
      this.id_terminate = null;
    },

    delete2() {
      ShowLoading();
      let formData = new FormData();
      formData.append("id", this.id_terminate);


      // for (let [key, value] of formData.entries()) {
      //     console.log(`${key}: ${value}`);
      // }
      // CloseLoading()
      // return true;

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'live_ig_delete';
      } else {
        this.api = base_url + 'live_ig_delete';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {
            const index = this.records.findIndex((item) => item.id === this.id_terminate); // Find item in the array by ID
            this.records.splice(index, 1); // Remove the item from the array
            CloseLoading();
            this.closeModal(); // Close modal after deletion
            AlertBottom(response.data.message);

          } else {
            AlertPopup("error", "", response.data.message, 1500, false);
            // goPage('dashboard')
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });


    },

    terminate(id) {
      this.id_terminate = id;
      this.openModalDelete();
      // this.tanggal_terminate = new Date();

    },

    //   openModalDelete(index) {
    //       this.currentDeleteIndex = index;

    //       this.isModalOpen = true;
    //   },
    //   closeModal() {
    //       this.isModalOpen = false;
    //   },
    //   confirmDelete() {
    //       // Handle the actual deletion here
    //       if (this.currentDeleteIndex !== null) {
    //           this.records.splice(this.currentDeleteIndex, 1);  // Delete the item
    //           this.closeModal();  // Close the modal after deletion
    //       }
    //   },
  }
}
</script>

<style scoped>
/* Adjust positioning and z-index for the multiselect dropdown */
.multiselect__content {
  position: absolute;
  z-index: 9999;
  top: 100%;
}

.multiselect-full-width .multiselect__select {
  width: 100%;
}

.no-arrows {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: none;
  /* Remove default appearance */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* Chrome, Safari, Edge */
  margin: 0;
  /* Remove margin */
}
</style>