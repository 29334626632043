<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Perbaharui PO</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6">
                <div class="mb-4">
                    <label for="no-po" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nomor
                        PO</label>
                    <input type="text" v-model="currentData.no_po"
                        class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="no-po"
                        :placeholder="currentData.no_po" disabled>

                </div>
                <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 mb-4 ">
                    <div class="">
                        <div class="w-100">

                            <label for="kode-produk"
                                class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Periode PO</label>
                            <div class="flex gap-3 items-center w-100">

                                <div class="w-1/2">

                                    <input type="date" name="date" id="date" v-model="currentData.start_date"
                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                </div>
                                <div class="w-fill">
                                    -
                                </div>
                                <div class="w-1/2">

                                    <!-- <label for="kode-produk" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Periode PO</label> -->
                                    <input type="date" name="date" id="date" v-model="currentData.end_date"
                                        :min="currentData.start_date"
                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- <div class="mb-5 ">
                        <label for="date" class="mb-3 block text-base font-medium text-[#07074D]">
                            for
                        </label>
                        <input type="date" name="date" id="date"
                            class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                    </div> -->

                </div>

                <div class="mb-3">
                    <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Lokasi PO</label>
                    <multiselect v-model="currentData.id_location" :options="dataLokasi" :custom-label="nameWithLang"
                        placeholder="" label="name" track-by="" :selectLabel="''" :deselectLabel="''"
                        class="multiselect-full-width"></multiselect>
                </div>
                <div class="mb-3">
                    <label for="dropdown" class="block font-redHat font-2xl font-medium text-[#8F8F8F]">Mata
                        Uang</label>
                    <input type="text" v-model="currency" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                        :placeholder="currency ? currency : 'Currency will appear here'" disabled>

                </div>
                <div class="d-flex ">
                    <div class="mb-3 w-1/2 me-2">
                        <label for="dropdown"
                            class="block font-redHat font-2xl font-medium text-[#8F8F8F]">Currency</label>
                        <input type="text" v-model="currency2"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                            :placeholder="currency2 ? currency2 : 'Currency will appear here'" disabled>
                    </div>
                    <div class="mb-3 w-1/2">
                        <label for="dropdown" class="block font-redHat font-2xl font-medium text-[#8F8F8F]">Currency
                            Rate (Rp)</label>
                        <input type="number" v-model="currentData.currency_rate" step="0.01"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="0" />
                    </div>
                </div>


            </div>

            <div class="mb-3 space-x-5 flex justify-end px-10">
                <RouterLink to="po"
                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">
                    Batal</RouterLink>
                <button type="submit"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
            </div>
        </form>


    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, rupiahInput, rupiahoutput, rupiah } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect';

export default {
    components: {
        NavBar,
        Multiselect,

    },

    data() {
        return {
            // currentData: [],
            dataLokasi: [],
            currentData: {
                id_location: null, // This will store the selected location ID
            },
            // selectedLocationId: null,
            currency: '',
            currency2: '',
            // newRecord: '',
        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }

        //get data
        this.currentData = JSON.parse(localStorage.getItem('currentData'));

        // console.log(this.config);
        // this.index();
        this.dataLokasi = JSON.parse(localStorage.getItem('data_location'));

        const index = this.dataLokasi.findIndex((item) => item.id === this.currentData.id_location);
        // setelah dpt maka bakal masukin data lokasi dengan index yg ditemukan ke dlm id_location
        this.currentData.id_location = this.dataLokasi[index];



    },

    watch: {
        'currentData.id_location'(newVal) {
            // When id_location changes, find the corresponding location
            // console.log(newVal)
            const selectedLocation = this.dataLokasi.find(location => location.id === newVal.id);

            // If the location is found, update the currency
            if (selectedLocation) {
                this.currency = selectedLocation.currency;
                this.currency2 = selectedLocation.currency.split(" ")[0] + ' 1';
            } else {
                this.currency = ''; // Reset currency if no location is selected
                this.currency2 = '';
            }

        }
    },

    methods: {
        nameWithLang({ name }) {
            return `${name}`
        },

        formatCurrency(label, number) {
            this.currentData[label] = rupiahInput(number);
        },

        getCurrentDate() {
            const today = new Date();
            // Format the date to YYYY-MM-DD
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        insert() {
            ShowLoading();
            let formData = new FormData();


            Object.entries(this.currentData).forEach(([key, value]) => {   // for looping in table
                formData.append(key, value);
            });

            formData.delete("id_location");
            formData.append("id_location", this.currentData.id_location.id)

            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'preorder_update';
            } else {
                this.api = base_url + 'preorder_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        goPage("po");
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },
}
</script>