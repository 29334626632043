<template>
    <NavBar />
    <!-- <h1>Dashboard </h1> -->
    <header class="w-full bg-[#5FBAE6] h-[350px] px-10 pt-12">
        <div class="flex align-middle justify-between w-full ">
            <div class="left-content w-3/4">
                <div class="flex items-center space-x-1 gap-2">
                    <h1 class="text-xl text-black font-redHat mb-1">Dashboard</h1>
                    <button class="text-gray-500 hover:text-gray-700" @click="pilihLokasiBatch()"><i
                            class="bi bi-pencil-fill"></i></button>
                    <button class="text-gray-500 hover:text-gray-700"
                        v-if="currentData.currentPo && currentData.currentPo.status_po === 'AKTIF'"
                        @click="openClosePo('TIDAK')"><i class="bi bi-lock-fill"></i></button>
                    <button class="text-gray-500 hover:text-gray-700"
                        v-else="currentData.currentPo && currentData.currentPo.status_po === 'TIDAK' "
                        @click="openClosePo('AKTIF')"><i class="bi bi-unlock-fill"></i></button>
                </div>



                <h1 class="text-5xl text-black font-redHat font-bold">PO {{ selectedBatch?.location_name || '' }}</h1>

                <div class="mt-4">
                    <div v-if="currentData.currentPo && currentData.currentPo.status_po === 'AKTIF'" :class="[
                        'w-[225px] max-h-[47px] py-2 px-3 flex justify-center items-center text-center rounded-[10px] text-black font-medium font-redHat',
                        'bg-green-500'
                    ]">
                        PO Open
                    </div>
                    <div v-else="currentData.currentPo && currentData.currentPo.status_po === 'TIDAK' " :class="[
                        'w-[225px] max-h-[47px] py-2 px-3 flex justify-center items-center text-center rounded-[10px] text-black font-medium font-redHat',
                        'bg-red-500'
                    ]">
                        PO Close
                    </div>

                </div>
            </div>

            <!-- button dashboard -->
            <div class="right-content  justify-end pt-7 text-end">
                <!-- <h1>button</h1> -->
                <!-- <div class="flex justify-end">
                    <div class="relative p-0 rounded-[20px] w-[425px] max-w-lg mb-3 ">
                        <form @submit.prevent="searchCustomerInOrder">
                            <input type="text" v-model="query" class="rounded-[20px] p-3 w-full" name="search"
                                placeholder="Cari nama customer">
                            <button type="submit" class="absolute right-6 top-3">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </button>
                        </form>
                    </div>
                </div> -->
                <!-- button group -->

                <h1 class="text-3xl text-black font-redHat font-bold mt-3 w-full mb-4"
                    v-if="selectedBatch.start_date != undefined">{{ selectedBatch?.no_po || '' }}
                    ({{
                        formatDateRange(selectedBatch.start_date, selectedBatch.end_date) }})</h1>
                <div class="flex flex-row gap-3 justify-end mb-2">

                    <!-- <RouterLink to="liveig"
                        class="w-[225px] max-h-[47px] py-2 px-3 flex justify-center items-center text-center  bg-[#F5CA40] rounded-[10px] text-black font-medium font-redHat">
                        Live Instagram</RouterLink> -->
                    <RouterLink to="liveig"
                        class="w-[225px] max-h-[47px] py-2 px-3 flex justify-center items-center text-center  bg-[#F5CA40] rounded-[10px] text-black font-medium font-redHat"
                        :class="{ 'pointer-events-none opacity-50': currentData.currentPo.status_po === 'TIDAK' }">
                        Live Instagram</RouterLink>
                    <RouterLink to="addlistorder"
                        class="w-[225px] max-h-[47px] py-2 px-3 flex justify-center items-center text-center  bg-[#F5CA40] rounded-[10px] text-black font-medium font-redHat"
                        :class="{ 'pointer-events-none opacity-50': currentData.currentPo.status_po === 'TIDAK' }">
                        Story Instagram</RouterLink>
                    <RouterLink to="listorder"
                        class="w-[225px] max-h-[47px] py-2 px-3 flex justify-center items-center text-center  bg-[#F5CA40] rounded-[10px] text-black font-medium font-redHat">
                        List Orderan </RouterLink>
                </div>

            </div>
        </div>
    </header>

    <!-- <DashboardCard></DashboardCard> -->

    <div class="relative h-64">
        <div class="absolute -top-40 bg-none h-screen w-full px-6 pt-10">

            <!-- grid card customer -->
            <div class="card-title pl-8">
                <h5 class=" text-black text-3xl font-redHat font-bold">Customer</h5>
            </div>

            <div class="grid gap-4 lg:gap-8 md:grid-cols-3 p-8">

                <!-- Customer Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] font-bold w-24 text-black flex justify-center items-center">
                            <i class="bi bi-graph-up"></i>
                            <!-- text-[#07975A] old color -->
                        </div>
                        <div class="space-y-0">
                            <div class="text-4xl font-redHat text-black font-bold">
                                {{ currentData.summary_new.customer_count }}
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Total Customer</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Deposit Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] text-black font-bold w-24 flex items-center justify-center">
                            <i class="bi bi-graph-up"></i>
                        </div>
                        <div class="space-y-0 ">
                            <div class="font-redHat text-black font-bold text-4xl">
                                <!-- {{ rupiah(currentData.summary.deposit_count) }} -->
                                {{ currentData.summary_new.customer_blm_deposit }}
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Belum Deposit</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Done Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] text-black font-bold w-24 flex justify-center items-center">
                            <i class="bi bi-graph-up"></i>
                            <!-- text-red-700 old color -->
                        </div>
                        <div class="space-y-0">
                            <div class="font-redHat text-black font-bold text-4xl">
                                <!-- {{ rupiah(currentData.summary.on_sales) }} -->
                                {{ currentData.summary_new.customer_pending }}
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Total Item</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid gap-4 lg:gap-8 md:grid-cols-3 p-8">

                <!-- Done Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] text-black font-bold w-24 flex justify-center items-center">
                            <i class="bi bi-graph-up"></i>
                            <!-- text-red-700 -->
                        </div>
                        <div class="space-y-0">
                            <div class="font-redHat text-black font-bold text-4xl">
                                <!-- {{ rupiah(currentData.summary.on_sales) }} -->
                                {{ currentData.summary_new.customer_ok }} <span
                                    class="text-[19px] text-black font-redHat"> / {{
                                        currentData.summary_new.customer_pending }} </span>
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Pending</h1>
                            </div>
                        </div>
                    </div>
                </div>



                <!-- Deposit Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] text-black font-bold w-24 flex items-center justify-center">
                            <i class="bi bi-graph-up"></i>
                        </div>
                        <div class="space-y-0 ">
                            <div class="font-redHat text-black font-bold text-4xl">
                                <!-- {{ rupiah(currentData.summary.deposit_count) }} -->
                                {{ currentData.summary_new.customer_soldout }}
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Sold Out</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Customer Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] font-bold w-24 text-black flex justify-center items-center">
                            <i class="bi bi-graph-up"></i>
                            <!-- text-[#07975A] -->
                        </div>
                        <div class="space-y-0">
                            <div class="text-4xl font-redHat text-black font-bold">
                                {{ currentData.summary_new.customer_keep }}
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Ok</h1>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <!-- end of card grid customer -->

            <!-- grid for live istagram summary data -->
            <div class="card-title pl-8">
                <h5 class=" text-black text-3xl font-redHat font-bold">Live Instagram</h5>
            </div>

            <!-- Grid for the summary data -->
            <div class="grid gap-4 lg:gap-8 md:grid-cols-3 p-8">

                <!-- Customer Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] font-bold w-24 text-black flex justify-center items-center">
                            <i class="bi bi-graph-up"></i>
                            <!-- text-[#07975A] old color -->
                        </div>
                        <div class="space-y-0">
                            <div class="text-4xl font-redHat text-black font-bold">
                                {{ currentData.summary_new.liveig_product }}
                                <!-- 0 -->

                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Produk</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Deposit Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] text-black font-bold w-24 flex items-center justify-center">
                            <i class="bi bi-graph-up"></i>

                        </div>
                        <div class="space-y-0 ">
                            <div class="text-4xl font-redHat text-black font-bold">
                                <!-- {{ rupiah(currentData.summary.deposit_count) }} -->
                                {{ currentData.summary_new.liveig_product_weight }} <span>Kg</span>
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Est. Berat</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Done Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] text-black font-bold w-24 flex justify-center items-center">
                            <i class="bi bi-graph-up"></i>
                            <!-- text-red-700 old color -->
                        </div>
                        <div class="space-y-0">
                            <div class="font-redHat text-black font-bold text-4xl">
                                <!-- {{ rupiah(currentData.summary.on_sales) }} -->
                                {{ currentData.summary_new.liveig_product_item }}
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Total Item</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Second Section: Instagram Story -->
            <div class="card-title pl-8">
                <h5 class=" text-black text-3xl font-redHat font-bold">Story Instagram</h5>
            </div>

            <!-- Grid for second section summary -->
            <div class="grid gap-4 lg:gap-8 md:grid-cols-3 p-8">

                <!-- Orderan Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] font-bold w-24 text-black flex justify-center items-center">
                            <i class="bi bi-graph-up"></i>
                            <!-- text-[#07975A] old color -->
                        </div>
                        <div class="space-y-0">
                            <div class="text-4xl font-redHat text-black font-bold">
                                {{ currentData.summary_new.storyig_product }}
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Produk</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- On Process Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] text-black font-bold w-24 flex justify-center items-center">
                            <i class="bi bi-graph-up"></i>
                        </div>
                        <div class="space-y-0">
                            <div class="text-4xl font-redHat text-black font-bold">
                                {{ currentData.summary_new.storyig_product_weight }} <span>Kg</span>
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Est. Berat</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Sold Out Count -->
                <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                    <div class="grid grid-cols-2 p-4">
                        <div
                            class="icon-chart text-center text-[32px] text-black font-bold w-24 flex justify-center items-center">
                            <i class="bi bi-graph-up"></i>
                            <!-- text-red-700 old color -->
                        </div>
                        <div class="space-y-0">
                            <div class="text-4xl font-redHat text-black font-bold">
                                {{ currentData.summary_new.storyig_product_item }}
                                <!-- 0 -->
                            </div>
                            <div class="flex items-center space-x-1 text-[20px] font-redHat font-bold text-black">
                                <h1>Total Item</h1>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


    <!-- modal edit PO batch -->
    <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <!-- Modal content -->
        <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full" @click.stop>
            <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold">Pilih PO Batch</h2>
                <button v-if="closeBtn" @click="closeModal" class="text-red-600 hover:text-gray-900">
                    ✕
                </button>
            </div>
            <div class="">
                <div class="mb-3">
                    <form class="w-full max-w-lg mx-auto">
                        <label class="block mb-2 text-lg font-redHat font-medium text-gray-900 dark:text-white">PO batch
                            ke</label>
                        <!-- <select id="countries" v-model="selectedStore" class="bg-gray-50 border border-gray-300  text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-redHat font-medium text-[#5FBAE6] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option selected>-AUTO BATCH 1-</option>
                      </select> -->
                        <multiselect v-model="currentData.no_po" :options="dataLokasi" :custom-label="nameWithLang"
                            placeholder="" label="name" track-by="" :selectLabel="''" :deselectLabel="''"
                            class="multiselect-full-width"></multiselect>
                    </form>
                </div>
                <div class="mb-3">
                    <form class="w-full max-w-lg mx-auto">
                        <label class="block mb-2 text-lg font-redHat font-medium text-gray-900 dark:text-white">Lokasi
                            PO</label>
                        <input type="text" v-model="currentData.location_name"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placesholder="" disabled>
                    </form>
                </div>

            </div>

            <div class="space-y-3">
                <div aria-hidden="true" class="border-t px-2"></div>
                <div class="button-group flex justify-end">
                    <button v-if="closeBtn" @click="closeModal"
                        class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
                        Close
                    </button>
                    <button @click="saveBatch" class="bg-[#F5CA40] text-white font-bold py-2 px-4 rounded">
                        Simpan
                    </button>
                </div>
            </div>
        </div>
    </div>


</template>

<style scoped></style>


<script>
import NavBar from '@/components/NavBar.vue';
import axios from "axios";
import { ErrorConnectionTimeOut, ShowLoading, goPage, CloseLoading, base_url, AlertPopup, AlertBottom, rupiah } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect';

// import DashboardCard from '@/components/DashboardCard.vue';


export default {
    components: {
        NavBar,
        Multiselect,

        // DashboardCard
    },

    data() {
        return {
            currentData: {
                no_po: '',
                // start_date: '',
                // end_date: '',
                // id_location: null,
                // currency: '',
                query: "",
                location_name: '',
                data_po: [
                    {

                        no_po: '',
                        start_date: '',
                        end_date: '',
                        id_location: null,
                        currency: '',
                        location_name: '',
                    }
                ],
                // summary:
                // {
                //     deposit_count: 0,
                //     done: 0,
                //     orderan: 0,
                //     on_process: 0,
                //     on_sales: 0,
                //     on_done: 0,
                //     sold_out: 0,
                //     customer_count: 0

                // },
                summary_new:
                {
                    customer_count: 0,
                    customer_blm_deposit: 0,
                    customer_pending: 0,
                    customer_keep: 0,
                    customer_soldout: 0,
                    customer_ok: 0,
                    liveig_product: 0,
                    liveig_product_weight: 0,
                    liveig_product_item: 0,
                    storyig_product: 0,
                    storyig_product_weight: 0,
                    storyig_product_item: 0

                },

                status_po: 'AKTIF',
                currentPo: [
                    {

                        status_po: '',
                    }
                ],
            },

            updateStatus: {
                currency: '',
                end_date: '',
                id_location: '',
                start_date: '',
                location_name: '',
                status_po: '',
            },

            dataLokasi: [],
            dataPo: '',
            config: '',
            location: '',
            batchData: [],
            selectedBatch: {
                location_name: '',
                no_po: '',
            },
            closeBtn: true,
            PoActive: [],
            // lokasi: '',
            // record: {

            // },
            dropMenuLaporan: false,
            isModalOpen: false,
        }
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }

        // this.incrementCounters();

        // console.log(this.config);
        this.index();

        // this.checkLocal();
        // this.dataLokasi = JSON.parse(localStorage.getItem('data_location'));

        // const index = this.dataLokasi.findIndex((item) => item.id === this.currentData.id_location);
        // setelah dpt maka bakal masukin data lokasi dengan index yg ditemukan ke dlm id_location
        // this.currentData.id_location = this.dataLokasi[index];
    },

    watch: {
        'currentData.no_po'(newVal) {
            // Log the selected PO value
            // console.log('Selected PO:', newVal);

            // Ensure newVal is not null or empty before searching
            if (!newVal) {
                // console.log('No PO selected');
                this.currentData.location_name = ''; // Reset location if no PO is selected
                return;
            }

            // Log the dataLokasi array to check its structure and content
            // console.log('dataLokasi:', this.dataLokasi);

            // Find the selected PO batch based on the no_po value
            const selectedPreorder = this.dataLokasi.find(po => po.id === newVal.id);

            // Log the result of the search
            // console.log('Selected Preorder:', selectedPreorder);

            // If the location is found, update currentData.location_name
            if (selectedPreorder) {
                this.currentData.location_name = selectedPreorder.location_name;
            } else {
                this.currentData.location_name = ''; // Reset if no location is found
            }
        }
    },

    methods: {
        rupiah,
        searchCustomerInOrder() {
            console.log('test');
            localStorage.setItem('search_customer', this.query);
            goPage('listorder');
        },
        formatDateRange(start_date, end_date) {
            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];

            // Convert strings to date objects
            const startDateObj = new Date(start_date);
            const endDateObj = new Date(end_date);

            // Extract day, month, and year
            const startDay = startDateObj.getDate();
            const endDay = endDateObj.getDate();
            const startMonth = monthNames[startDateObj.getMonth()];
            const endMonth = monthNames[endDateObj.getMonth()];
            const startYear = startDateObj.getFullYear();
            const endYear = endDateObj.getFullYear();

            // Check if months and years are the same
            if (startMonth === endMonth && startYear === endYear) {
                // If same month and year, return single range format
                return `${startDay}-${endDay} ${startMonth} ${startYear}`;
            } else if (startYear === endYear) {
                // If same year but different months
                return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
            } else {
                // If different years (edge case)
                return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
            }
        },
        nameWithLang({ no_po }) {
            return `${no_po}`
        },

        navigateTo(route) {
            //   this.$router.push({ path: route });
            goPage(route)
        },


        pilihLokasiBatch() {
            // this.currentDeleteIndex = index;

            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },

        checkLocal() {
            // const idPreorder = null;
            // if(idPreorder == null){

            // }
            const idPreorder = localStorage.getItem('id_preorder');
            if (idPreorder == null || idPreorder == undefined) {
                this.index()
                this.pilihLokasiBatch()
                this.closeBtn = false
            } else {
                this.index()
                this.closeBtn = true

            }
        },

        openClosePo(newStatus) {
            ShowLoading();
            let formData = new FormData();




            Object.entries(this.updateStatus).forEach(([key, value]) => {   // for looping in table
                formData.append(key, value);
            });




            formData.delete('status_po')
            formData.append('status_po', newStatus)
            // formData.delete('currentPo')
            // // formData.append('currentPo', this.updateStatus)
            // console.log("statusnya:", newStatus)



            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            // formData.delete('id')
            // formData.append('id', poId);



            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'preorder_update';
            } else {
                this.api = base_url + 'preorder_update';
            }


            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    console.log(response.data)
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        this.currentData.currentPo.status_po = newStatus;



                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        index() {
            // console.log('teeestaaa');
            ShowLoading();
            let formData = new FormData();


            // console.log('id preorder saat ini:', localStorage.getItem('id_preorder'));
            if (localStorage.getItem('id_preorder') != null && localStorage.getItem('id_preorder') != undefined && localStorage.getItem('id_preorder') != '') {
                formData.append('id_preorder', localStorage.getItem('id_preorder'))

            } else {
                formData.append('id_preorder', "0")

            }

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard';
            } else {
                this.api = base_url + 'dashboard';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    console.log("respon data index dashboard:", response.data.currentPo);


                    if (response.data.status == true) {
                        localStorage.setItem('code_number_phone', response.data.currentPo.code_number_phone);
                        localStorage.setItem('id_preorder', response.data.currentPo.id);
                        localStorage.setItem('code_number_phone', response.data.currentPo.code_number_phone);
                        localStorage.setItem('data_code_number', JSON.stringify(response.data.data_code_number));
                        localStorage.setItem('location', response.data.currentPo.location_name);

                        // console.log(response.data);
                        this.currentData = response.data.data
                        // this.currentData.summary = response.data.summary
                        this.currentData.summary_new = response.data.summary_new
                        this.currentData.currentPo = response.data.currentPo
                        this.updateStatus = response.data.currentPo
                        // this.currentData.currentPo = response.data.currentPo

                        if (response.data.currentPo != undefined) {
                            this.selectedBatch.location_name = response.data.currentPo.location_name
                            this.selectedBatch.no_po = response.data.currentPo.no_po
                            this.selectedBatch.start_date = response.data.currentPo.start_date
                            this.selectedBatch.end_date = response.data.currentPo.end_date
                            // console.log(response.data.currentPo.location_name);
                        }

                        // console.log(this.currentData)
                        // console.log(this.currentData.summary)
                        // console.log(this.currentData.summary.customer_count)

                        if (response.data.data && Array.isArray(response.data.data)) {
                            this.dataLokasi = response.data.data;
                            // console.log(this.dataLokasi);  // Check if `dataLokasi` contains the correct data
                        } else {
                            console.error("data_po is missing or not an array");
                        }

                        // if (response.data.status == true) {
                        //     this.data = [];

                        //     response.data.data.forEach((item, index) => {
                        //         let item_prepare = { ...item, no: (index + 1), };
                        //         this.data.push(item_prepare);
                        //     });

                        //     CloseLoading();
                        // }


                        CloseLoading();
                    } else {

                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });
        },


        saveBatch() {
            // Save the selected PO and location_name to batchData


            // console.log('cek_selected',this.currentData.no_po.id)

            localStorage.setItem('id_preorder', this.currentData.no_po.id)

            this.closeBtn = true
            // Close the modal after saving
            this.closeModal();

            this.index()

            // Log the batchData to check
            // console.log('Batch Data:', this.batchData);
        },

    }
};
</script>