<template>
    <div class="bg-gray-500 h-screen w-full flex items-center justify-center py-8">
        <div class="max-w-lg w-[700px] rounded-lg bg-[#5FBAE6] h-[580px] flex flex-col items-center p-8">
            <!-- Heading -->
            <div class="block mb-16 mt-6">
                <h5 class="font-redHat text-3xl font-medium text-[#F5CA40F2]">Live Instagram Baru</h5>
            </div>

            <!-- Form -->
            <form action="" @submit.prevent="insert">
                <div class="block mb-10 w-full">
                    <div class="w-full max-w-lg mx-auto">
                        <!-- <label for="countries" class="block mb-2 text-lg font-redHat font-medium text-gray-900 dark:text-white">Nama Toko</label> -->
                        <div class="flex items-center justify-between">
                            <label class=" mb-2 text-lg font-redHat font-medium text-gray-900">Nama Toko</label>
                            <button @click="addLocation">
                                <i class='bx bx-plus-circle text-xl'></i>
                            </button>
                        </div>
                        <multiselect v-model="selectedSupplier" :options="dataToko" :custom-label="nameWithLang"
                            placeholder="Select Store" label="name" track-by="name" :selectLabel="''"
                            :deselectLabel="''"
                            class="bg-gray-50 border border-gray-300 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full font-redHat font-medium text-[#5FBAE6]">
                        </multiselect>
                    </div>
                </div>
                <div class="block mb-32 w-full">
                    <div class="w-full max-w-lg mx-auto">
                        <div class="flex items-center justify-between">
                            <label class=" mb-2 text-lg font-redHat font-medium text-gray-900">Tanggal</label>
                            <!-- <button @click="addLocation">
                                <i class='bx bx-plus-circle text-xl'></i>
                            </button> -->
                        </div>
                        <input type="date" v-model="date"
                            class="border border-gray-300 rounded-lg pl-3 pr-10 py-2 w-full focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500" />
                    </div>
                </div>

                <!-- Submit Button -->
                <div class="flex gap-2">
                    <button type="reset" tabindex="-1" @click="backToDashboard"
                        class="text-red-600 font-redHat border-1 border-red-500 font-bold text-lg flex items-center justify-center px-5 py-2  rounded-md w-[230px]">Cancel</button>
                    <button type="submit"
                        class="text-black font-redHat font-bold text-lg flex items-center justify-center px-5 py-2 bg-[#F5CA40] rounded-md w-[230px]">Mulai
                        Live</button>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
// import  from 'vue-select'
import axios from "axios";
// import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, goPage } from "@/assets/js/function";
// import {ErrorConnectionTimeOut,  ShowLoading, CloseLoading, base_url, AlertPopup,  rupiahInput,  rupiah } from "@/assets/js/function";
import Cookies from "js-cookie";

export default {
    components: {
        // 'v-select': vSelect,
        Multiselect,

    },

    data() {
        return {

            selectedSupplier: null,
            currentData: {
                id_supplier: null,
            },
            counter: 0,

            dataToko: [
                // {
                //     name: '',
                // }
            ],
            config: '',
            date: '',
        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }

        this.index();

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(currentDate.getDate()).padStart(2, '0');
        this.date = `${year}-${month}-${day}`;

    },

    methods: {
        nameWithLang({ name }) {
            return `${name}`
        },

        addLocation() {
            // for batal edit customer page
            goPage('addliveshop')

        },

        backToDashboard() {
            goPage('dashboard')
        },

        index() {
            ShowLoading();
            let formData = new FormData();
            formData.append('id_preorder', localStorage.getItem('id_preorder'))
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'live_ig_create';
            } else {
                this.api = base_url + 'live_ig_create';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status === true) {
                        this.dataToko = response.data.data
                        this.counter = response.data.counter

                        if (localStorage.getItem('newSupplier') != undefined || localStorage.getItem('newSupplier') != null) {
                            const index = this.dataToko.findIndex((item) => item.name == localStorage.getItem('newSupplier'));
                            this.selectedSupplier = this.dataToko[index];
                        }

                        localStorage.setItem('data_location', JSON.stringify(response.data.data_location));
                        // console.log(response.data);
                        localStorage.setItem('data_code_number', JSON.stringify(response.data.data_code_number));

                        CloseLoading();
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });
        },

        // to insert after choosing shop
        insert() {
            ShowLoading();


            // formData.delete("id");
            // formData.append("id", this.currentData.id)

            // localStorage.setItem('id_supplier', this.currentData.id_supplier.id)
            if (this.selectedSupplier && this.selectedSupplier.id) {
                localStorage.setItem('id_supplier', this.selectedSupplier.id); // Save the ID from selectedSupplier
            } else {
                AlertPopup("error", "", "Silahkan Pilih toko", 1500, false);
                return true;
            }

            localStorage.setItem('counter', this.counter)
            localStorage.setItem('live_ig_date', this.date)

            CloseLoading()
            goPage('dataliveig')

        },
    },

};
</script>
