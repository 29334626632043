<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Admin Baru</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nama Admin</label>
                        <input type="text" v-model="currentData.nama_lengkap"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required>

                    </div>

                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">No. Whatsapp</label>
                        <input type="tel" v-model="currentData.phone" @input="restrictToAllowedChars"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Username</label>
                        <input type="text" v-model="currentData.username"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Password</label>
                        <input type="text" v-model="currentData.password"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required>
                    </div>


                </div>

                <div class="mb-3">
                    <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Email</label>
                    <input type="text" v-model="currentData.email"
                        class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Email" required>
                </div>

                <div class="">
                    <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Otoritas</label>
                    <select id="tipe"
                        class="  border-1 border-[#5FBAE6] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-redHat font-medium  "
                        v-model="currentData.tipe" required>
                        <option value="">Pilih tipe</option>
                        <option value="Business owner">Business Owner</option>
                        <option value="Super Admin">Super Admin</option>
                        <option value="Admin">Admin</option>
                    </select>
                    <!-- <input type="text" v-model="currentData.tipe"  class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Business Owner, Super Admin, Admin" required> -->
                </div>




            </div>

            <div class="mb-3 space-x-5 flex justify-end px-10">
                <RouterLink to="admin"
                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">
                    Batal</RouterLink>
                <button type="submit"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
            </div>
        </form>


    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
import Cookies from "js-cookie";


export default {
    components: {
        NavBar,
    },

    data() {
        return {

            // currentData: [],
            currentData: {
                nama_lengkap: '',
                phone: '',
                username: '',
                password: '',
                email: '',
                tipe: '',
            },
            // newRecord: '',
        };
    },

    created() {
        const token = Cookies.get("token");
        console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }

        console.log(this.config);
        // this.index();
    },

    methods: {
        restrictToAllowedChars(event) {
            // Allow only numbers, plus sign (+), hyphen (-), space, parentheses (), and delete invalid characters
            event.target.value = event.target.value.replace(/[^0-9+\-() ]/g, '');
            // Update the v-model with the formatted value
            this.currentData.phone = event.target.value;
        },

        insert() {
            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });


            // formData.delete("foto_profile");

            // if (this.$refs.foto_profile.value != "") {

            //     formData.append("foto_profile", this.gambar_profile_64);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'user_insert';
            } else {
                this.api = base_url + 'user_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response)
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        goPage("admin");
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },
}
</script>