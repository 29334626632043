<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Rekap Live</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6 mx-3">
                <div class="grid grid-cols-3 gap-4">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Instagram</label>
                        <input type="text" v-model="currentData.customer_instagram"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="instagram"
                            placeholder="" disabled>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nama Customer</label>
                        <input type="text" v-model="currentData.customer_name"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="name" placeholder=""
                            disabled>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">No. Whatsapp</label>
                        <input type="text" v-model="currentData.customer_detail.whatsapp"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="whatsapp" placeholder=""
                            disabled>

                    </div>

                </div>

                <!-- tipe customer -->
                <div class="button-group grid grid-cols-2 mt-5 gap-4">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Jenis
                            Penjualan</label>
                        <button type="button" class="w-full text-center items-center border-1 rounded py-2"
                            :class="{ 'bg-[#F5CA40] text-white': selectedButton === 'Story_ig' }"
                            @click="selectButton('Story_ig')" disabled>
                            Story Instagram
                        </button>
                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2"></label>
                        <button type="button" class="mt-4 w-full text-center items-center border-1 rounded py-2"
                            :class="{ 'bg-[#F5CA40] text-white': selectedButton === 'Live_ig' }"
                            @click="selectButton('Live_ig')">Live Instagram</button>
                    </div>
                </div>




                <div class="grid grid-cols-12 mt-5">
                    <div class="col-span-12">
                        <label for="alamat"
                            class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Alamat</label>
                        <textarea name="address" rows="4" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                            v-model="currentData.customer_detail.address" id="address" disabled></textarea>
                        <!-- <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder=""> -->



                    </div>
                </div>

                <!-- provinsi section -->
                <div class="grid grid-cols-4 gap-4 mt-5">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Provinsi</label>
                        <!-- <input type="text" v-model="currentData.province" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder=""> -->
                        <multiselect v-model="currentData.customer_detail.province" :options="provinsiList" label="text"
                            track-by="id" placeholder="Pilih Provinsi" :selectLabel="''" :deselectLabel="''"
                            class="multiselect-full-width" :disabled="isDisabled"></multiselect>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kota/Kabupaten</label>
                        <!-- <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder=""> -->
                        <multiselect v-model="currentData.customer_detail.city" :options="kabupatenList"
                            placeholder="Pilih Kota/Kabupaten" label="text" track-by="id" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width" :disabled="isDisabled"></multiselect>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kecamatan</label>
                        <!-- <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder=""> -->
                        <multiselect v-model="currentData.customer_detail.district" :options="kecamatanList"
                            placeholder="Pilih kecamatan" label="text" track-by="id" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width" :disabled="isDisabled"></multiselect>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kelurahan</label>
                        <!-- <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder=""> -->
                        <multiselect v-model="currentData.customer_detail.sub_district" :options="kelurahanList"
                            placeholder="Pilih Kelurahan" label="text" track-by="id" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width" :disabled="isDisabled"></multiselect>

                    </div>

                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kode Pos</label>
                        <input type="text" v-model="currentData.customer_detail.pos_code"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" disabled>

                    </div>
                </div>

                <div class="bg-[#F5F5F5] rounded-[8px] grid grid-cols-1 gap-1 px-4 py-4 mt-5">
                    <div class="">
                        <label class=" font-redHat text-[#8F8F8F] font-medium font-2xl mb-3">Deposit</label>
                        <div class="flex gap-3 w-full mb-2" v-for="(item, index) in currentData.customer_detail.deposit"
                            :key="index">
                            <!-- <input type="date" v-model="currentData.date" class=" flex-grow border-1 border-[#5FBAE6] rounded-md p-2 " name="date" >
                            <input type="text" v-model="currentData.name" class=" flex-grow border-1 border-[#5FBAE6] rounded-md p-2 " name="atas_nama" placeholder="Atas Nama">
                            <input type="text" v-model="currentData.bank_name" class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2 " name="bank" placeholder="Nama Bank">
                            <input type="text" v-model="currentData.amount"  class=" flex-grow border-1 border-[#5FBAE6] rounded-md p-2" name="jumlah" placeholder="Jumlah"> -->

                            <!-- /////// -->

                            <input type="date" v-model="item.date"
                                class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" disabled>
                            <input type="text" v-model="item.name"
                                class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Atas Nama"
                                disabled>
                            <input type="text" v-model="item.bank_name"
                                class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Nama Bank"
                                disabled>
                            <input type="text" v-model="item.amount"
                                @input="formatCurrency('amount', item.amount, index)"
                                class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-"
                                disabled>
                            <!-- <button type="button" v-if="!checkingLastRow(index)" @click="removeDeposit(index)" class="text-red-500 text-[24px]">
                                <i class="bi bi-trash"></i>
                            </button>
                            <button type="button" v-if="checkingLastRow(index)" @click="addDeposit" class="text-[#5FBAE6F2] text-[24px]"><i class="bi bi-plus-circle"></i></button> -->


                        </div>

                    </div>
                    <!-- <div class="">
                        <label class="font-redHat text-[#8F8F8F] font-medium font-2xl"></label>
                            
                    </div>
                    <div class="">
                        <label class=" font-redHat text-[#8F8F8F] font-medium font-2xl"></label>
                            
                    </div>
                    <div class="">
                        <label class=" font-redHat text-[#8F8F8F] font-medium font-2xl"></label>
                            
                    </div> -->
                    <!-- <div class="">
                        <button class="text-[#5FBAE6F2] w-[87px] h-[88px]"><i class="bi bi-plus-circle"></i></button>
                    </div> -->
                </div>

                <!-- <div class="grid grid-cols-1 mt-5">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kategori</label>
                        <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2">
                    </div>
                </div> -->

                <!-- for row kode produk, warna, size, qty & harga -->
                <div class="grid grid-cols-10 gap-4 items-center mt-5">
                    <template v-for="(item, index) in currentData.product_detail" :key="index">

                        <!-- Kode Produk -->
                        <div class="col-span-2">
                            <label class="block font-redHat text-[#8F8F8F] font-medium text-md mb-2">Kode Produk</label>
                            <input type="text" v-model="item.code_product"
                                class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" disabled>
                        </div>

                        <div class="col-span-2">
                            <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kategori</label>
                            <input type="text" v-model="item.category"
                                class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" disabled>
                        </div>

                        <!-- Warna -->
                        <div class="col-span-2">
                            <label class="block font-redHat text-[#8F8F8F] font-medium text-md mb-2">Varian</label>
                            <input type="text" v-model="item.color"
                                class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" disabled>
                        </div>

                        <!-- Size -->
                        <div class="col-span-1">
                            <label class="block font-redHat text-[#8F8F8F] font-medium text-md mb-2">Size</label>
                            <input type="text" v-model="item.size"
                                class="block w-[80px] border-1  border-[#5FBAE6] rounded-md p-2 text-center" disabled>
                        </div>

                        <!-- Qty -->
                        <div class="col-span-1">
                            <label class="block font-redHat text-[#8F8F8F] font-medium text-md mb-2">Qty</label>
                            <input type="number" v-model="item.qty"
                                class="block w-[80px] border-1  border-[#5FBAE6] rounded-md p-2 text-center" disabled>
                        </div>

                        <!-- Harga -->
                        <div class="col-span-2">
                            <label class="block font-redHat text-[#8F8F8F] font-medium text-md mb-2">Harga</label>
                            <input type="text" v-model="item.total_price"
                                @input="formatCurrency('total_price', item.total_price, index)"
                                class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" disabled>
                        </div>
                    </template>
                </div>

                <div class="grid grid-cols-1 mt-5">
                    <div class="flex justify-end">
                        <label class="font-redHat text-black font-medium text-md me-4">Total Harga</label>
                        <input type="text" :value="calculateTotalHarga()"
                            class="w-[20%] border-1 border-[#5FBAE6] rounded-md p-2" disabled>
                        <!-- <input type="text" class="w-[20%] border-1 border-[#5FBAE6] rounded-md p-2"> -->
                    </div>
                </div>














            </div>

            <div class="mb-3 space-x-5 flex justify-end px-10 ">
                <!-- <RouterLink to="customer" class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">Batal</RouterLink> -->
                <RouterLink to="rekapliveig"
                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">
                    Kembali</RouterLink>
                <!-- <button type="submit" class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]" >Simpan</button> -->
            </div>
        </form>


    </div>
</template>

<style scoped>
.selected {
    background-color: #F5CA40;
    color: white;
}
</style>



<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, base_url_wilayah_indonesia, rupiahInput, rupiahoutput } from "@/assets/js/function";
// import {ShowLoading, CloseLoading, base_url_wilayah_indonesia, ErrorConnectionTimeOut, rupiahInput, rupiahoutput } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect';


export default {
    components: {
        NavBar,
        Multiselect,

    },

    data() {
        return {
            // currentData: [],
            currentData: {
                type: '', // Add customer_type in currentData
                customer_detail: [{
                    instagram: '',
                    name: '',
                    whatsapp: '',
                    address: '',
                    pos_code: '',
                }],
                product_detail: [{
                    code_product: '',
                    color: '',
                    size: '',
                    qty: '',
                    total_price: '',
                }],
                // provinsiList: {},
                // kabupatenList: {},
                // kecamatanList: {},
                // kelurahanList: {},
                // province: {}, // Selected province
                // city: {}, // Selected city
                // district: {}, // Selected district
                // sub_district: {}
            },
            date: '',
            selectedButton: null, // to track the selected button
            products: [
                { kodeProduk: 'Celpen 2 Button', warna: 'Cream', size: 'XL', qty: 1, harga: 'Rp. 50.000' },
                { kodeProduk: 'T-Shirt Classic', warna: 'Black', size: 'M', qty: 2, harga: 'Rp. 10.000' },
            ],
            // for alamat
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],
            type_rekap: '',
        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }

        // console.log(this.config);
        // this.index();
        //get data
        this.currentData = JSON.parse(localStorage.getItem('currentData'));
        this.type_rekap = localStorage.getItem('tipe_rekap');
        if (this.type_rekap) {
            this.selectedButton = this.type_rekap; // Set the selected button based on local storage
            this.currentData.type = this.type_rekap; // Also set the currentData type if necessary
        }
        // this.dataDeposit = JSON.parse(localStorage.getItem('data_deposit'));



        // Initialize selectedButton based on currentData's customer_type
        if (this.currentData && this.currentData.type) {
            this.selectedButton = this.currentData.type; // Set selectedButton to match existing customer_type
        }

        this.prepareData();
        // this.provinsi_index();


    },

    watch: {
        'currentData.customer_detail.province': {
            handler(newValue) {
                const index = this.provinsiList.findIndex((record) => record.id === newValue.id);
                if (index > -1) {
                    this.kabupaten_index(this.provinsiList[index].id);
                }
                this.kecamatanList = [];
                this.kelurahanList = [];
            }
        },
        'currentData.customer_detail.city': {
            handler(newValue) {
                const index = this.kabupatenList.findIndex((record) => record.id === newValue.id);
                if (index > -1) {
                    this.kecamatan_index(this.kabupatenList[index].id);
                }
                this.kelurahanList = [];
            }
        },
        'currentData.customer_detail.district': {
            handler(newValue) {
                // console.log(newValue);


                const index = this.kecamatanList.findIndex((record) => record.id === newValue.id);
                // console.log(index);
                if (index > -1) {
                    this.kelurahan_index(this.kecamatanList[index].id);
                }
            }
        },

        'currentData.customer_detail.sub_district': {
            handler(newValue) {
                // console.log(newValue);
                const index = this.kecamatanList.findIndex((record) => record.id === newValue.id);
                // console.log(index);

            }
        },





    },

    methods: {
        calculateTotalHarga() {
            const total = this.products.reduce((sum, product) => {
                // Remove "Rp." and commas from harga and convert to a number
                const harga = parseInt(product.harga.replace(/[Rp.,\s]/g, ''));
                return sum + harga;
            }, 0);

            // Return the total formatted as Indonesian Rupiah
            return total.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' });
        },

        selectButton(button) {
            this.selectedButton = button; // Set the clicked button as selected
            this.currentData.type = button;
        },

        addDeposit() {
            this.currentData.customer_detail.deposit.push({
                date: '',
                name: '',
                bank_name: '',
                amount: ''
            });
        },

        // Remove a deposit row
        removeDeposit(index) {
            this.currentData.customer_detail.deposit.splice(index, 1);
        },

        formatCurrency(label, number, index) {
            this.currentData.customer_detail.deposit[index][label] = rupiahInput(number);
            this.currentData.product_detail[index][label] = rupiahInput(number);

        },

        calculateTotalHarga() {
            // Sum all total_price values in the product detail array
            const total = this.currentData.product_detail.reduce((sum, product) => {
                // Convert total_price to a string before replacing characters
                const harga = parseInt(String(product.total_price).replace(/[Rp.,\s]/g, '')) || 0;
                return sum + harga;
            }, 0);

            const formattedTotal = total.toLocaleString('id-ID');

            return `Rp ${formattedTotal},-`;

            // Return the total formatted as Indonesian Rupiah
            // return total.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' });
        },

        checkingLastRow(index) {
            if (index == this.currentData.customer_detail.deposit.length - 1) {
                // this.records[index].isAdded = true;
                return true;

                // this.records.splice(index + 1, 0, );
            } else {
                // this.records[index].isAdded = false;
                return false;

            }
        },

        onProvinceSelect(selectedProvince) {
            this.currentData.province = selectedProvince;
            this.kabupatenList = []; // Reset city list
            this.kecamatanList = []; // Reset district list
            this.kelurahanList = []; // Reset sub-district list
            this.kabupaten_index(selectedProvince.id); // Load cities based on province
        },

        onCitySelect(selectedCity) {
            this.currentData.city = selectedCity;
            this.kecamatanList = []; // Reset district list
            this.kelurahanList = []; // Reset sub-district list
            this.kecamatan_index(selectedCity.id); // Load districts based on city
        },

        onDistrictSelect(selectedDistrict) {
            this.currentData.district = selectedDistrict;
            this.kelurahanList = []; // Reset sub-district list
            this.kelurahan_index(selectedDistrict.id); // Load sub-districts based on district
        },
        onSubDistrictSelect(selectedSubDistrict) {
            this.currentData.sub_district = selectedSubDistrict;
            this.kelurahanList = []; // Reset sub-district list
            this.kelurahan_index(selectedSubDistrict.id); // Load sub-districts based on district
        },

        insert() {
            ShowLoading();
            let formData = new FormData();

            // Ensure customer_type is included in currentData
            this.currentData.type = this.selectedButton;

            // console.log(this.currentData.type)

            // Clean up the deposit data (remove 'Rp.' from the amount)
            this.currentData.deposit.forEach((item) => {
                // Apply rupiahOutput to remove "Rp." and format amount
                item.amount = rupiahoutput(item.amount);
            });


            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // console.log('cek id provinsi dkk :',this.currentData.province.id)

            // for test try seperately the deposit row 
            // this.currentData.deposit.forEach((deposit, index) => {
            //     // Append each deposit field as deposit[0], deposit[1], etc.
            //     Object.entries(deposit).forEach(([key, value]) => {
            //         // formData.delete("deposit");
            //         formData.append(`deposit[${index}][${key}]`, value);
            //     });
            // });

            formData.delete("province");
            formData.append("province", this.currentData.customer_detail.province.text);
            formData.delete("city");
            formData.append("city", this.currentData.customer_detail.city.text);
            formData.delete("district");
            formData.append("district", this.currentData.customer_detail.district.text);
            formData.delete("sub_district");
            formData.append("sub_district", this.currentData.customer_detail.sub_district.text);



            formData.delete("deposit");
            formData.append('deposit', JSON.stringify(this.currentData.deposit));
            formData.delete("total_price");
            formData.append('total_price', JSON.stringify(this.currentData.product_detail));

            // formData.delete("amount");
            // formData.append('amount', rupiahoutput(item.amount));

            // this.currentData.deposit.forEach((item) => {
            //     // console.log('Original Amount:', item.amount);  // Log original amount
            //     // console.log('Processed Amount:', rupiahoutput(item.amount));
            //     formData.delete("amount");
            //     formData.append('amount', rupiahoutput(item.amount));

            //     // item.amount = rupiah(item.amount)
            // });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // } 
            // CloseLoading()
            // return true




            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'live_ig_summary';
            } else {
                this.api = base_url + 'live_ig_summary';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response.data)
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);



                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },


        async provinsi_index() {
            ShowLoading();
            return new Promise((resolve, reject) => {
                axios.post(base_url_wilayah_indonesia + "provinsi_index", '')
                    .then((response) => {
                        // console.log(response);
                        if (response.data.status === true) {
                            this.provinsiList = response.data.data.map((item) => ({
                                text: item.nama,
                                id: item.id,
                                value: item.nama,
                            }));
                            resolve(response); // Resolve the promise with the response
                        } else {
                            reject(new Error("Failed to load provinces")); // Reject the promise
                        }
                        CloseLoading();
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                        reject(error); // Reject the promise with the error
                    });
            });
        },

        async kabupaten_index(id) {
            this.kabupatenList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);

            try {
                const response = await axios.post(base_url_wilayah_indonesia + "kabupaten_index", formData);
                if (response.data.status === true) {
                    this.kabupatenList = response.data.data.map((item) => ({
                        text: item.nama,
                        id: item.id,
                        value: item.nama,
                    }));
                }
                CloseLoading();
            } catch (error) {
                ErrorConnectionTimeOut(error);
                CloseLoading();
            }
        },
        async kecamatan_index(id) {
            this.kecamatanList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);

            try {
                const response = await axios.post(base_url_wilayah_indonesia + "kecamatan_index", formData);
                if (response.data.status === true) {
                    this.kecamatanList = response.data.data.map((item) => ({
                        text: item.nama,
                        id: item.id,
                        value: item.nama,
                    }));
                }
                CloseLoading();
            } catch (error) {
                ErrorConnectionTimeOut(error);
                CloseLoading();
            }
        },

        async kelurahan_index(id) {
            this.kelurahanList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);

            try {
                const response = await axios.post(base_url_wilayah_indonesia + "kelurahan_index", formData);
                if (response.data.status === true) {
                    this.kelurahanList = response.data.data.map((item) => ({
                        text: item.nama,
                        id: item.id,
                        value: item.nama,
                    }));
                }
                CloseLoading();
            } catch (error) {
                ErrorConnectionTimeOut(error);
                CloseLoading();
            }
        },


        async prepareData() {
            await this.provinsi_index();

            // console.log(this.provinsiList)
            // console.log(this.currentData.province)
            const indexprovinsi = this.provinsiList.findIndex((record) => record.text === this.currentData.customer_detail.province);
            // console.log(indexprovinsi)
            // console.log(this.provinsiList[0])
            // console.log(this.provinsiList[indexprovinsi])

            if (indexprovinsi > -1) {
                this.currentData.customer_detail.province = this.provinsiList[indexprovinsi]
                await this.kabupaten_index(this.provinsiList[indexprovinsi].id);
            }

            // console.log(this.kabupatenList)
            // console.log(this.currentData.city)
            const indexkabupaten = this.kabupatenList.findIndex((record) => record.text === this.currentData.customer_detail.city);
            // console.log(indexkabupaten)
            // console.log(this.kabupatenList[0])
            // console.log(this.kabupatenList[indexkabupaten])
            if (indexkabupaten > -1) {
                this.currentData.customer_detail.city = this.kabupatenList[indexkabupaten]
                await this.kecamatan_index(this.kabupatenList[indexkabupaten].id);
            }

            const indexkecamatan = this.kecamatanList.findIndex((record) => record.text === this.currentData.customer_detail.district);
            // console.log(indexkecamatan)
            if (indexkecamatan > -1) {
                this.currentData.customer_detail.district = this.kecamatanList[indexkecamatan]
                await this.kelurahan_index(this.kecamatanList[indexkecamatan].id);
            }

            const indexkelurahan = this.kelurahanList.findIndex((record) => record.text === this.currentData.customer_detail.sub_district);
            // console.log(indexkecamatan)
            if (indexkelurahan > -1) {
                this.currentData.customer_detail.sub_district = this.kelurahanList[indexkelurahan]
                // await this.kelurahan_index(this.kelurahanList[indexkelurahan].id);
            }

            // for amount currency
            // this.records.total_deposit = rupiahInput(this.records.total_deposit);
            // this.currentData.deposit.forEach((item) => {
            //     item.amount = rupiahInput(item.amount);
            // });
            if (this.currentData.customer_detail.deposit.length === 0) {
                // If deposit length is 0, add one data object
                this.currentData.customer_detail.deposit.push({
                    amount: 'Rp 0,-', // You can adjust the default data as needed
                    // Add other properties if required
                });
            } else {
                // If deposit length is not 0, apply rupiahInput to each deposit
                this.currentData.customer_detail.deposit.forEach((item) => {
                    item.amount = rupiahInput(item.amount);
                });
            }

            if (this.currentData.product_detail.length === 0) {
                // If deposit length is 0, add one data object
                this.currentData.product_detail.push({
                    total_price: 'Rp 0,-', // You can adjust the default data as needed
                    // Add other properties if required
                });
            } else {
                // If deposit length is not 0, apply rupiahInput to each deposit
                this.currentData.product_detail.forEach((item) => {
                    item.total_price = rupiahInput(item.total_price);
                });
            }

        },


        // goBack() {
        //     // Retrieve the previous route from localStorage
        //     const previousRoute = localStorage.getItem('previousRoute');
        //     if (previousRoute) {
        //         // Navigate to the previous route
        //         this.$router.push(previousRoute);
        //         // Optionally clear the saved route
        //         localStorage.removeItem('previousRoute');
        //     } else {
        //         // Fallback route if no previous route is found
        //         this.$router.push({ name: 'dashboard' });
        //     }
        // },


    },
}
</script>