<template>
    <div class="justify-center items-center px-3 py-2 bg-[#5FBAE6] w-screen h-screen">
        <div class="card bg-white rounded-xl   w-full h-full shadow-md px-5 py-4">
            <div class="title-header text-center mb-3">
                <h2 class="text-5xl font-redHat font-bold">Customer Baru</h2>
            </div>
            <div class="menu-tab">
                <div class="menu-top ">
                    <div class="flex justify-start items-center gap-4 order-2 md:order-1 mt-4 md:mt-0">
                        <div class="font-normal text-md relative cursor-pointer px-4 py-2" @click="activePage('umum');"
                            :class="{ 'bg-[#5FBAE6]/30 border-l-4 border-[#5FBAE6] text-black rounded-sm': active_page === 'umum', 'text-gray-300': active_page !== 'umum' }">
                            UMUM
                            <!-- <hr
                                class="absolute border border-[#5FBAE6] opacity-100 w-[150px] left-[-20px] md:hidden bottom-[-25px]"
                                v-if="active_page === 'trip'">
                            <hr
                                class="absolute hidden md:block border border-[#5FBAE6] opacity-100 w-[150px] left-[-20px]"
                                v-if="active_page === 'trip'"> -->


                        </div>
                        <div class="font-normal text-md relative cursor-pointer px-4 py-2"
                            @click="activePage('alamat');"
                            :class="{ 'bg-[#5FBAE6]/30 border-l-4 border-[#5FBAE6] text-black rounded-sm': active_page === 'alamat', 'text-gray-300': active_page !== 'alamat' }">
                            ALAMAT
                            <!-- <hr
                                class="absolute border border-[#5FBAE6] opacity-100 w-[150px] left-[-20px] md:hidden bottom-[-25px]"
                                v-if="active_page === 'alamat'">
                            <hr
                                class="absolute hidden md:block border border-[#5FBAE6] opacity-100 w-[150px] left-[-20px]"
                                v-if="active_page === 'alamat'"> -->


                        </div>
                        <!-- <div class="font-normal text-md relative cursor-pointer px-4 py-2"
                            @click="activePage('deposit');"
                            :class="{ 'bg-[#5FBAE6]/30 border-l-2 border-[#5FBAE6] text-black rounded-sm': active_page === 'deposit', 'text-gray-300': active_page !== 'deposit' }">
                            DEPOSIT



                        </div> -->
                        <div class="font-normal text-md relative cursor-pointer px-4 py-2"
                            @click="activePage('riwayat_pembelian');"
                            :class="{ 'bg-[#5FBAE6]/30 border-l-2 border-[#5FBAE6] text-black rounded-sm': active_page === 'riwayat_pembelian', 'text-gray-300': active_page !== 'riwayat_pembelian' }"
                            style="display: none;">
                            RIWAYAT PEMBELIAN



                        </div>
                    </div>
                </div>
                <div class="menu-content">
                    <div class="card rounded-[10px] border-1 border-[#5FBAE6]/30 p-4 h-full">
                        <form @submit.prevent="insert">
                            <div class="row" :class="{ 'overflow-y-scroll max-h-64': active_page !== 'umum' }">
                                <div class="grid grid-cols-12 mb-5" v-show="active_page == 'umum'">
                                    <div class="col-span-6 md:col-span-6 lg:col-span-6 gap-3 me-4">
                                        <!-- Content for left column -->
                                        <div class="flex items-center gap-2 mb-3">
                                            <label class="whitespace-nowrap text-gray-400 me-5">Instagram</label>
                                            <input type="text" v-model="currentData.instagram"
                                                class="w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                                                required>
                                        </div>
                                        <div class="flex items-center gap-2 mb-3">
                                            <label class="whitespace-nowrap text-gray-400 me-2">Nama customer</label>
                                            <input type="text" v-model="currentData.name"
                                                class="w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="">
                                        </div>
                                        <div class="flex items-center gap-2 mb-3">
                                            <label class="whitespace-nowrap text-gray-400 me-3">No. Whatsapp</label>
                                            <input type="text" v-model="currentData.whatsapp"
                                                class="w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="">
                                        </div>
                                        <div class="flex items-center gap-2 mb-3">
                                            <label class="whitespace-nowrap text-gray-400 me-3">Tipe Customer</label>
                                            <div class="left-menu-check flex">
                                                <div class="flex items-center me-4">
                                                    <input id="inline-checkbox" type="checkbox"
                                                        :checked="selectedButton === 'Reguler'"
                                                        @change="toggleCustomerType('Reguler')"
                                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-[#5FBAE6] rounded focus:ring-blue-500   focus:ring-2">
                                                    <label for="inline-checkbox"
                                                        class="ms-2 text-sm font-medium text-gray-400 ">Reguler</label>
                                                </div>
                                                <div class="flex items-center me-4">
                                                    <input id="inline-2-checkbox" type="checkbox"
                                                        :checked="selectedButton === 'Reseller'"
                                                        @change="toggleCustomerType('Reseller')"
                                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-[#5FBAE6] rounded focus:ring-blue-500 focus:ring-2">
                                                    <label for="inline-2-checkbox"
                                                        class="ms-2 text-sm font-medium text-gray-400 ">Reseller</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-6 md:col-span-6 lg:col-span-6">
                                        <!-- Content for right column -->
                                        <div class="flex items-center gap-2 mb-3">
                                            <label
                                                class="whitespace-nowrap text-gray-400 -translate-y-11">Catatan</label>
                                            <textarea v-model="currentData.note" rows="4"
                                                class="w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                                placeholder=""></textarea>
                                        </div>
                                    </div>






                                </div>
                                <div class="grid grid-cols-12 mb-3" v-show="active_page == 'alamat'">
                                    <!-- alamat Rumah -->
                                    <div class="col-span-12">
                                        <div class="flex justify-between items-center mb-2">
                                            <h5 class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">
                                                Alamat Rumah</h5>
                                            <div class="flex items-center me-4">
                                                <input id="home-checkbox" type="checkbox"
                                                    :checked="selectedMainAddress === 'home'"
                                                    @change="toggleAddressStatus('home', 0)"
                                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-[#5FBAE6] rounded focus:ring-blue-500 focus:ring-2">
                                                <label for="home-checkbox"
                                                    class="ms-2 text-sm font-medium text-gray-400">
                                                    Jadikan Alamat Utama
                                                </label>
                                            </div>
                                        </div>
                                        <div class="flex gap-3">
                                            <input type="text" v-model="currentData.address[0].address"
                                                class="w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                                placeholder="Masukan Alamat">
                                            <multiselect v-model="currentData.address[0].province"
                                                :options="provinsiList" label="text" track-by="id"
                                                placeholder="Pilih Provinsi" class="multiselect-full-width"
                                                :selectLabel="''" :deselectLabel="''"
                                                @update:modelValue="handleProvince(0)"></multiselect>
                                            <multiselect v-model="currentData.address[0].city" :options="kabupatenList"
                                                placeholder="Pilih Kota/Kabupaten" label="text" track-by="id"
                                                class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                                @update:modelValue="handleCity(0)" @click="handleProvince(0)">
                                            </multiselect>
                                            <multiselect v-model="currentData.address[0].district"
                                                :options="kecamatanList" placeholder="Pilih Kecamatan" label="text"
                                                track-by="id" class="multiselect-full-width" :selectLabel="''"
                                                :deselectLabel="''" @update:modelValue="handleDistrict(0)"
                                                @click="handleCity(0)"></multiselect>
                                            <multiselect v-model="currentData.address[0].sub_district"
                                                :options="kelurahanList" placeholder="Pilih Kelurahan" label="text"
                                                track-by="id" class="multiselect-full-width" :selectLabel="''"
                                                :deselectLabel="''" @update:modelValue="handleSub_district(0)"
                                                @click="handleDistrict(0)">
                                            </multiselect>
                                            <input type="text" v-model="currentData.address[0].pos_code"
                                                class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                                placeholder="Kode Pos">


                                        </div>

                                    </div>

                                    <!-- alamat pengiriman -->
                                    <div class="col-span-12 mt-4">
                                        <div class="flex justify-between items-center mb-2">
                                            <h5 class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">
                                                Alamat Pengiriman</h5>
                                            <div class="flex items-center me-4">
                                                <input :id="'pengiriman-checkbox-' + index" type="checkbox"
                                                    :checked="selectedMainAddress === `pengiriman-${index}`"
                                                    @change="toggleAddressStatus('pengiriman', index)"
                                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-[#5FBAE6] rounded focus:ring-blue-500 focus:ring-2">
                                                <label :for="'pengiriman-checkbox-' + index"
                                                    class="ms-2 text-sm font-medium text-gray-400">
                                                    Jadikan Alamat Utama
                                                </label>
                                            </div>
                                        </div>


                                        <div class=" " v-for="(item, index) in currentData.address" :key="index">
                                            <div class="flex gap-3 mb-3" v-if="index > 0">

                                                <input type="text" v-model="item.address"
                                                    class="w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                                    placeholder="Masukan Alamat">
                                                <multiselect v-model="item.province" :options="provinsiList"
                                                    label="text" track-by="id" placeholder="Pilih Provinsi"
                                                    class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                                    @update:modelValue="handleProvinceKirim(index)"></multiselect>
                                                <multiselect v-model="item.city" :options="kabupatenList"
                                                    placeholder="Pilih Kota/Kabupaten" label="text" track-by="id"
                                                    class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                                    @update:modelValue="handleCityKirim(index)"
                                                    @click="handleProvinceKirim(index)"></multiselect>
                                                <multiselect v-model="item.district" :options="kecamatanList"
                                                    placeholder="Pilih Kecamatan" label="text" track-by="id"
                                                    class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                                    @update:modelValue="handleDistrictKirim(index)"
                                                    @click="handleCityKirim(index)"></multiselect>
                                                <multiselect v-model="item.sub_district" :options="kelurahanList"
                                                    placeholder="Pilih Kelurahan" label="text" track-by="id"
                                                    class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                                    @update:modelValue="handleSub_districtKirim(index)"
                                                    @click="handleDistrictKirim(index)">
                                                </multiselect>
                                                <input type="text" v-model="item.pos_code"
                                                    class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                                    placeholder="Kode Pos">
                                            </div>


                                        </div>
                                        <div class="mb-2 flex justify-between">
                                            <button type="button" @click="addAddressRow"
                                                class="underline underline-offset-4 font-redHat text-[#8F8F8F]">Tambah
                                                Alamat Pengirim</button>
                                            <button type="button" @click="deleteAddressRow(index)"
                                                class="underline underline-offset-4 font-redHat text-[#8F8F8F]">Hapus
                                                Alamat</button>
                                        </div>


                                    </div>


                                </div>

                                <div class="grid grid-cols-12 mb-[10rem]" v-show="active_page == 'deposit'">
                                    <div class="col-span-12">

                                        <div class="relative overflow-x-auto">
                                            <table class="w-full text-sm text-left rtl:text-right text-white ">
                                                <thead class="text-xs text-gray-700 bg-[#5FBAE6] ">
                                                    <tr>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-white font-redHat rounded-l-lg border-r border-r-white text-center">
                                                            Tanggal
                                                        </th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-white font-redHat  border-r border-r-white text-center">
                                                            Atas Nama
                                                        </th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-white font-redHat border-r border-r-white text-center">
                                                            Nama Bank
                                                        </th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-white font-redHat rounded-r-lg text-center">
                                                            Deposit
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="bg-white border-b rounded-lg"
                                                        v-for="(item, index) in currentData.deposit" :key="index">
                                                        <td scope="row"
                                                            class="px-2 py-2 font-medium text-gray-900 font-redHat border-l whitespace-nowrap text-center">
                                                            <input type="date" v-model="item.date"
                                                                class="w-full h-[30px]" placeholder="Tanggal"
                                                                @keydown.enter="focusNext($event, index)">
                                                        </td>
                                                        <td class="px-2 py-2 text-gray-900 font-redHat border">
                                                            <input type="text" v-model="item.name"
                                                                class="w-full h-[30px]" placeholder="Atas Nama"
                                                                @keydown.enter="focusNext($event, index)">
                                                        </td>
                                                        <td class="px-2 py-2 text-gray-900 font-redHat border">
                                                            <input type="text" class="w-full h-[30px]"
                                                                v-model="item.bank_name" placeholder="Nama Bank"
                                                                @keydown.enter="focusNext($event, index)">
                                                        </td>
                                                        <td
                                                            class="px-2 py-2 text-gray-900 font-redHat border-r text-center">
                                                            <input type="text" class="w-full h-[30px]"
                                                                @input="formatCurrency('amount', item.amount, index)"
                                                                v-model="item.amount" placeholder="Deposit"
                                                                @keydown.enter="focusNext($event, index)">
                                                        </td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>






                                <!-- riwayat pembelian -->
                                <div class="grid grid-cols-12 mb-[15rem] " v-show="active_page == 'riwayat_pembelian'"
                                    style="display: none;">
                                    <div class="col-span-12">
                                        <table class="w-full text-sm text-left rtl:text-right text-white ">
                                            <thead class="text-xs text-gray-700 bg-[#5FBAE6] ">
                                                <tr>
                                                    <th scope="col"
                                                        class="px-6 py-3 text-white font-redHat rounded-l-lg border-r border-r-white text-center">
                                                        No.Invoice
                                                    </th>
                                                    <th scope="col"
                                                        class="px-6 py-3 text-white font-redHat rounded-r-lg  border-r border-r-white text-center">
                                                        Total
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="bg-white border-b rounded-lg">
                                                    <td scope="row"
                                                        class="px-2 py-2 font-medium text-gray-900 font-redHat border-l whitespace-nowrap  text-center">
                                                        <!-- <input type="text"  class="w-full h-[30px]" placeholder="No.Invoice"> -->
                                                        <!-- {{ v-model="currentData.no_invoice" }} -->
                                                    </td>
                                                    <td class="px-2 py-2 text-gray-900 font-redHat border">
                                                        <!-- <input type="text" class="w-full h-[30px]" placehoder="Total" > -->
                                                        <!-- {{ v-model="currentData.total" }} -->
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div v-show="active_page !== 'riwayat_pembelian'"
                                class="mb-3 mt-[6rem] space-x-5 flex justify-end px-10 ">
                                <!-- <routerLink to="/customer">Batal</routerLink> -->
                                <button type="button" @click="back()"
                                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">
                                    Batal</button>
                                <button type="submit"
                                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, base_url_wilayah_indonesia, rupiahInput, rupiahoutput } from "@/assets/js/function";
// import {ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url_wilayah_indonesia} from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect';


export default {
    components: {

        Multiselect,

    },

    data() {
        return {
            currentData: {
                // umum: [
                //     {
                //         instagram: "",
                //         name: "",
                //         whatsapp: "",
                //         whatsapp: "",
                //         type: 'reguler',
                //         notes: "",
                //     }
                // ],
                // alamat: [
                //     {
                //         address: "",
                //         province: {},
                //         city: {},
                //         district: {},
                //         sub_district: {},
                //         pos_code: '',
                //     }
                // ],
                address: [
                    {
                        address: "",
                        province: {},
                        city: {},
                        district: {},
                        sub_district: {},
                        pos_code: '',
                        address_status: '',
                    },
                    {
                        address: "",
                        province: {},
                        city: {},
                        district: {},
                        sub_district: {},
                        pos_code: '',
                        address_status: '',
                    },


                ],
                // deposit: [
                //     {
                //         date: "",
                //         name: "",
                //         bank_name: "",
                //         amount: "",

                //     },


                // ],
                type: "reguler",
            },
            active_page: "umum",
            isActive: true,
            selectedButton: 'Reguler',
            selectedMainAddress: 'home',
            // for alamat
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],
        }
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }

        // if (!this.currentData.type) {
        //     this.currentData.type = 'Reguler'; // Set default type
        //     this.selectedButton = 'Reguler'; // Set default button
        // } else {
        //     this.selectedButton = this.currentData.type; // Set button based on existing type
        // }



        // console.log(this.config);
        // this.index();
        // this.dataDeposit = JSON.parse(localStorage.getItem('data_deposit'));
        this.provinsi_index();

    },

    methods: {
        toggleAddressStatus(type, index) {
            // Define the selected key based on the address type and index
            const selectedKey = type === 'home' ? 'home' : `pengiriman-${index}`;
            this.selectedMainAddress = selectedKey;
            // Loop through the addresses and set the selected one to 'MAIN' and others to ''
            this.currentData.address.forEach((addr, addrIndex) => {
                addr.address_status = (addrIndex === index) ? 'MAIN' : '';
            });
        },
        toggleCustomerType(type) {
            // If the checkbox is already selected, uncheck it (set selectedButton to empty string)
            if (this.selectedButton === type) {
                this.selectedButton = '';
                this.currentData.type = '';
            } else {
                // Set selectedButton to the selected type
                this.selectedButton = type;
                this.currentData.type = type;
            }
        },
        focusNext(event, index) {
            event.preventDefault();
            const inputs = Array.from(document.querySelectorAll('input')); // Get all input elements
            const currentIndex = inputs.indexOf(event.target); // Find the current input's index
            // console.log('ini index current :',currentIndex)
            // console.log("ini inputnya :", inputs)

            if (currentIndex !== -1 && inputs[currentIndex + 1]) {
                // Focus on the next input if it exists
                inputs[currentIndex + 1].focus();
            } else if (index === this.currentData.deposit.length - 1) {
                // Add a new row if the Enter key is pressed on the last input of the last row
                this.addNewRow();
                // CloseLoading();
            }
        },
        addNewRow() {
            // Add an empty object to currentData.deposit to create a new row
            // console.log('ada g??')
            this.currentData.deposit.push({ date: '', name: '', bank: '', deposit: '' });
        },
        addAddressRow() {
            this.currentData.address.push({
                alamat: "",
                province: null,
                city: null,
                district: null,
                sub_district: null,
                pos_code: ""
            });
        },
        deleteAddressRow(index) {
            this.currentData.address.splice(index, 1); // Remove the row at the specified index
        },
        back() {
            // Check if 'lastPage' exists in localStorage
            if (localStorage.getItem('history_page') === null) {
                goPage("customer");  // Default to customer page if no lastPage
            } else {
                const page = localStorage.getItem('history_page');
                localStorage.removeItem('history_page');  // Clear lastPage after retrieving
                goPage(page);  // Navigate back to the last page
            }
        },
        activePage(page) {
            this.active_page = page;
            localStorage.setItem("active_page", page)
        },

        formatCurrency(label, number, index) {
            this.currentData.deposit[index][label] = rupiahInput(number);

        },

        insert() {
            ShowLoading();
            let formData = new FormData();

            // Ensure customer_type is included in currentData
            this.currentData.type = this.selectedButton;

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            let addressArray = [];
            const allEmpty = this.currentData.address.every(item => item.address_status === '');
            if (allEmpty) {
                this.currentData.address[0].address_status = 'MAIN';
            }

            this.currentData.address.forEach((addr, index) => {
                let item_prepare = addr;
                // item_prepare.address = addr.address
                if (addr.province != null) {
                    item_prepare.province = addr.province.text;
                } else {
                    item_prepare.province = '';
                }
                if (addr.city != null) {
                    item_prepare.city = addr.city.text;
                } else {
                    item_prepare.city = '';
                }
                if (addr.district != null) {
                    item_prepare.district = addr.district.text;
                } else {
                    item_prepare.district = '';
                }
                if (addr.sub_district != null) {
                    item_prepare.sub_district = addr.sub_district.text;
                } else {
                    item_prepare.sub_district = '';
                }
                addressArray.push(item_prepare)
            });


            formData.delete("address");
            formData.append('address', JSON.stringify(addressArray));

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'customer_insert';
            } else {
                this.api = base_url + 'customer_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        if (localStorage.getItem('history_page') === null) {
                            goPage("customer");  // Default to customer page if no lastPage
                        } else {
                            const page = localStorage.getItem('history_page');
                            localStorage.removeItem('history_page');  // Clear lastPage after retrieving
                            goPage(page);  // Navigate back to the last page
                        }

                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },


        // for alamat rumah
        handleProvince(index) {
            this.kabupatenList = [];
            this.kecamatanList = [];
            this.kelurahanList = [];

            // Clear the selected city, district, and sub-district from the address as well
            this.currentData.address[index].city = null;
            this.currentData.address[index].district = null;
            this.currentData.address[index].sub_district = null;

            // const indexProvinsi = this.provinsiList.findIndex((record) => record.id === this.currentData.address[index].province.id);
            //     console.log(indexProvinsi)
            //     if (indexProvinsi > -1) {
            //         this.kabupaten_index(this.provinsiList[indexProvinsi].id);
            //     }

            const province = this.currentData.address[index].province;
            if (province && province.id) { // Ensure province exists and has an id
                const indexProvinsi = this.provinsiList.findIndex((record) => record.id === province.id);
                if (indexProvinsi > -1) {
                    this.kabupaten_index(this.provinsiList[indexProvinsi].id);
                }
            }



        },

        handleCity(index) {
            console.log('tes')
            this.kecamatanList = [];
            this.kelurahanList = [];

            this.currentData.address[index].district = null;
            this.currentData.address[index].sub_district = null;

            // const indexCity = this.kabupatenList.findIndex((record) => record.id === this.currentData.address[index].city.id);
            //     // console.log(index)
            //     if (indexCity > -1) {
            //         this.kecamatan_index(this.kabupatenList[indexCity].id);
            //     }

            const city = this.currentData.address[index].city;
            if (city && city.id) { // Ensure city exists and has an id
                const indexCity = this.kabupatenList.findIndex((record) => record.id === city.id);
                if (indexCity > -1) {
                    this.kecamatan_index(this.kabupatenList[indexCity].id);
                }
            }



        },

        handleDistrict(index) {
            console.log('ini index kecamatan', index)
            this.kelurahanList = [];

            this.currentData.address[index].sub_district = null;

            // const indexDistrict = this.kecamatanList.findIndex((record) => record.id === this.currentData.address[index].district.id);
            //     // console.log(indexDistrict)
            //     if (indexDistrict > -1) {
            //         this.kelurahan_index(this.kecamatanList[indexDistrict].id);
            //     }

            const district = this.currentData.address[index].district;


            if (district && district.id) { // Ensure district exists and has an id
                const indexDistrict = this.kecamatanList.findIndex((record) => record.id === district.id);

                if (indexDistrict > -1) {
                    this.kelurahan_index(this.kecamatanList[indexDistrict].id);
                }
            }




        },

        handleSub_district(index) {
            const sub_district = this.currentData.address[index].sub_district;
            if (sub_district && sub_district.id) { // Ensure sub-district exists and has an id
                const indexSub_district = this.kelurahanList.findIndex((record) => record.id === sub_district.id);
                if (indexSub_district > -1) {
                    // Perform your logic if needed
                }
            }

            // const indexSub_district = this.kelurahanList.findIndex((record) => record.id === this.currentData.address[index].sub_district.id);
            //     // console.log(indexSub_district)
            //     if (indexSub_district > -1) {
            //         // this.kelurahan_index(this.kecamatanList[indexDistrict].id);
            //         // this.currentData.address[index].sub_district = this.kecamatanList
            //     }


            // this.kelurahanList = [];

        },

        // for alamat pengiriman
        handleProvinceKirim(index) {
            this.kabupatenList = [];
            this.kecamatanList = [];
            this.kelurahanList = [];

            // Clear the selected city, district, and sub-district from the address as well
            this.currentData.address[index].city = null;
            this.currentData.address[index].district = null;
            this.currentData.address[index].sub_district = null;

            // const indexProvinsi = this.provinsiList.findIndex((record) => record.id === this.currentData.address[index].province.id);
            //     console.log(indexProvinsi)
            //     if (indexProvinsi > -1) {
            //         this.kabupaten_index(this.provinsiList[indexProvinsi].id);
            //     }

            const province = this.currentData.address[index].province;
            if (province && province.id) { // Ensure province exists and has an id
                const indexProvinsi = this.provinsiList.findIndex((record) => record.id === province.id);
                console.log(indexProvinsi);
                if (indexProvinsi > -1) {
                    this.kabupaten_index(this.provinsiList[indexProvinsi].id);
                }
            }



        },

        handleCityKirim(index) {
            console.log('tes')
            this.kecamatanList = [];
            this.kelurahanList = [];

            this.currentData.address[index].district = null;
            this.currentData.address[index].sub_district = null;

            // const indexCity = this.kabupatenList.findIndex((record) => record.id === this.currentData.address[index].city.id);
            //     // console.log(index)
            //     if (indexCity > -1) {
            //         this.kecamatan_index(this.kabupatenList[indexCity].id);
            //     }

            const city = this.currentData.address[index].city;
            console.log("Current selected district:", city); // Log the current selected district
            console.log("Current selected district ID:", city ? city.id : "No district selected"); // Log the district ID if available
            console.log("Current kecamatanList:", this.kabupatenList);

            if (city && city.id) { // Ensure city exists and has an id
                const indexCity = this.kabupatenList.findIndex((record) => record.id === city.id);
                console.log("Index of selected district in kabupatenList:", indexCity);
                if (indexCity > -1) {
                    this.kecamatan_index(this.kabupatenList[indexCity].id);
                }
            }



        },

        handleDistrictKirim(index) {
            console.log('ini index kecamatan', index)
            this.kelurahanList = [];

            this.currentData.address[index].sub_district = null;

            // const indexDistrict = this.kecamatanList.findIndex((record) => record.id === this.currentData.address[index].district.id);
            //     // console.log(indexDistrict)
            //     if (indexDistrict > -1) {
            //         this.kelurahan_index(this.kecamatanList[indexDistrict].id);
            //     }

            const district = this.currentData.address[index].district;
            console.log("Current selected district:", district); // Log the current selected district
            console.log("Current selected district ID:", district ? district.id : "No district selected"); // Log the district ID if available
            console.log("Current kecamatanList:", this.kecamatanList);

            if (district && district.id) { // Ensure district exists and has an id
                const indexDistrict = this.kecamatanList.findIndex((record) => record.id === district.id);
                console.log("Index of selected district in kecamatanList:", indexDistrict);
                if (indexDistrict > -1) {
                    this.kelurahan_index(this.kecamatanList[indexDistrict].id);
                }
            }




        },

        handleSub_districtKirim(index) {
            const sub_district = this.currentData.address[index].sub_district;
            if (sub_district && sub_district.id) { // Ensure sub-district exists and has an id
                const indexSub_district = this.kelurahanList.findIndex((record) => record.id === sub_district.id);
                if (indexSub_district > -1) {
                    // Perform your logic if needed
                }
            }

            // const indexSub_district = this.kelurahanList.findIndex((record) => record.id === this.currentData.address[index].sub_district.id);
            //     // console.log(indexSub_district)
            //     if (indexSub_district > -1) {
            //         // this.kelurahan_index(this.kecamatanList[indexDistrict].id);
            //         // this.currentData.address[index].sub_district = this.kecamatanList
            //     }


            // this.kelurahanList = [];

        },

        // for alamat rumah
        onProvinceSelect(selectedProvince) {
            this.item.province = selectedProvince;
            this.kabupatenList = []; // Reset city list
            this.kecamatanList = []; // Reset district list
            this.kelurahanList = []; // Reset sub-district list
            this.kabupaten_index(selectedProvince.id); // Load cities based on province
        },

        onCitySelect(selectedCity) {
            this.item.city = selectedCity;
            this.kecamatanList = []; // Reset district list
            this.kelurahanList = []; // Reset sub-district list
            this.kecamatan_index(selectedCity.id); // Load districts based on city


        },

        onDistrictSelect(selectedDistrict) {
            this.item.district = selectedDistrict;
            this.kelurahanList = []; // Reset sub-district list
            this.kelurahan_index(selectedDistrict.id); // Load sub-districts based on 

        },

        // for alamat pengiriman
        onProvinceSelectKirim(selectedProvince) {
            this.item.province = selectedProvince;
            this.kabupatenList = []; // Reset city list
            this.kecamatanList = []; // Reset district list
            this.kelurahanList = []; // Reset sub-district list
            this.kabupaten_index(selectedProvince.id); // Load cities based on province
        },

        onCitySelectKirim(selectedCity) {
            this.item.city = selectedCity;
            this.kecamatanList = []; // Reset district list
            this.kelurahanList = []; // Reset sub-district list
            this.kecamatan_index(selectedCity.id); // Load districts based on city


        },

        onDistrictSelectKirim(selectedDistrict) {
            this.item.district = selectedDistrict;
            this.kelurahanList = []; // Reset sub-district list
            this.kelurahan_index(selectedDistrict.id); // Load sub-districts based on 

        },

        provinsi_index() {
            ShowLoading();

            axios
                .post(base_url_wilayah_indonesia + "provinsi_index", '')
                .then((response) => {
                    if (response.data.status == true) {
                        // AlertPopup("success", response.data.message, "", 1500, false);
                        this.provinsiList = response.data.data.map((item) => ({
                            text: item.nama,
                            id: item.id,
                            value: item.nama,
                        }));

                    }
                    if (response.data.status == false) {
                        // AlertPopup("error", "", response.data.message, 1500, false);
                    }
                    CloseLoading();
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        kabupaten_index(id) {
            this.kabupatenList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);

            axios
                .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
                .then((response) => {
                    if (response.data.status == true) {

                        this.kabupatenList = response.data.data.map((item) => ({
                            text: item.nama,
                            id: item.id,
                            value: item.nama,
                        }))
                    } else {
                        //as
                    }
                    CloseLoading();
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });


        },
        kecamatan_index(id) {
            this.kecamatanList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);
            axios
                .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
                .then((response) => {
                    if (response.data.status == true) {
                        this.kecamatanList = response.data.data.map((item) => ({
                            text: item.nama,
                            id: item.id,
                            value: item.nama,
                        }))
                    } else {
                        //as
                    }
                    CloseLoading();
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });


        },

        kelurahan_index(id) {
            this.kelurahanList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);
            axios
                .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
                .then((response) => {
                    if (response.data.status == true) {
                        this.kelurahanList = response.data.data.map((item) => ({
                            text: item.nama,
                            id: item.id,
                            value: item.nama,
                        }))
                    } else {
                        //as
                    }
                    CloseLoading();
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });


        },



    }
}
</script>

<style scoped></style>