<template>
    <!-- <div class="bg-gray-200 justify-center items-center px-64 py-2 w-screen relative">
       
    </div> -->
    <img src="../../../assets/img/sudah-deposit.png" alt=""
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 " width="800"
        v-if="dataCustomer.total_deposit > 0">
    <img src="../../../assets/img/belum-deposit.png" alt=""
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 " width="800" v-else>
    <div class="card bg-white rounded-t-xl w-full shadow-md px-2 py-0">
        <div class="grid grid-cols-12 w-full items-center header-invoice mb-1">
            <div class="col-span-6 flex justify-start ">
                <img src="../../../assets/img/logo-hwang-1.png" alt="hwang-invoice" class="w-[100px] h-[100px] ">
            </div>
            <div class="col-span-6 flex justify-end text-right">
                <div class="text-right">
                    <h1 class="font-bold font-redHat text-[30px] uppercase ">Invoice </h1>
                    <div class="">
                        <span class="bg-[#5FBAE6] py-2 px-2 text-[19px] text-white">
                            {{ records[0].counter_customer }}
                        </span>
                    </div>
                    <p class="font-bold font-redHat text-[14px] mt-1">
                        <span>#PO{{ capitalizeFirstWord(records[0].no_po) }}</span>
                    </p>
                    <p class="font-bold font-redHat text-[14px]">Periode {{
                        formatDateRange(records[0].start_date, records[0].end_date) }}</p>
                </div>
            </div>

        </div>
        <div class="grid grid-cols-12 w-full items-start customer-data-invoice mb-1 ">
            <div class="col-span-4 flex justify-start">
                <div class="text-left">
                    <p class="font-bold font-redHat text-[14px]">Nama Customer</p>
                    <p class=" font-redHat text-[14px]">{{ dataCustomer.instagram }}<span
                            v-if="dataCustomer.name != ''"> / </span><span>{{ dataCustomer.name }}</span></p>
                    <p class=" font-redHat text-[14px]">{{ formatPhoneNumber(dataCustomer.whatsapp) }}</p>
                </div>

            </div>
            <div class="col-span-8 ">
                <div class="text-right">
                    <p class="font-bold font-redHat text-[14px]">Alamat Pengiriman</p>
                    <p class="font-redHat text-[14px]" v-if="dataCustomer.sub_district != undefined">
                        <span v-if="dataCustomer.sub_district != ''">
                            {{ dataCustomer.address }}
                            <br>
                            {{ dataCustomer.sub_district + ', ' +
                                dataCustomer.district + ', ' +
                                dataCustomer.city + ', ' +
                                dataCustomer.province + ' ' +
                                + ' ' + dataCustomer.pos_code }}
                        </span>
                        <span v-else>
                            {{
                                dataCustomer.address }}
                        </span>
                    </p>
                </div>
            </div>

        </div>
        <div class="grid grid-cols-12 w-full items-center customer-data-invoice mb-2">
            <div class="col-span-6 flex justify-start">
                <div class="text-left px-0">
                    <p class="font-bold font-redHat text-[13px]">Admin</p>
                    <p class="font-normal font-redHat text-[14px]">{{ nama_admin }}</p>

                </div>
                <!-- <p>{{ currentData.Instagram }}</p>
                    <p>{{ currentData.phone }}</p> -->
            </div>
            <div class="col-span-6 ">
                <div class="text-right px-0">
                    <p class="font-bold font-redHat text-[13px]">Ekspedisi</p>
                    <div class="font-normal font-redHat text-[14px]">{{ dataTotal.expedition }} <span
                            v-if="dataTotal.expedition_info != ''"> {{ '(' + dataTotal.expedition_info + ')' }}</span>
                    </div>

                </div>
            </div>

        </div>

        <!-- style="background-image: url('../../../assets/img/sudah-deposit.png'); bg-center bg-no-repeat opacity-10 pointer-events-none" -->
        <div class="grid grid-cols-12 w-full items-center customer-data-invoice mb-2 ">
            <div class="col-span-12 mb-1">
                <div class="table-header">
                    <p>List Orderan</p>
                </div>
            </div>
            <div class="col-span-12">
                <table class="w-full table-fixed mb-3 ">
                    <thead class="border-bottom border-black">
                        <tr class="bg-[#5FBAE626]/15  ">
                            <th class="w-10 py-4 px-1 text-start text-gray-600 font-semibold"
                                style="width:6%!important">
                                No</th>
                            <th class="w-20 py-4 px-1 text-start text-gray-600 font-semibold"
                                style="width:29%!important">
                                Order</th>
                            <!-- <th class="w-20 py-4 px-1 text-start text-gray-600 font-semibold"
                                style="width:15%!important">
                                Varian</th>
                            <th class="w-10 py-4 px-1 text-start text-gray-600 font-semibold"
                                style="width:10%!important">
                                Size</th> -->
                            <th class="w-10 py-4 px-1 text-start text-gray-600 font-semibold"
                                style="width:6%!important">
                                Qty</th>
                            <th class="w-auto py-4 px-1 text-start text-gray-600 font-semibold"
                                style="width: 13% !important;">
                                BY
                            </th>
                            <th class="w-auto py-4 px-1 text-start text-gray-600 font-semibold"
                                style="width:20%!important">
                                Harga Satuan</th>
                            <th class="w-auto py-4 px-1 text-end text-gray-600 font-semibold"
                                style="width:26%!important">
                                Total</th>
                        </tr>
                    </thead>
                    <tbody class="bg-white/30 border-b border-gray-200">
                        <tr v-for="(item, index) in records" :key="index">
                            <td class="py-2 px-2 text-start" v-if="item.qty > 0">{{ index + 1 }}</td>
                            <td class="py-2 px-2 text-start" v-if="item.qty > 0">{{ item.name }} {{ item.code }} / {{
                                item.color }} / {{
                                    item.size }}</td>
                            <!-- <td class="py-2 px-2 text-start"></td>
                            <td class="py-2 px-2 text-start"></td> -->
                            <td class="py-2 px-2 text-start" v-if="item.qty > 0">{{ item.qty }}</td>
                            <td class="py-2 px-2 text-start" v-if="item.qty > 0">
                                <span v-if="item.type == 'STORY_IG'">STORY IG</span>
                                <span v-else>LIVE IG</span>
                            </td>
                            <td class="py-2 px-2 text-start" v-if="item.qty > 0">{{ rupiah(item.normal_price) }}</td>
                            <td class="py-2 px-2 text-end" v-if="item.qty > 0">{{ rupiah(item.total) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-span-7">

            </div>
            <div class="col-span-5 me-2">
                <div class="flex items-center justify-between mb-1">
                    <p class="font-semibold font-redHat text-[14px] me-12 w-32 text-left">Total</p>
                    <p>{{ rupiah(dataTotal.total) }}</p>
                </div>
                <div class="flex items-center justify-between mb-1">
                    <p class="font-semibold font-redHat text-[14px] me-[100px] w-32 text-left ">Diskon
                    </p>
                    <p>{{ rupiah(dataTotal.diskon) }}</p>
                </div>
                <div class="flex items-center justify-between mb-1">
                    <p class="font-semibold font-redHat text-[14px] me-12 w-32 text-left">Total Barang</p>
                    <p>{{ dataTotal.qty }}</p>
                </div>
                <div class="flex items-center justify-between mb-1">
                    <p class="font-semibold font-redHat text-[14px] me-24 w-32 text-left">Total Berat Barang</p>
                    <p>{{ dataTotal.expedition === 'LION BIG PACK' && dataTotal.total_berat < 10000 ? 10 :
                        Math.floor(dataTotal.total_berat / 1000) + (dataTotal.total_berat % 1000 >= 300 ? 1 : 0) ||
                        (dataTotal.total_berat > 0 && dataTotal.total_berat < 1000 ? 1 : 0) }} Kg</p>
                </div>
                <div class="flex items-center justify-between mb-1">
                    <p class="font-semibold font-redHat text-[14px] me-12 w-32 text-left">Ongkos Kirim</p>
                    <p>{{ rupiah(dataTotal.shipping_cost) }}</p>
                </div>
                <div class="flex items-center justify-between mb-1">
                    <p class="font-semibold font-redHat text-[14px] me-9 w-32 text-left ">Subtotal</p>
                    <p>{{ rupiah(dataTotal.total - dataTotal.diskon + dataTotal.shipping_cost) }}</p>
                </div>
                <div class="flex items-center justify-between mb-1">
                    <p class="font-semibold font-redHat text-[14px] me-9 w-32 text-left ">Deposit</p>
                    <p>{{ rupiah(dataCustomer.total_deposit) }}</p>
                </div>
                <div class="flex items-center justify-between mb-1">
                    <p class="font-bold font-redHat text-[14px] me-12 w-32 text-left">Grand Total</p>
                    <p class="font-bold font-redHat text-[14px]">
                        <span>
                            {{ rupiah(dataCustomer.total_deposit - (dataTotal.total - dataTotal.diskon +
                                dataTotal.shipping_cost)) }}
                        </span>
                        <!-- <span v-else>
                                Rp 0,-
                            </span> -->
                    </p>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-12 w-full items-end  mb-4">
            <div class="col-span-6">
                <div class="w-full">
                    <div class="mb-3">
                        <h5 class="font-redHat font-bold">Metode Pembayaran</h5>
                    </div>
                    <div class="">
                        <p class="font-redHat ">Bank BCA</p>
                        <p class="font-redHat ">Account Name: CHRYSANTY YAPRILIA</p>
                        <p class="font-redHat ">Account No: 0222134895</p>
                    </div>
                </div>
            </div>

            <!-- watermark sign in -->
            <div class="col-span-6 flex justify-end">
                <div class="relative ">
                    <img src="../../../assets/img/logo-hwang.png" alt="" width="120"
                        class="opacity-25 transparant rotate-12 -translate-x-[-60px]">
                    <div class="absolute   h-full w-full flex items-center justify-center" style="top: 25%;">
                        <div class="ml-4 font-semibold font-redHat text-[14px]">
                            <hr class="border-t-2 border-[#F5CA40]">
                            Authorized Signed
                        </div>

                    </div>
                    <div class="font-semibold font-redHat text-[20px]">
                        <p>Thank you for shopping!</p>
                    </div>

                </div>
            </div>
        </div>


    </div>
    <!-- footer card -->
    <div class="card-footer w-full bg-[#5FBAE6] flex items-center justify-center h-[60px] text-sm text-white">
        <!-- <div class="flex items-center me-4">
                <div class="me-2">
                    <img src="../../../assets/img/telpon-icon.png" alt="" class="w-[14px] h-[14px]">
                </div>
                <p class="text-white">081234567890</p>
            </div>
            <div class="flex items-center ">
                <div class="me-2">
                    <img src="../../../assets/img/pin-icon.png" alt="" class="w-[14px] h-[14px]">
                </div>
                <p class="text-white">Medan, Indonesia</p>
            </div> -->
        powered by <img src="../../../assets/img/logoscbdwhite.png" alt="" width="80">
    </div>
</template>

<script>
import axios from "axios";
// import Swal from 'sweetalert2';
import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, rupiahInput, rupiah, goPage, DateFormat } from "@/assets/js/function";
// import {ErrorConnectionTimeOut,  ShowLoading, CloseLoading, base_url, AlertPopup,  rupiahInput,  rupiah } from "@/assets/js/function";
import Cookies from "js-cookie";
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import { data } from "autoprefixer";
export default {
    components: {
    },
    data() {
        return {
            searchQuery: '',
            records: [
                {
                    no_po: "",
                    total_deposit: 0,
                    start_date: "",
                    end_date: "",
                    nama_admin: "",
                }
            ],
            dataCustomer: {
                whatsapp: "",
            },
            nama_admin: "",
            dataTotal: [],
            currentData: [],
            listStatusPesanan: [],
            isModalOpen: false,
            currentDeleteIndex: null,
            config: '',
            filterDropdown: false,
            selectedType: 'LIVE_IG',
            date: '',
            invoice: '',
        }
    },
    watch: {
        // date: {
        //   handler(newVal) {
        //     this.index();
        //   },
        // }
    },
    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }


        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const noInvoice = urlParams.get('no_invoice');

        if (noInvoice) {
            this.indexInvoiceFromNoInvoice(noInvoice);
        } else {
            this.index();
            this.prepareData();
        }

    },

    computed: {
        filteredRecords() {
            if (!this.searchQuery) {
                return this.records;
            }
            return this.records.filter(item => {
                const searchLower = this.searchQuery.toLowerCase();
                return (
                    (item.instagram ? item.instagram.toLowerCase().includes(searchLower) : false) ||
                    (item.type ? item.type.toLowerCase().includes(searchLower) : false) ||
                    (item.name ? item.name.toLowerCase().includes(searchLower) : false)
                    // (item.product_code ? item.product_code.toLowerCase().includes(searchLower) : false) ||
                    // (item.color ? item.color.toLowerCase().includes(searchLower) : false) ||
                    // (item.size ? item.size.toLowerCase().includes(searchLower) : false) ||
                    // (item.price ? String(item.price).includes(searchLower) : false)
                );
            });
        }
    },
    mounted() {
        $(document).ready(function () {
            setTimeout(() => {
                window.print();

            }, 1000);

            window.addEventListener('onbeforeunload', function () {
                window.close();
            });

            window.addEventListener('afterprint', function () {
                window.close();
            });
        });
    },

    methods: {
        rupiah,
        goPage,
        formatPhoneNumber(phoneNumber) {
            if (phoneNumber == undefined) {
                phoneNumber = '';
            }
            // Check if the phoneNumber starts with '62'
            if (phoneNumber.startsWith('62')) {
                // Add '+' at the beginning and a space after the country code
                return '+' + phoneNumber.slice(0, 2) + ' ' + phoneNumber.slice(2);
            }
            return phoneNumber; // Return as is if it doesn't start with '62'
        },
        capitalizeFirstWord() {
            const noPo = this.records[0].no_po;
            if (!noPo) return "";
            const words = noPo.split(" ");
            words[0] = words[0][0].toUpperCase() + words[0].slice(1).toLowerCase();
            return words.join(" ");
        },
        formatDateRange(start_date, end_date) {
            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];

            // Convert strings to date objects
            const startDateObj = new Date(start_date);
            const endDateObj = new Date(end_date);

            // Extract day, month, and year
            const startDay = startDateObj.getDate();
            const endDay = endDateObj.getDate();
            const startMonth = monthNames[startDateObj.getMonth()];
            const endMonth = monthNames[endDateObj.getMonth()];
            const startYear = startDateObj.getFullYear();
            const endYear = endDateObj.getFullYear();

            // Check if months and years are the same
            if (startMonth === endMonth && startYear === endYear) {
                // If same month and year, return single range format
                return `${startDay}-${endDay} ${startMonth} ${startYear}`;
            } else if (startYear === endYear) {
                // If same year but different months
                return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
            } else {
                // If different years (edge case)
                return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
            }
        },
        formatDate(dateString) {
            // Remove the parentheses from the string
            const cleanDateString = dateString.replace(/[()]/g, '');
            // Split the date string by the '-' character
            const [year, month, day] = cleanDateString.split('-');
            // Return the date in the format 'DD/MM/YYYY'
            return `${day}/${month}/${year}`;
        },
        nameWithLang({ name }) {
            return `${name}`;
        },
        handleOrderChange(item, index) {
            ShowLoading();
            let formData = new FormData();
            Object.entries(item).forEach(([key, value]) => {   // for looping in table
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            formData.delete('id_order_status');
            formData.append('id_order_status', item.id_order_status.id);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'live_ig_update';
            } else {
                this.api = base_url + 'live_ig_update';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response)
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        this.records[index].status_order = '';
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        addRow() {
            // Mark the row as "Added"
            //   this.record[index].isAdded = true
            // Add a new row with a unique id
            this.records.push({
                id_customer: '',
                id_produk: '',
                color: '',
                size: '',
                qty: '',
                price: '',
                deposit: '',
                isAdded: false
            })
        },
        getIconClass(item) {
            // Determine the icon class based on whether the row is the last one
            // return item.total_deposit > item.price ? 'bi bi-check-circle-fill text-gray-400' : 'bi bi-check-circle-fill text-[#50C996]' ;
            // console.log(item.total_deposit)
            // console.log(item.price)
            if (item.total_deposit >= item.price) {
                //return 'bi bi-check-circle-fill text-[#50C996]'; // Green verified
                return true
            }
            else {
                //return 'bi bi-check-circle-fill text-gray-400'; // Gray unverified
                return false
            }
        },
        // checkingLastRow(index) {
        //     if(index == this.records.length - 1) {
        //         // this.records[index].isAdded = true;
        //         return true;
        //             // this.records.splice(index + 1, 0, );
        //     }else {
        //         // this.records[index].isAdded = false;
        //         return false;
        //     }
        // },
        formatCurrency(label, number, index) {
            this.records[index][label] = rupiahInput(number);
        },
        prepareData() {
            // this.records.total_deposit = rupiahInput(this.records.total_deposit);
            this.records.forEach((item) => {
                item.total_deposit = rupiahInput(item.total_deposit);
            });
        },
        editData(item) {
            // for batal edit customer page
            localStorage.setItem('currentData', JSON.stringify(item));
            goPage('editverifikasiliveig');
        },
        editCustomer(item) {
            // for batal edit customer page
            this.$router.push({ name: 'editcustomer' });
            sessionStorage.setItem('lastPage', 'verifikasi-live-ig');
            // console.log(item)
            localStorage.setItem('currentData', JSON.stringify(item.customer_detail));
            // Navigate to the Add Customer page
            this.$router.push({ name: 'editcustomer' });
        },
        filterRecords(type) {
            this.selectedType = type; // Save the selected filter type
            this.index(); // Call the index method to fetch filtered data
        },
        index() {
            ShowLoading();
            let formData = new FormData();
            formData.append('id_preorder', localStorage.getItem('id_preorder'))
            formData.append('id_customer', localStorage.getItem('id_customer'))
            formData.append('diskon', localStorage.getItem('diskon'))

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'generate_invoice';
            } else {
                this.api = base_url + 'generate_invoice';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response.data);
                    this.records = [];
                    if (response.data.status === true) {
                        this.records = response.data.data;
                        this.dataCustomer = response.data.customer_deposit[0];
                        this.dataTotal = response.data.total[0];
                        this.invoice = response.data.no_invoice;
                        this.date = response.data.date_invoice;
                        this.nama_admin = response.data.nama_admin;

                        document.title = response.data.data[0].counter_customer + ' (' + this.dataCustomer.name + ')';

                        // save to local storage
                        // localStorage.setItem('data_supplier', JSON.stringify(response.data.data_supplier));
                        // localStorage.setItem('data_product_detail', JSON.stringify(response.data.product_detail));
                        // if (response.data.status == true) {
                        //     this.data = [];
                        //     response.data.data.forEach((item, index) => {
                        //         let item_prepare = { ...item, no: (index + 1), };
                        //         this.data.push(item_prepare);
                        //     });
                        //     CloseLoading();
                        // }
                        CloseLoading();
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });
        },
        indexInvoiceFromNoInvoice(nomor_invoice) {
            ShowLoading();
            let formData = new FormData();
            formData.append('nomor_invoice', nomor_invoice)
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'get_invoice';
            } else {
                this.api = base_url + 'get_invoice';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    this.records = [];
                    if (response.data.status === true) {
                        this.records = response.data.data;
                        this.dataCustomer = response.data.customer_deposit[0];
                        this.dataTotal = response.data.total[0];
                        this.invoice = response.data.no_invoice;
                        this.date = response.data.date_invoice;
                        CloseLoading();
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });
        },
        openModalDelete() {
            // this.currentDeleteIndex = index;
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
            this.id_terminate = null;
        },
        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'live_ig_delete';
            } else {
                this.api = base_url + 'live_ig_delete';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.records.findIndex((item) => item.id === this.id_terminate); // Find item in the array by ID
                        this.records.splice(index, 1); // Remove the item from the array
                        CloseLoading();
                        this.closeModal(); // Close modal after deletion
                        AlertBottom(response.data.message);
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        terminate(id) {
            this.id_terminate = id;
            this.openModalDelete();
            // this.tanggal_terminate = new Date();
        },
        //   openModalDelete(index) {
        //       this.currentDeleteIndex = index;
        //       this.isModalOpen = true;
        //   },
        //   closeModal() {
        //       this.isModalOpen = false;
        //   },
        //   confirmDelete() {
        //       // Handle the actual deletion here
        //       if (this.currentDeleteIndex !== null) {
        //           this.records.splice(this.currentDeleteIndex, 1);  // Delete the item
        //           this.closeModal();  // Close the modal after deletion
        //       }
        //   },
    }
}
</script>

<style scoped>
/* Adjust positioning and z-index for the multiselect dropdown */
.multiselect__content {
    position: absolute;
    z-index: 9999;
    top: 100%;
}

.multiselect-full-width .multiselect__select {
    width: 100%;
}

.no-arrows {
    -moz-appearance: textfield;
    /* Firefox */
    appearance: none;
    /* Remove default appearance */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    /* Chrome, Safari, Edge */
    margin: 0;
    /* Remove margin */
}
</style>