<template>

  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 h-screen bg-[#5FBAE6]">
    <div class="left-side bg-[#5FBAE6] flex justify-center items-center py-4 hidden md:flex">

      <div class="flex flex-col items-center">
        <div class="top-logo-banner">
          <img src="@/assets/img/logo-hwang.png" alt="logo-hwang" class="h-[128px] w-[128px]">
        </div>
        <div class="banner-img">

          <img src="@/assets/img/login-img-edit.png" alt="banner-login" class="h-[465px] w-[812px]">
        </div>
      </div>
    </div>
    <div class="right-side p-4 md:p-0 lg:p-0">
      <div class="flex flex-col items-center  h-full pt-2 justify-center">
        <div class="w-full bg-[#F5CA40F2]/[0.95] mt-8 rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div class="text-center mb-8 pt-6">
              <h1 class="text-sm  md:text-[30px] font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Confirm OTP
              </h1>
            </div>
            <!-- <div class="mb-8">
                <div>
                    <h4>Please enter the OTP</h4>
                </div>

                <div>
                    Didn't get the OTP?
                    <a @click="sendOtp()" class="hover-a">send again</a>
                </div>
              </div> -->
            <form class="" @submit.prevent="onSubmit">
              <div class="mb-8">
                <label for="otp" class="block mb-2 text-lg font-redHat text-gray-900 ">OTP <span
                    class="text-red-500 font-redHat">*</span></label>
                <input type="text" id="otp" v-model="otp"
                  class="bg-gray-50 border border-white text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                  placeholder="" required>
              </div>
              <!-- <div>
                  <label for="password"
                    class="block mb-2 text-lg font-redHat  text-gray-900 dark:text-white">Password</label>
                  <input type="password" v-model="password" name="password" id="password" placeholder=""
                    class="bg-gray-50 border border-white text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 mb-8"
                    required>
  
                </div> -->

              <!-- for test only -->
              <!-- <RouterLink :to="{ name: 'dashboard' }">
                          </RouterLink> -->
              <div class="mb-8">
                <button type="submit"
                  class="w-full mb-6 text-black bg-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-[20px] text-sm px-5 py-2.5 text-center">Confirm
                  OTP</button>

              </div>

            </form>
            <div class="privacy-policy mb-8">
              <p class="text-center mb-4 font-redHat">Already have an account? <RouterLink to="/"
                  class="text-center font-bold font-redHat">Sign in.</RouterLink>
              </p>
              <p class="text-center">By continuing, you are agree to our <br>
                <a href="#" class="font-bold hover:text-white">Privacy Policy</a>
              </p>
            </div>
            <div class="scbd-bottom flex items-center justify-center mt-8">
              <img src="@/assets/img/mini-scbd-logo.png" alt="scbd-mini" class="w-[96px] h-[22px]">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import { ref } from "vue";
// import { useStore } from "vuex";
// import { Form, } from "vee-validate";
// import { router } from "../../../router";
// import VueRouter from "vue-router";
// import * as Yup from "yup";
// import { ref } from 'vue';
import axios from "axios";
// import Cookies from "js-cookie";
import Swal from 'sweetalert2';

import { goPage, ShowLoading, CloseLoading, base_url, timeout, ErrorConnectionTimeOut, AlertPopup } from "@/assets/js/function";
import Cookies from "js-cookie";

export default {

  data() {
    return {
      //   showPassword: false,
      showPassword: false,
      password: null,
      phone: "",
      config: '',

      timer: 0,
      //   useremailphone: "",
      //   showAdmin: true,
      //   showOwner: false,
      //   bodyAllowNotification: "",
      //   statusAllow: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },

  created() {
    const token = Cookies.get("token");



    if (token) {
      if (token !== 'null' && token.trim() !== '') {
        // Token is set and has a value
        this.config = {
          headers: { Authorization: `Bearer ` + token },
          timeout: 100000,
        };
        // AlertPopup("success", "", "OTP has been verified, please change your password", 2000, false);
      } else {
        // Token is set but is null or an empty string
        goPage('');
      }
    } else {
      // Token is not set
      goPage('');
    }

  },


  methods: {

    onSubmit() {
      ShowLoading();

      let formData = new FormData();

      //   formData.append("phone", Cookies.get('phone_email'));
      formData.append("otp", this.otp);
      //   formData.append("token", Cookies.get('token'));

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'confirm_otp';
      } else {
        this.api = base_url + 'confirm_otp';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          console.log(response);
          if (response.data.status == true) {
            Cookies.set("token", response.data.token);
            // Cookies.set("token", ""); // Optional, depending on your token handling logic
            CloseLoading();
            goPage('changepassword');
          } else {
            AlertPopup("error", "", response.data.message, 2000, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    // cekIsSendOTP() {
    //   let date_now = new Date();
    //   date_now = date_now.setSeconds(date_now.getSeconds() + 0);
    //   const date_local = parseInt(localStorage.getItem('SendOTPDate'));

    //   if (date_local < date_now) {
    //     localStorage.setItem('isSendOTP', 'true');

    //   } else {
    //     this.timer = parseInt(((date_now - date_local) / 1000) * -1);
    //     localStorage.getItem('isSendOTP', 'false');
    //   }
    // },

    // sendOtp() {
    //   ShowLoading();
    //   this.cekIsSendOTP();
    //   if (localStorage.getItem('isSendOTP') == "false") {
    //     AlertPopup("error", "", "coba lagi dalam " + this.timer + " detik", 2000, false);
    //     return true;
    //   }

    //   let formData = new FormData();

    //   formData.append("phone", Cookies.get("useremailphone"));

    //   let api = "";
    //   if (process.env.NODE_ENV === "development") {
    //     api = base_url + 'send_otp';
    //   } else {
    //     api = base_url + 'send_otp';
    //   }

    //   axios
    //     .post(api, formData, { timeout: timeout })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         Cookies.set("token", response.data.token);
    //         CloseLoading();
    //         // goPage('confirm-otp');
    //         AlertPopup("success", "", "OTP has been sent", 2000, false);
    //         formData.append("phone", localStorage.setItem('isSendOTP', 'false'));

    //         let currentDate2 = new Date();
    //         currentDate2 = currentDate2.setSeconds(currentDate2.getSeconds() + 120);
    //         // console.log(currentDate2);
    //         localStorage.setItem('SendOTPDate', currentDate2);

    //       } else {
    //         // this.showAlert(response.data.message);
    //         // this.email = "0" + this.email.substring(2);
    //         // this.isi_button = `<div class="fs-7">Login</div>`;
    //         AlertPopup("error", "", response.data.message, 2000, false);
    //       }
    //     })
    //     .catch((error) => {
    //       // this.isi_button = `<div class="fs-7">Login</div>`;

    //       ErrorConnectionTimeOut(error);
    //     });
    // }
  },





};

</script>