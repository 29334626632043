<template>
    <div class=" w-full h-screen bg-[#5FBAE6] flex flex-col items-center justify-center pt-5">
        <div class="block the-button mb-4 rounded-[14px] shadow">
            <RouterLink to="createliveig" class="text-black font-redHat font-bold text-lg flex items-center justify-center px-8 py-3 bg-[#F5CA40] rounded-[14px] w-[272px]">Buat Live Instagram</RouterLink>
        </div>
        <div class="block h-[350px] w-[500px] pt-5">
            <img src="@/assets/img/img-content.png" alt="bg-img">
        </div>
    </div>
</template>

<style scoped></style>

<script>
// import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, goPage, AlertBottom } from "@/assets/js/function";

export default {
    data() {
        return {

        records: [{
            // {
            // ig: '',
            // kode_produk: '',
            // warna: '',
            // size: '',
            // qty: '',
            // harga_jual: '',
            // berat: '',
            // isAdded: false // New row starts with "Add Row" button
            // }
            // id: '1',
            id_customer: 0,
            instagram: '',
            // id_product: '',
            code_product: '',
            color: '',
            size: '',
            qty: 0,
            price: 0,
            price_2: 0,
            weight: 0,
            selling_price: 0,
            total_weight: 0,
            showSelectOptionCustomer: true,

        }],

        recordDelete: [{
            id_customer: 0,
            instagram: '',
            code_product: '',
            color: '',
            size: '',
            qty: 0,
            price: 0,
            price_2: 0,
            weight: 0,
            selling_price: 0,
            total_weight: 0,
            showSelectOptionCustomer: true,
        }],


        options: [
            { size: 'XS' },
            { size: 'S' },
            { size: 'M' },
            { size: 'L' },
            { size: 'XL' },
            { size: '2XL' },
            { size: '3XL' },
            { size: 'FS' },
        ],
        showMultiselect: false,
        // currentData: [],

        // value: {name: '@IgUsername'},
        dataCustomer: [],
        dataProduct: [],
        dataColor: [],
        dataSize: [],

        isModalOpen: false,
        currentDeleteIndex: null,

        }
    },

    mounted() {
        // Check if 'records' exists and has data
        const records = JSON.parse(localStorage.getItem("records")) || [];
        
        if (records.length > 0) {
        // Redirect to 'dataLiveIg' page
        this.$router.push({ name: "dataliveig" });
        } else {
        // Redirect to 'createLiveIg' page
        this.$router.push({ name: "createliveig" });
        }
    },

    // methods: {

    // },
}

</script>