<template>
    <!-- <h1>navbar</h1> -->
    <header class="sticky inset-0 z-50  bg-[#5FBAE6] backdrop-blur-lg ">
        <nav class="mx-6 flex max-w-8xl gap-8 px-2 transition-all duration-200 ease-in-out lg:px-3 py-2">
            <div class="relative flex items-center ">
                <div class="mr-8">
                    <!-- <img  src="https://www.svgrepo.com/show/499831/target.svg" loading="lazy" style="color:transparent" width="32" height="32"></a> -->
                    <img src="@/assets/img/logo-hwang.png" loading="lazy" style="color:transparent" width="84"
                        height="84">
                </div>
            </div>
            <ul class="hidden md:flex items-center justify-center gap-6">
                <li class="pt-1.5 font-redHat font-bold text-lg text-black hover:text-white">
                    <RouterLink to="dashboard">Dashboard</RouterLink>
                </li>

                <li class="relative group pt-1.5 font-bold text-black font-redHat text-lg" @click="toggleMenu2"
                    ref="menu">
                    <a href="javascript:void(0)" class="flex items-center space-x-1">
                        <!-- @click="openMenu = !openMenu" -->
                        <span>Master Data </span>
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </a>
                    <!-- Dropdown Menu -->
                    <ul v-show="openMenu2" class="absolute bg-[#F1EDEA] shadow-lg mt-2 rounded-sm w-48 space-y-2 p-1">
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('admin')">
                            <!-- <RouterLink to="admin">Admin</RouterLink> -->
                            Admin
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('lokasipo')">
                            <!-- <RouterLink to="lokasipo">Lokasi PO</RouterLink> -->
                            Lokasi PO
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('kategoriproduk')">
                            <!-- <RouterLink to="kategoriproduk">Kategori Produk</RouterLink> -->
                            Kategori Produk
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('discount')">
                            <!-- <RouterLink to="kategoriproduk">Kategori Produk</RouterLink> -->
                            Diskon
                        </li>
                        <!-- <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('jenispenjualan')">
                            
                            Jenis Penjualan
                        </li> -->
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('supplier')">
                            <!-- <RouterLink to="supplier">Supplier</RouterLink> -->
                            Supplier
                        </li>
                        <!-- <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer" @click="navigateTo('statuspesanan')">
                            <RouterLink to="statuspesanan">Status Pesanan</RouterLink> 
                             Status Pesanan
                        </li> -->
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('po')">
                            <!-- <RouterLink to="po">PO</RouterLink> -->
                            PO
                        </li>
                    </ul>
                </li>

                <li class="pt-1.5 font-bold font-redHat text-lg text-black hover:text-white">
                    <RouterLink to="produk">Produk</RouterLink>
                </li>
                <li class="pt-1.5 font-bold font-redHat text-lg text-black hover:text-white">
                    <RouterLink to="customer">Customer</RouterLink>
                </li>
                <li class="relative group pt-1.5 font-bold text-black font-redHat text-lg" @click="toggleMenuReport"
                    ref="menu">
                    <a href="javascript:void(0)" class="flex items-center space-x-1">
                        <!-- @click="openMenu = !openMenu" -->
                        <span>Laporan</span>
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </a>
                    <!-- Dropdown Menu -->
                    <ul v-if="openMenuReport"
                        class="absolute bg-[#F1EDEA] shadow-lg mt-2 rounded-sm w-48 space-y-2 p-1">
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('dailyreport')">
                            <!-- <RouterLink to="admin">Admin</RouterLink> -->
                            Laporan Penjualan
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('productsalesreport')">
                            <!-- <RouterLink to="admin">Admin</RouterLink> -->
                            Laporan Penjualan Produk
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('depositreport')">
                            <!-- <RouterLink to="lokasipo">Lokasi PO</RouterLink> -->
                            Laporan Deposit
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('summaryreport')">
                            <!-- <RouterLink to="kategoriproduk">Kategori Produk</RouterLink> -->
                            Rekap Perlu di Beli
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('summaryboughtreport')">
                            <!-- <RouterLink to="kategoriproduk">Kategori Produk</RouterLink> -->
                            Rekap Sudah di Beli
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('listorderreport')">
                            Laporan List Orderan
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('packaginglistreport')">
                            Laporan Packing List
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('customersoldoutreport')">
                            Laporan Customer SoldOut
                        </li>
                    </ul>
                </li>

                <!-- finance -->
                <li class="relative group pt-1.5 font-bold text-black font-redHat text-lg" @click="toggleMenuFinance"
                    ref="menu">
                    <a href="javascript:void(0)" class="flex items-center space-x-1">
                        <!-- @click="openMenu = !openMenu" -->
                        <span>Finance</span>
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </a>
                    <!-- Dropdown Menu -->
                    <ul v-if="openMenuFinance"
                        class="absolute bg-[#F1EDEA] shadow-lg mt-2 rounded-sm w-48 space-y-2 p-1">
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('labarugi')">
                            <!-- <RouterLink to="admin">Admin</RouterLink> -->
                            Laba Rugi
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium cursor-pointer"
                            @click="navigateTo('bebanoperasional')">
                            <!-- <RouterLink to="lokasipo">Lokasi PO</RouterLink> -->
                            Beban Operasional
                        </li>


                    </ul>
                </li>
            </ul>

            <div class="flex-grow"></div>
            <div class="items-center justify-center gap-6 md:flex">

                <div class="relative inline-block text-left">

                    <button @click="profileDropdown = !profileDropdown" id="dropdownHoverButton"
                        class="inline-flex items-center space-y-2 gap-2 font-redHat text-lg font-bold"
                        ref="userDropdown">
                        <!-- profile photo -->
                        <img src="@/assets/img/profile-dummy.png" alt="Profile Photo"
                            class="w-12 h-12 rounded-full mr-2" />

                        <!-- username -->
                        <span v-if="username">{{ username }}</span>
                        <!-- <p>Admin</p> -->

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="h-4 w-4 ml-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </button>

                    <!-- dropdown -->
                    <ul v-if="profileDropdown"
                        class="absolute bg-[#F1EDEA] shadow-lg mt-2 rounded-sm w-40 space-y-2 p-1 z-50 overflow-auto"
                        ref="userMenu">
                        <!-- <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 border-b">
                            <a href="javascript.void(0)">Profile</a>
                        </li> -->
                        <li class="block px-4 py-2 text-red-500 font-redHat text-base hover:bg-[#5FBAE6]/80 cursor-pointer"
                            @click="handleLogout()">
                            <div class="cursor-pointer">Logout</div>
                        </li>
                    </ul>

                    <!-- <ul v-if="profileDropdown" class="absolute  bg-[#F1EDEA] shadow-lg mt-2 rounded-sm w-40 space-y-2 p-1">
                        
                        <li class="block px-4 py-2 text-red-500 font-redHat text-base hover:bg-[#5FBAE6]/80">
                            <div class="cursor-pointer" @click="handleLogout()">Logout</div>
                        </li>
                    </ul> -->








                </div>
            </div>
            <!-- <div class="relative flex items-center justify-center md:hidden">
                <button type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-6 w-auto text-slate-900"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path></svg>
                </button>
            </div> -->
        </nav>
    </header>




</template>

<style scoped></style>

<script>
// import { ref } from 'vue';
// import axios from 'axios';
import { goPage } from "@/assets/js/function";
import Cookies from "js-cookie";




export default {
    name: 'NavBar',

    data() {
        return {
            username: '',
            openMenu2: false,
            openMenuReport: false,
            openMenuFinance: false,
            profileDropdown: false,

        };
    },

    mounted() {
        this.username = localStorage.getItem('username');

        // Add event listener to detect outside clicks
        document.addEventListener('click', this.handleClickOutside);
    },

    methods: {
        goPage,
        toggleMenu2() {
            this.openMenu2 = !this.openMenu2;
            if (this.openMenuReport == true) {
                this.openMenuReport = false;
            } else if (this.openMenuFinance == true) {
                this.openMenuFinance = false;
            }
        },
        toggleMenuReport() {
            this.openMenuReport = !this.openMenuReport;
            if (this.openMenu2 == true) {
                this.openMenu2 = false;
            } else if (this.openMenuFinance == true) {
                this.openMenuFinance = false;
            }
        },
        toggleMenuFinance() {
            this.openMenuFinance = !this.openMenuFinance;
            if (this.openMenuReport == true) {
                this.openMenuReport = false;
            } else if (this.openMenu2 == true) {
                this.openMenu2 = false;
            }
        },

        handleClickOutside(event) {
            // If click is outside the menu, close it
            if (this.$refs.menu && !this.$refs.menu.contains(event.target)) {
                this.openMenu = false;
            }

            if (
                this.$refs.userMenu &&
                !this.$refs.userMenu.contains(event.target) &&
                !this.$refs.userDropdown.contains(event.target)
            ) {
                this.profileDropdown = false;
            }
        },

        navigateTo(route) {
            //   this.$router.push({ path: route });
            goPage(route)
        },

        // logout() {
        //   // Clear token from cookies or localStorage
        //   Cookies.expire('token');  

        //   // Clear Axios token
        //   delete axios.defaults.headers.common['Authorization'];

        //   // Redirect user to the login page
        //   this.$router.push('signin');
        // }

        handleLogout() {
            //logOut();  // Call the logOut function
            localStorage.removeItem('username');
            Cookies.set("token", "");
            goPage("");
        }
    },

    beforeDestroy() {
        // Remove the event listener when the component is destroyed
        document.removeEventListener('click', this.handleClickOutside);
    },




};
</script>