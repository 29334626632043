<template>
    <NavBar />
    <header class="lg:px-16 px-5 bg-white flex flex-wrap items-center py-3">
        <div class="flex-1 flex justify-between items-center pt-3">
            <a href="#" class="text-2xl font-redHat text-[#5FBAE6] font-bold">PO Story Instagram</a>
        </div>
    </header>
    <div class="px-5 py-4">
        <form @submit.prevent="insert">
            <div class="card rounded-[10px] border-1 border-[#5FBAE6]/30 p-4 h-full bg-[#FAF8F8]">
                <div class="grid grid-cols-12 ">
                    <div class="col-span-4 me-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">PO Batch <span
                                class="text-red-600">*</span></label>
                        <input type="text" v-model="currentData.preorder[0].no_po"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" disabled>
                    </div>
                    <div class="col-span-4 me-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">Periode <span
                                class="text-red-600">*</span></label>
                        <input type="text" v-model="currentData.periode"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" disabled>
                    </div>
                    <div class="col-span-4 me-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">Admin <span
                                class="text-red-600">*</span></label>
                        <input type="text" v-model="currentData.username"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" disabled>
                    </div>
                    <div class="col-span-4 me-3 mt-3">
                        <div class="flex items-center justify-between">
                            <label for="" class="block font-redHat text-gray-400 mb-2 me-2">Instagram <span
                                    class="text-red-600">*</span></label>
                            <button type="button" @click="addNewCustomer" class="text-[#5FBAE6] text-[22px]"><i
                                    class="bi bi-plus-circle-fill"></i></button> <!-- goPage to customer baru -->
                        </div>
                        <multiselect v-model="currentData.id_customer" :options="currentData.customer"
                            :custom-label="instagramWithLang" placeholder="" label="name" track-by="" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect>
                    </div>
                    <div class="col-span-4 me-3 mt-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">Nama Customer <span
                                class="text-red-600">*</span></label>
                        <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                            v-model="currentData.name" disabled>
                    </div>
                    <div class="col-span-4 me-3 mt-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">No. Whatsapp <span
                                class="text-red-600">*</span></label>
                        <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                            v-model="currentData.whatsapp" disabled>
                    </div>
                    <!-- alamat Pengiriman-->
                    <div class="col-span-12 mt-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">Alamat Pengiriman<span
                                class="text-red-600">*</span></label>
                        <div class="flex gap-3">
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="Alamat Pengiriman" v-model="currentData.address" disabled>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="Provinsi" v-model="currentData.province" disabled>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="Kota/Kabupaten" v-model="currentData.city" disabled>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="Kecamatan" v-model="currentData.district" disabled>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="Kelurahan" v-model="currentData.sub_district" disabled>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="kode pos" v-model="currentData.pos_code" disabled>
                        </div>
                    </div>

                    <!-- List Orderan -->
                    <div class="col-span-12 mt-4">
                        <div class="flex justify-between items-center ">
                            <label for="" class="block font-redHat text-black ">List Orderan</label>

                            <!-- <button type="button" @click="addNewProduct" class="text-[#5FBAE6] text-[22px]"><i
                                    class="bi bi-plus-circle-fill"></i></button>  -->
                        </div>
                        <table class="w-full text-sm text-left rtl:text-right text-[#8F8F8F] mb-4 ">
                            <thead class="text-xs text-gray-700 bg-[#F1F1F1] shadow-sm shadow-[#8F8F8F] mb-5">
                                <tr>
                                    <th scope="col"
                                        class="px-6 py-3 text-[#8F8F8F] font-redHat rounded-l-sm   text-center">
                                        No.
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat text-center  ">
                                        Tanggal
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat    text-center">
                                        Kode Produk
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat    text-center">
                                        Varian
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat    text-center">
                                        Keterangan
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat    text-center">
                                        Size
                                    </th>

                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat   text-center">
                                        Jenis Penjualan
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat   text-center">
                                        Qty
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat text-center  ">
                                        Harga Satuan
                                    </th>

                                    <th scope="col"
                                        class="px-6 py-3 text-[#8F8F8F] font-redHat rounded-r-sm text-center">
                                        Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr class="bg-white border-1 border-spacing-1 "
                                    v-for="(item, index) in currentData.list_order" :key="index"
                                    v-show="currentData.id_customer != null">
                                    <td scope="row"
                                        class="px-2 py-2 font-medium text-gray-900 font-redHat  whitespace-nowrap text-center">
                                        {{ index + 1 }}
                                        <button v-if="currentData.list_order.length > 1" @click="deleterow(index)"
                                            class="cursor-pointer"><i class="bi bi-trash text-red-400"></i></button>
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <input type="date" class="w-full h-[30px]" id="listOrderTable"
                                            v-model="item.date" placeholder="" :min="currentDate" :max="currentDate"
                                            @keydown.enter="focusNext($event, index)">
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <!-- <input type="text" v-model="item.id_product" class="w-full h-[30px]"
                                            id="listOrderTable" placeholder=""
                                            @keydown.enter="focusNext($event, index)"> -->
                                        <multiselect v-model="item.id_product" :options="currentData.product"
                                            :custom-label="nameWithLangProduct" placeholder="Pilih product" label="name"
                                            :track-by="'id'" :selectLabel="''" :deselectLabel="''" class=""
                                            @update:modelValue="afterProductChange(index)"> </multiselect>
                                        <!-- :class="{ 'border-red-500': validationErrors[index] }" -->
                                        <!-- <p v-if="validationErrors[index]" class="text-red-500 text-sm">This field is required.</p> -->
                                    </td>

                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <multiselect v-model="item.color" :options="currentDataColorList"
                                            :custom-label="colorWithLang" placeholder="Pilih product" label="name"
                                            :track-by="'color'" :selectLabel="''" :deselectLabel="''" class=""
                                            @open="afterProductChange(index)"
                                            @update:modelValue="handleColorChange(index)"
                                            :class="{ 'border-red-500': validationErrors[index] }"></multiselect>
                                        <p v-if="validationErrors[index]" class="text-red-500 text-sm">This field is
                                            required.</p>
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <input type="text" class="w-full h-[30px]" id="listOrderTable"
                                            v-model="item.info" placeholder="">
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <multiselect v-model="item.size" :options="currentDataSizeList"
                                            :custom-label="sizeWithLang" placeholder="Pilih product" label="name"
                                            :track-by="'size'" :selectLabel="''" :deselectLabel="''" class=""
                                            @open="handleColorChange(index)"></multiselect>
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <!-- <input type="text" class="w-full h-[30px]" id="listOrderTable"
                                            v-model="item.jenis_penjualan" placeholder=""
                                            @keydown.enter="focusNext($event, index)" disabled> -->
                                        <span>
                                            {{ item.jenis_penjualan }}
                                        </span>
                                    </td>

                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1 text-center">
                                        <input type="number" class="w-full h-[30px]" id="listOrderTable"
                                            v-model="item.qty" min="1" placeholder="0"
                                            @input="afterProductChange(index)"
                                            @keydown.enter="focusNext($event, index);">
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1 text-center">
                                        <span>
                                            {{ item.normal_price }}
                                        </span>
                                    </td>

                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1 text-center">
                                        <input type="text" class="w-full h-[30px]" id="listOrderTable"
                                            v-model="item.total" placeholder=""
                                            @keydown.enter="focusNext($event, index)" disabled>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Subtotal
                            </div>
                            <div class="text-[#8F8F8F]">
                                <span v-if="currentData.subtotal > 0">{{ rupiah(currentData.subtotal) }}</span>
                                <span v-else>Rp. 0,-</span>
                                <!-- {{ rupiah(currentData.sub_total) }} -->
                            </div>
                        </div>
                        <!-- <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Diskon
                            </div>
                            <div class="flex items-center ms-auto me-28">
                                <div
                                    class="border border-gray-500 w-[45px] h-[45px] flex items-center justify-center rounded-md me-2">
                                    Rp.</div>
                                <input type="text" class="w-full border-1 border-gray-500 rounded-md p-2"
                                    placeholder="0,-">
                            </div> 
                            <div class="text-[#8F8F8F]">
                                <span v-if="currentData.diskon > 0">{{ rupiah(currentData.diskon) }}</span>
                                <span v-else>Rp. 0,-</span>
                            </div>
                        </div> -->
                        <!-- <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Deposit
                            </div>
                            <div class="flex items-center ms-auto me-28">
                                <div
                                    class="border border-gray-500 w-[45px] h-[45px] flex items-center justify-center rounded-md me-2">
                                    Rp.</div>
                                <input type="text" class="w-full border-1 border-gray-500 rounded-md p-2"
                                    placeholder="0,-">
                            </div>
                            <div class="text-[#8F8F8F]">
                                Rp. 0,-
                            </div>
                        </div> -->
                        <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Grand Total
                            </div>
                            <div class="text-[#8F8F8F]">
                                <span v-if="currentData.subtotal > 0">{{ rupiah(currentData.subtotal) }}</span>
                                <span v-else>Rp. 0,-</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3 mt-[6rem] space-x-5 flex justify-end px-10 ">
                <button type="submit"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
                <button type="button" @click="insertNew()"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan
                    & baru</button>
                <button type="button" @click="back()"
                    class="bg-white text-xl font-redHat text-red-500 text-center py-2 px-4 rounded font-bold border-1 border-red-500 w-[240px]">
                    Batal</button>

            </div>
        </form>
    </div>

</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from "axios";
// import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, ShowLoading, goPage, CloseLoading, base_url, AlertPopup, AlertBottom, rupiah, rupiahInput, rupiahoutput } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect'

export default {
    components: {
        NavBar,
        Multiselect
    },
    data() {
        return {
            currentData: {
                preorder: [
                    {
                        no_po: "",
                    }
                ],
                customer: [
                ],
                product: [],
                periode: "",
                name: "",
                whatsapp: "",
                list_order: [
                    {
                        no: 1,
                        id_product: "",
                        jenis_penjualan: "LIVE_IG",
                        price: 0,
                        total: "",
                    }
                ],

                subtotal: 0,
                diskon: 0,
                deposit: 0,
                grand_total: 0,
            },

            //Data List 
            currentDataColorList: [],
            currentDataSizeList: [],

            // for alamat
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],

            currentDate: this.formatDate(new Date()),


            validationErrors: {}
        }
    },

    watch: {
        'currentData.id_customer'(newVal) {

            if (newVal != null && newVal.address?.[0]) {
                const mainAddress = newVal.address.find(record => record.address_status == 'MAIN') || {};
                this.currentData.address = mainAddress.address || '';
                this.currentData.province = mainAddress.province || '';
                this.currentData.city = mainAddress.city || '';
                this.currentData.district = mainAddress.district || '';
                this.currentData.sub_district = mainAddress.sub_district || '';
                this.currentData.pos_code = mainAddress.pos_code || '';
                this.currentData.whatsapp = newVal.whatsapp || '';
                this.currentData.name = newVal.name || '';
            } else {
                this.currentData.address = '';
                this.currentData.province = '';
                this.currentData.city = '';
                this.currentData.district = '';
                this.currentData.sub_district = '';
                this.currentData.pos_code = '';
                this.currentData.whatsapp = '';
                this.currentData.name = '';
            }
        },

        'currentData.list_order': {

            handler(newValue) {
                //subtotal
                this.currentData.subtotal = this.currentData.list_order.reduce((sum, order) => {
                    return sum + rupiahoutput(order.total);
                }, 0);

                //diskon 
                let arrProdukNormalPrice = [];
                this.currentData.diskon = 0;

                const listOrderCopy = JSON.parse(JSON.stringify(this.currentData.list_order)); // Non-reactive copy

                listOrderCopy.forEach((item) => {
                    if (item.id_product != null && item.id_product.type_price === 'NORMAL_PRICE' && item.type.value == 'STORY_IG') {
                        const index = arrProdukNormalPrice.findIndex((record) => record.id_product.id === item.id_product.id);
                        // per produk
                        // if (index > -1) {
                        //     arrProdukNormalPrice[index].qty += item.qty;
                        // } else {
                        //     arrProdukNormalPrice.push(item);
                        // }

                        //per total
                        if (index > -1) {
                            arrProdukNormalPrice[index].qty += item.qty;
                        } else {
                            arrProdukNormalPrice[index].qty += item.qty;
                        }
                    }
                });

                if (arrProdukNormalPrice.length > 0) {
                    //perproduk
                    // arrProdukNormalPrice.forEach((item) => {
                    //     const index = this.currentData.discount.findIndex((record) => {
                    //         // Convert `id_product` string to an array and ensure consistency with `item.id_product.id`
                    //         // const productIds = record.id_product.split(',').map(id => id.trim());

                    //         // productIds.some(productId => productId === String(item.id_product.id)) &&
                    //         return (record.qty <= item.qty && item.qty <= record.qty_max) &&
                    //             (record.type_discount.toLowerCase() === this.currentData.id_customer.type.toLowerCase());
                    //     });

                    //     if (index > -1) {
                    //         if (this.currentData.discount[index].type == 'TOTAL') {
                    //             this.currentData.diskon += this.currentData.discount[index].amount;
                    //         } else {
                    //             this.currentData.diskon += this.currentData.discount[index].amount * item.qty;
                    //         }
                    //     }
                    // });

                    //per total 
                    const total = arrProdukNormalPrice.reduce((sum, item) => sum + item.qty, 0);
                    const index = this.currentData.discount.findIndex((record) => {
                        return (record.qty <= total && total <= record.qty_max) &&
                            (record.type_discount.toLowerCase() == this.currentData.id_customer.type.toLowerCase());
                    });
                    if (index > -1) {
                        if (this.currentData.discount[index].type == 'TOTAL') {
                            this.currentData.diskon += this.currentData.discount[index].amount;
                        } else {
                            this.currentData.diskon += this.currentData.discount[index].amount * total;
                        }
                    }

                } else {
                    this.currentData.diskon = 0;
                }
                // this.currentData.diskon = this.currentData.list_order.reduce((sum, order) => {
                //     return sum + rupiahoutput(order.total);
                // }, 0);

                //grand total
                if (this.currentData.subtotal - this.currentData.diskon > 0) {
                    this.currentData.grand_total = this.currentData.subtotal - this.currentData.diskon;
                } else {
                    this.currentData.grand_total = 0;
                }


            },
            deep: true
        },

    },

    created() {

        const token = Cookies.get("token");

        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }

        this.index();


        // Load from localStorage when the component is created
        const savedLiveIg = localStorage.getItem('records_lo');
        if (savedLiveIg) {
            this.records = JSON.parse(savedLiveIg); // Load saved data into records
        } else {
            console.log('Data local not found')
        }
    },



    methods: {
        rupiah,
        nameWithLang({ name }) {
            return `${name}`
        },
        colorWithLang({ color }) {
            return `${color}`
        },
        sizeWithLang({ size }) {
            return `${size}`
        },
        instagramWithLang({ instagram }) {
            return `${instagram}`
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        // validateRequired(index) {
        //     const selected = this.currentData.product[index]?.id_product; // Adjust the path as needed
        //     if (!selected || selected.length === 0) {
        //         this.$set(this.validationErrors, index, true); // Mark field as invalid
        //     } else {
        //         this.$set(this.validationErrors, index, false); // Mark field as valid
        //     }
        // },

        validateForm() {
            // Validate all fields before submission
            this.currentData.product.forEach((item, index) => {
                this.validateRequired(index);
            });

            // Check if there are errors
            const hasErrors = Object.values(this.validationErrors).some(error => error);
            if (!hasErrors) {
                this.submitForm(); // Submit the form if valid
            }
        },


        afterProductChange(index) {
            if (this.currentData.list_order[index].id_product != null && this.currentData.list_order[index].id_product != undefined && this.currentData.list_order[index].id_product != '') {
                this.currentData.list_order[index].normal_price = rupiahInput(this.currentData.list_order[index].id_product.normal_price);
                this.currentData.list_order[index].total = rupiahInput(this.currentData.list_order[index].id_product.normal_price * this.currentData.list_order[index].qty);
                const index2 = this.currentData.product.findIndex((record) => record.id === this.currentData.list_order[index].id_product.id);

                this.currentDataColorList = this.currentData.product[index2].product_detail;


            }

            // this.dataProduct[index2];
            // let new_data = [];
            // new_data.push(this.dataRecordCurrent);

            // if (this.dataRecordCurrent.id_product > 0 && this.dataRecordCurrent.size != '' && this.dataRecordCurrent.color != '' && this.dataRecordCurrent.qty > 0) {
            //     this.records[index].qty = 0;
            //     this.records[index].size = '';
            //     this.deleteItemAvailibilityArraySummary(new_data);

            // }
            // //AVAILABLE SIZEEEE
            // const productId = this.records[index].id_product.id;
            // const filteredProductSummary = this.cek_product_summary.filter(item => item.id_product === productId);

            // // Step 2: Adjust quantities based on `cek_data_summary`
            // const adjustedSummary = filteredProductSummary.map(item => {
            //     // Find matching entry in cek_data_summary by color and size
            //     const match = this.cek_data_summary.find(data =>
            //         data.id_product == productId &&
            //         data.color == item.color &&
            //         data.size == item.size
            //     );
            //     // If a match is found, subtract the qty from cek_data_summary
            //     const adjustedQty = match ? item.qty - match.qty : item.qty;
            //     // Ensure the quantity doesn't go below zero
            //     return {
            //         ...item,
            //         qty: Math.max(0, adjustedQty)
            //     };
            // });

            // const availableSizes = adjustedSummary.filter(item => item.qty > 0);

            // availableSizes.forEach((item) => {
            //     if (item.color == this.records[index].color && item.size == this.records[index].size) {
            //         this.records[index].max_qty = item.qty;

            //     }
            // });

            // this.records[index].color = '';
            // this.records[index].size = '';
            // this.records[index].price = 0;
            // this.records[index].weight = 0;

        },
        handleColorChange(index) {
            if (this.currentData.list_order[index].color != null && this.currentData.list_order[index].color != undefined && this.currentData.list_order[index].color != '') {
                const indexProduct = this.currentData.product.findIndex((record) => record.id === this.currentData.list_order[index].id_product.id);
                const indexColor = this.currentData.product[indexProduct].product_detail.findIndex((record) => record.color === this.currentData.list_order[index].color.color);
                this.currentDataSizeList = this.currentData.product[indexProduct].product_detail[indexColor].sizeqty;
            }
            // this.validateRequired(index);

        },

        nameWithLangProduct({ code }) {
            return `${code}`
        },

        formatDateRange(start_date, end_date) {
            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];

            // Convert strings to date objects
            const startDateObj = new Date(start_date);
            const endDateObj = new Date(end_date);

            // Extract day, month, and year
            const startDay = startDateObj.getDate();
            const endDay = endDateObj.getDate();
            const startMonth = monthNames[startDateObj.getMonth()];
            const endMonth = monthNames[endDateObj.getMonth()];
            const startYear = startDateObj.getFullYear();
            const endYear = endDateObj.getFullYear();

            // Check if months and years are the same
            if (startMonth === endMonth && startYear === endYear) {
                // If same month and year, return single range format
                return `${startDay}-${endDay} ${startMonth} ${startYear}`;
            } else if (startYear === endYear) {
                // If same year but different months
                return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
            } else {
                // If different years (edge case)
                return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
            }
        },
        formatCurrency(label, number, index) {
            this.currentData.deposit[index][label] = rupiahInput(number);

        },
        focusNext(event, index) {
            event.preventDefault();
            const inputs = Array.from(document.querySelectorAll('#focusNext')); // Get all input elements
            const currentIndex = inputs.indexOf(event.target); // Find the current input's index

            if (currentIndex !== -1 && inputs[currentIndex + 1]) {
                // Focus on the next input if it exists
                inputs[currentIndex + 1].focus();
            } else if (index === this.currentData.list_order.length - 1) {
                // Add a new row if the Enter key is pressed on the last input of the last row
                this.addNewRow();
                // CloseLoading();
            }
        },
        addNewRow() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const day = String(currentDate.getDate()).padStart(2, '0');
            this.date = `${year}-${month}-${day}`;
            this.currentData.list_order.push({
                id_product: "",
                jenis_penjualan: "STORY IG",
                type: "STORY_IG",
                qty: "",
                total: 0,
                normal_price: rupiahInput(0),
                date: this.date,
                total: "",
            })
        },
        deleterow(index) {
            this.currentData.list_order.splice(index, 1);
        },
        addNewCustomer() {
            // for batal edit customer page
            localStorage.setItem('history_page', 'addlistorder'); // Replace 'current_page' with the current page identifier

            // Navigate to the addcustomer page
            goPage('addcustomer');

            // localStorage.setItem('currentData', JSON.stringify(item));
        },
        index() {
            ShowLoading();
            let formData = new FormData();

            formData.append('id_preorder', localStorage.getItem('id_preorder'))
            formData.append('type', 'STORY_IG')
            formData.append('type_sale', 'STORY_IG')

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'list_order_get';
            } else {
                this.api = base_url + 'list_order_get';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status === true) {
                        // this.currentData.deposit = response.data.deposit || [];
                        this.currentData = response.data;
                        this.currentData.periode = this.formatDateRange(this.currentData.preorder[0].start_date, this.currentData.preorder[0].end_date);

                        const currentDate = new Date();
                        const year = currentDate.getFullYear();
                        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                        const day = String(currentDate.getDate()).padStart(2, '0');


                        this.currentData.list_order = [
                            {
                                id_product: "",
                                jenis_penjualan: "STORY IG",
                                type: "STORY_IG",
                                qty: "",
                                total: 0,
                                normal_price: rupiahInput(0),
                                date: `${year}-${month}-${day}`,
                                total: "",
                            }
                        ]

                        CloseLoading();
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });
        },


        insert() {
            ShowLoading();
            let formData = new FormData();
            formData.append('id_preorder', localStorage.getItem('id_preorder'))
            formData.append('type', 'STORY_IG')

            let prepare_data = [];
            let zero_qty_data = [];
            let zero_product_data = [];

            this.currentData.list_order.forEach((item) => {
                // console.log('cek warna :', item.color.color)
                const prepare_item = { ...item, id_customer: this.currentData.id_customer.id, id_product: item.id_product?.id || null, color: item.color?.color || null, size: item.size?.size || null }
                if (item.qty == 0) {
                    zero_qty_data.push(prepare_item);
                } else if (!item.color || !item.color.color || !item.size || !item.size.size || !item.id_product.id || !item.id_product) {
                    zero_product_data.push(prepare_item)

                }
                else {
                    prepare_data.push(prepare_item);
                }
            });

            if (zero_qty_data.length > 0) {
                AlertPopup("error", "", "terdapat QTY yang kosong", 1500, false);
                return true;
            }

            if (zero_product_data.length > 0) {
                AlertPopup("error", "", "terdapat item product yang kosong", 1500, false);
                return true;
            }


            formData.append('data', JSON.stringify(prepare_data));



            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'list_order_insert';
            } else {
                this.api = base_url + 'list_order_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status === true) {
                        AlertBottom(response.data.message);
                        goPage('dashboard');
                        CloseLoading();
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });

        },
        insertNew() {
            ShowLoading();
            let formData = new FormData();
            formData.append('id_preorder', localStorage.getItem('id_preorder'))
            formData.append('type', 'STORY_IG')

            let prepare_data = [];
            let zero_qty_data = [];
            this.currentData.list_order.forEach((item) => {
                const prepare_item = { ...item, id_customer: this.currentData.id_customer.id, id_product: item.id_product.id, color: item.color.color, size: item.size.size }
                if (item.qty == 0) {
                    zero_qty_data.push(prepare_item);
                } else {
                    prepare_data.push(prepare_item);
                }
            });

            if (zero_qty_data.length > 0) {
                AlertPopup("error", "", "QTY tidak boleh kosong", 1500, false);
                return true;
            }

            formData.append('data', JSON.stringify(prepare_data));

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'list_order_insert';
            } else {
                this.api = base_url + 'list_order_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status === true) {
                        AlertBottom(response.data.message);
                        this.currentData = {
                            preorder: [
                                {
                                    no_po: "",
                                }
                            ],
                            customer: [
                            ],
                            product: [],
                            periode: "",
                            name: "",
                            whatsapp: "",
                            list_order: [
                                {
                                    no: 1,
                                    id_product: "",
                                    jenis_penjualan: "LIVE_IG",
                                    price: 0,
                                    total: "",
                                }
                            ],

                            subtotal: 0,
                            diskon: 0,
                            deposit: 0,
                            grand_total: 0,
                        }
                        this.index();


                        CloseLoading();
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });

        },

        // Define a method to reset all form fields
        clearForm() {
            this.currentData = {
                instagram: null,
                customer_detail: [],
                product_detail: [],
                address: []
                // Add other fields if necessary
            };
            this.selectedButton = null;
            this.date = '';
            this.type_rekap = '';
        },
        back() {
            goPage('')
        },
    },
}
</script>

<style scoped></style>