<template>
  <NavBar />
  <header class="lg:px-16 px-5 bg-white flex flex-wrap items-center py-3">
    <div class="flex-1 flex justify-between items-center">
      <div class="relative">
        <a href="javascript.void(0)" class="block text-3xl font-redHat text-[#F5CA40] font-bold">
          List Orderan
        </a>
        <p class="block text-xl font-redHat text-[#F5CA40] font-bold">Verifikasi Data</p>
      </div>
      <!-- <p class="block text-xl font-redHat text-[#F5CA40] font-bold">Story Instagram</p> -->
    </div>

    <div class="hidden md:flex md:items-center md:w-auto w-full" id="menu">
      <nav>
        <ul class="md:flex items-center justify-between text-base text-gray-700 pt-3 md:pt-0 gap-1">
          <li>
            <div
              class="relative flex items-center p-0 rounded-[8px] w-[250px] max-w-lg h-[40px] border-[0.5px] border-[#00000]">
              <input type="text" v-model="searchQuery" @input="filterTable"
                class="rounded-[8px] p-2.5 px-6 w-full h-full focus:border-blue-500 focus:ring focus:ring-blue-200 focus:outline-none"
                name="search" placeholder="Cari">

              <button type="submit"
                class="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 bg-white  p-2 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
              </button>
            </div>
          </li>
          <!-- <li>
            <button
              class="md:p-4 py-3 block flex items-center justify-center w-[200px] h-[40px] rounded-md bg-[#50C996] text-black py-2.5 font-semibold">
              Customer Baru
            </button>
          </li> -->
          <!-- <div class="relative p-0 rounded-[20px] w-[250px] max-w-lg"> -->
          <!-- <input type="text" class="rounded-[20px] p-3 w-full" name="search" placeholder="Cari"> -->
          <!-- </div> -->
          <!-- <li>
              <RouterLink to="addcustomer"
                class="md:p-4 py-3 block flex items-center justify-center w-[200px] h-[40px] rounded-md bg-[#50C996] text-black py-2.5 font-semibold"
              >
                Customer Baru
              </RouterLink>
            </li> -->

          <!-- <input type="date" v-model="item.date" class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" name="date"> -->
          <!-- <li>

            
            <ul v-if="filterDropdown"
              class="absolute transform -translate-x-2  bg-slate-100 shadow-lg mt-2 rounded-lg w-30 space-y-2 p-1">
              
              <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 hover:rounded-lg"
                :class="{ 'bg-[#5FBAE6]/80 rounded-lg': selectedType === 'STORY_IG' }">
                <div @click="filterRecords('STORY_IG')">List Order</div>
              </li>
              <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 hover:rounded-lg"
                :class="{ 'bg-[#5FBAE6]/80 rounded-lg': selectedType === 'LIVE_IG' }">
                <div @click="filterRecords('LIVE_IG')">Live Instagram</div>
              </li>
            </ul>
          </li> -->
        </ul>
      </nav>
    </div>
  </header>

  <div class="px-5 pt-5 mb-2">
    <!-- <div class="flex flex-col">
              <div class=" overflow-x-auto">
                  <div class="p-1.5 min-w-full inline-block align-middle">
                      <div class="">
                          
                          
                          
                      </div>
                  </div>
              </div>
          </div> -->


    <div class="overflow-scroll" style="max-height: 500px;">
      <!-- for datatable -->
      <table id="example" class="display">
        <thead style="position: sticky; top: 0; background-color: white; z-index: 1;">
          <tr>
            <th style="width: 100px;">No.</th>
            <th>Instagram</th>
            <th>Nama Customer</th>
            <th>Verifikasi</th>
            <th>Detail</th>
            <th>Deposit</th>
            <!-- <th>Sub total</th>
            <th>Diskon</th>
            <th>Ongkos kirim</th> -->
            <th>Total Sales</th>
            <th>Nama Admin</th>
            <th>Tipe</th>
            <!-- <th>Kode Produk</th>
            <th>Varian</th>
            <th>Size</th>
            <th>Qty</th>
            <th>Price</th>


            <th>Status Pesanan</th> -->

            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in records" :key="index" class="tr-table ">
            <td class="custom-td-left w-[50px]">{{ index + 1 }}</td>

            <!--<td><input type="text" v-model="item.ig" /></td> -->
            <td class="w-[250px]">
              <div class="m-2 flex gap-1">
                {{ item.instagram || 'N/A' }}
                <!-- @akuninstagramm -->
                <button @click="editCustomer(item)"
                  class="inline-flex items-center text-lg font-semibold disabled:opacity-50 disabled:pointer-events-none">
                  <i class="bi bi-person text-green-500" v-if="item.status_customer == 'COMPLETE'"></i>
                  <i class="bi bi-person" v-else></i>
                </button>
              </div>
            </td>
            <td class="w-[50px]">{{ item.name }}</td>
            <td class="w-[100px]">
              <!-- color green verified  #50C996 -->
              <div class="m-2 ">
                <button type="button"
                  class="inline-flex items-center text-lg ms-2 font-semibold disabled:opacity-50 disabled:pointer-events-none ">
                  <i :class="{
                    'text-[#50C996]': getIconClass(item) == 'GREEN', 'text-red-400': getIconClass(item) == 'RED',
                    'text-yellow-400': getIconClass(item) == 'YELLOW', 'text-gray-400': getIconClass(item) == 'GRAY',
                  }" class="bi bi-check-circle-fill">
                  </i>
                  <span class="d-none" v-if="getIconClass(item) == 'GREEN'">
                    4
                  </span>
                  <span class="d-none" v-if="getIconClass(item) == 'GRAY'">
                    1
                  </span>
                  <span class="d-none" v-if="getIconClass(item) == 'YELLOW'">
                    3
                  </span>
                  <span class="d-none" v-if="getIconClass(item) == 'RED'">
                    2
                  </span>

                </button>
              </div>
            </td>
            <td class="w-[100px]">
              <!-- color green verified  #50C996 -->
              <div class="m-2 ">
                <button type="button"
                  class="inline-flex items-center text-lg ms-2 font-semibold disabled:opacity-50 disabled:pointer-events-none ">
                  <span :class="{
                    'text-[#50C996]': getIconClass(item) == 'GREEN', 'text-red-400': getIconClass(item) == 'RED',
                    'text-yellow-400': getIconClass(item) == 'YELLOW', 'text-gray-400': getIconClass(item) == 'GRAY',
                  }">
                    <span v-if="getIconClass(item) == 'GREEN'">
                      LUNAS
                    </span>
                    <span v-if="getIconClass(item) == 'GRAY'">
                      BELUM DP
                    </span>
                    <span v-if="getIconClass(item) == 'YELLOW'">
                      SUDAH DP
                    </span>
                    <span v-if="getIconClass(item) == 'RED'">
                      LEBIH DP
                    </span>
                  </span>


                </button>
              </div>
            </td>
            <td class="w-[150px]">
              <div class="m-2">
                {{ rupiah(item.total_deposit) }}
              </div>
            </td>
            <!-- <td class="w-[160px]">
              <div class="m-2">
                {{ rupiah(item.total_sales) }}
              </div>
            </td>
            <td class="w-[160px]">
              <div class="m-2">
                {{ rupiah(item.diskon) }}
              </div>
            </td>
            <td class="w-[160px]">
              <div class="m-2">
                {{ rupiah(item.shipping_cost_total) }}
              </div>
            </td> -->
            <td class="w-[160px]">
              <div class="m-2">
                {{ rupiah(item.total_sales - item.discount + item.shipping_cost_total) }}
              </div>
            </td>
            <td class="w-[160px]">
              <div class="m-2">
                {{ item.nama_admin }}
              </div>
            </td>
            <td class=" w-[50px]">
              <span v-if="item.type == 'LIVE_IG'">LIVE IG</span>
              <span v-else>STORY IG</span>
            </td>
            <!-- <td class="w-[200px]">
              <div class="m-2">
                {{ item.product_code || 'N/A' }}
              </div>
            </td> -->
            <!-- <td class="w-[200px]">
              <div class="m-2">
                {{ item.color || 'N/A' }}
              </div>
            </td> -->
            <!-- <td class="w-[50px]">
              <div class="m-2">
                {{ item.size || 'N/A' }}
              </div>
            </td> -->
            <!-- <td class="w-[50px] py-0">
              <div class="m-2">
                {{ item.qty || 'N/A' }}
              </div>
            </td> -->
            <!-- <td class="w-[160px]">
              <div class="m-2">
                {{ rupiah(item.price_detail) }}
              </div>
            </td> -->
            <!-- <td class="w-[150px]">
              <div class="m-2">
                
                <multiselect v-model="item.order_status" :options="StatusPesananList" placeholder="pilih" label="name"
                  track-by="" :custom-label="nameWithLang" :selectLabel="''" :deselectLabel="''"
                  class="multiselect-full-width" @update:modelValue="handleOrderChange(item, index)"></multiselect>
              </div>

            </td> -->

            <td class="custom-td-right w-[150px]">
              <div class="flex justify-between">
                <!-- <button @click="editData(item)"
                  class="inline-flex items-center text-lg font-semibold disabled:opacity-50 disabled:pointer-events-none">
                  <!-- <i class="bi bi-pencil-fill"></i> 
                  <i class="bi bi-receipt-cutoff"></i>

                </button> -->

                <button @click="editData(item)"
                  class="inline-flex items-center text-lg font-semibold disabled:opacity-50 disabled:pointer-events-none">
                  <i class="bi bi-pencil"></i>
                </button>

                <button type="button" @click="terminate(item.id)" v-if="type == 'ADMIN'"
                  class="inline-flex items-center gap-x-2 text-lg font-semibold ms-2 text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                  <i class="bi bi-trash"></i>
                </button>
              </div>

              <!-- <button @click="addRow" class="mt-4 p-2 bg-blue-500 text-white rounded-md">Add New Row</button> -->

              <!-- <button type="button"  @click="openModalDelete(index)" class="inline-flex items-center gap-x-2 text-lg font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                          <i class="bi bi-plus-circle-fill"></i>
                                      </button> -->
            </td>
            <!-- <td>{{ item.position }}</td>
                                <td>{{ item.office }}</td>
                                <td>{{ item.age }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>


  </div>




  <!-- modal delete -->
  <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <!-- Modal content -->
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full" @click.stop>
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">Delete Data Rekap</h2>
        <button @click="closeModal" class="text-red-600 hover:text-gray-900">
          ✕
        </button>
      </div>
      <p class="text-gray-700 mb-10">
        Are you sure wants to delete this data ?
      </p>

      <div class="space-y-3">
        <div aria-hidden="true" class="border-t px-2"></div>
        <div class="button-group flex justify-end">
          <button @click="closeModal" class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
            Close
          </button>
          <button @click="delete2" class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import NavBar from '@/components/NavBar.vue'
import axios from "axios";
// import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, rupiahInput, rupiah, goPage } from "@/assets/js/function";
// import {ErrorConnectionTimeOut,  ShowLoading, CloseLoading, base_url, AlertPopup,  rupiahInput,  rupiah } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect'


export default {
  components: {
    NavBar,
    Multiselect
  },

  data() {
    return {
      searchQuery: '',
      // item: {
      //   date: this.getTodayDate() // Set default date as today
      // },

      records: [
        {
          total_deposit: '',
        }
      ],
      StatusPesananList: [
        { name: "PENDING" },
        { name: "CHANGE" },
        { name: "KEEP" },
        { name: "SOLDOUT" },
        { name: "BOOK" },
        { name: "OK" }
      ],

      currentData: [],
      listStatusPesanan: [],
      isModalOpen: false,
      currentDeleteIndex: null,
      config: '',
      filterDropdown: false,
      selectedType: 'LIVE_IG',

      type: "",
    }

  },

  watch: {
    // records: {
    //   handler(newVal) {
    //     console.log('tess');
    //   },
    //   deep: true
    // }
  },

  created() {
    const token = Cookies.get("token");
    // console.log(token)
    this.config = {
      headers: { Authorization: `Bearer ` + token },
      timeout: 60000,
    }

    // console.log(this.config);
    this.index();
    this.prepareData();

    this.showMultiselect = false;

    this.type = sessionStorage.getItem('type');
    localStorage.removeItem('needData');

    // Load from localStorage when the component is created
    // const savedLiveIg = localStorage.getItem('records');
    // if (savedLiveIg) {
    // this.records = JSON.parse(savedLiveIg); // Load saved data into records
    // console.log('Stored records:', JSON.parse(savedLiveIg));
    // }else {
    // console.log('No records found in localStorage.');
    // }
  },

  computed: {
    // filteredRecords() {
    //   return this.records.filter(item => {
    //     const searchLower = this.searchQuery.toLowerCase();
    //     return (
    //       (item.instagram?.toLowerCase().includes(searchLower) || false) ||
    //       (item.product_code?.toLowerCase().includes(searchLower) || false) ||
    //       (item.color?.toLowerCase().includes(searchLower) || false) ||
    //       (item.size?.toLowerCase().includes(searchLower) || false) ||
    //       (item.price?.toString().includes(searchLower) || false)
    //     );
    //   });
    // }

  },


  // mounted() {
  //     this.$root.initializeDataTable(); 

  // },

  methods: {
    rupiah,
    initializeDataTable() {
      // Ensure DataTable is initialized correctly
      // new DataTable('#example');
      // window.$('#example').DataTable();

      if (this.dataTable) {
        this.dataTable.destroy();  // Destroy previous DataTable instance
      }
      this.$nextTick(() => {
        this.dataTable = window.$('#example').DataTable();  // Reinitialize DataTable

        window.$('.dt-search').addClass('flex justify-end items-center');
        window.$('select').addClass('mr-3');
        window.$('.dt-paging').addClass('flex justify-end');
        window.$('.dt-search label').addClass('mr-3');
        window.$('.dt-search label').addClass('hidden');
        window.$('.dt-search input').addClass('hidden');
        window.$('.dt-info').addClass('hidden');
        window.$('.dt-length label').text('Entri Per Halaman');
        // window.$('.paginating').addClass('active:bg-blue-300');
      });

      // Optionally: Attach an event listener to default DataTables search input to reflect in custom input
      window.$('.dataTables_filter input').on('input', (e) => {
        this.searchQuery = e.target.value; // Update the custom search input if the default one is used
      });
      this.filterTable();

    },
    filterTable() {
      if (this.searchQuery == '') {

      } else {
        if (this.dataTable) {
          // Use DataTables' search() API to filter the table based on custom input
          this.dataTable.search(this.searchQuery).draw();

          // Sync the default search input with the custom input
          window.$('.dataTables_filter input').val(this.searchQuery);
        }
      }

    },
    nameWithLang({ name }) {
      return `${name}`;
    },
    handleOrderChange(item, index) {
      ShowLoading();
      let formData = new FormData();

      Object.entries(item).forEach(([key, value]) => {   // for looping in table
        formData.append(key, value);
      });


      // formData.delete('id_order_status');
      // formData.append('id_order_status', item.id_order_status.id);

      formData.delete('order_status');
      formData.append('order_status', item.order_status.name);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'live_ig_update';
      } else {
        this.api = base_url + 'live_ig_update';
      }

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          // console.log(response.data)
          if (response.data.status == true) {
            AlertBottom(response.data.message);

            CloseLoading();
          }
          if (response.data.status == false) {
            this.records[index].status_order = '';
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    addRow() {
      // Mark the row as "Added"
      //   this.record[index].isAdded = true

      // Add a new row with a unique id
      this.records.push({
        id_customer: '',
        id_produk: '',
        color: '',
        size: '',
        qty: '',
        price: '',
        deposit: '',
        isAdded: false
      })
    },

    getIconClass(item) {
      // console.log('ini deposit total: ', item.total_deposit)

      if (item.total_deposit == 0 || item.total_deposit == null) {
        return 'GRAY';
      } else if (item.total_deposit > (item.total_sales - item.discount + item.shipping_cost_total)) {

        return 'RED'
      } else if (item.total_deposit < (item.total_sales - item.discount + item.shipping_cost_total) && item.total_deposit > 0) {
        return 'YELLOW'
      } else {
        return 'GREEN'
      }

    },

    // checkingLastRow(index) {
    //     if(index == this.records.length - 1) {
    //         // this.records[index].isAdded = true;
    //         return true;

    //             // this.records.splice(index + 1, 0, );
    //     }else {
    //         // this.records[index].isAdded = false;
    //         return false;

    //     }
    // },

    formatCurrency(label, number, index) {
      this.records[index][label] = rupiahInput(number);

    },


    prepareData() {
      // this.records.total_deposit = rupiahInput(this.records.total_deposit);
      this.records.forEach((item) => {
        item.total_deposit = rupiahInput(item.total_deposit);
      });

    },
    editData(item) {
      // for batal edit customer page
      localStorage.setItem('currentData', JSON.stringify(item));
      // window.open('updatelistorder', 'updatelistorder');
      goPage('updatelistorder');
    },
    editCustomer(item) {

      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'))
      formData.append('id_customer', item.id_customer)

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'list_order_get_customer_detail';
      } else {
        this.api = base_url + 'list_order_get_customer_detail';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status === true) {
            localStorage.setItem('history_page', 'listorder');
            localStorage.setItem('currentData', JSON.stringify(response.data.data[0]));
            localStorage.setItem('needData', JSON.stringify(response.data.list_order));

            CloseLoading();
            goPage('editcustomer')

          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });



    },

    filterRecords(type) {
      this.selectedType = type; // Save the selected filter type
      this.index(); // Call the index method to fetch filtered data
    },



    index() {
      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'))

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'list_order';
      } else {
        this.api = base_url + 'list_order';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          console.log(response.data)
          if (response.data.status === true) {
            this.records = response.data.data;

            // localStorage.setItem('data_product', JSON.stringify(response.data.data_product));
            this.initializeDataTable();

            this.filteredRecords = this.records.filter(
              (item) => item.type === this.selectedType
            );

            // this.dataCustomer = response.data.customer; //to fetch customer
            // this.dataProduct = response.data.product; //to fetch product

            // this.listStatusPesanan = response.data.status_order;

            const index = this.listStatusPesanan.findIndex((record) => record.name === 'PENDING');
            if (index == -1) {
              this.listStatusPesanan.push({ id: 0, name: 'PENDING' });
            }

            // this.dataColor = response.data.data; //to fetch color
            //this.dataSize = response.data.data; //to fetch size


            this.records.forEach((item) => {
              const index = this.StatusPesananList.findIndex((status) => status.name == item.order_status); // Use `status` instead of `item`
              if (index !== -1) {
                item.order_status = this.StatusPesananList[index];  // Assign the matched status to item.id_order_status
              }
            });

            // search customer
            this.$nextTick(() => {
              // search customer
              if (localStorage.getItem('search_customer') != null) {
                this.searchQuery = localStorage.getItem('search_customer');
                this.filterTable();
                localStorage.removeItem('search_customer');
              }
            });

            // localStorage.setItem('data_product_detail', JSON.stringify(response.data.product_detail));


            // if (response.data.status == true) {
            //     this.data = [];

            //     response.data.data.forEach((item, index) => {
            //         let item_prepare = { ...item, no: (index + 1), };
            //         this.data.push(item_prepare);
            //     });

            //     CloseLoading();
            // }


            CloseLoading();
          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });
    },

    openModalDelete() {
      // this.currentDeleteIndex = index;

      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
      this.id_terminate = null;
    },

    delete2() {
      ShowLoading();
      let formData = new FormData();
      formData.append("id", this.id_terminate);


      // for (let [key, value] of formData.entries()) {
      //     console.log(`${key}: ${value}`);
      // }
      // CloseLoading()
      // return true;

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'live_ig_delete';
      } else {
        this.api = base_url + 'live_ig_delete';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {
            const index = this.records.findIndex((item) => item.id === this.id_terminate); // Find item in the array by ID
            this.records.splice(index, 1); // Remove the item from the array
            CloseLoading();
            this.closeModal(); // Close modal after deletion
            AlertBottom(response.data.message);

          } else {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });


    },

    terminate(id) {
      this.id_terminate = id;
      this.openModalDelete();
      // this.tanggal_terminate = new Date();

    },

    //   openModalDelete(index) {
    //       this.currentDeleteIndex = index;

    //       this.isModalOpen = true;
    //   },
    //   closeModal() {
    //       this.isModalOpen = false;
    //   },
    //   confirmDelete() {
    //       // Handle the actual deletion here
    //       if (this.currentDeleteIndex !== null) {
    //           this.records.splice(this.currentDeleteIndex, 1);  // Delete the item
    //           this.closeModal();  // Close the modal after deletion
    //       }
    //   },
  }
}
</script>

<style scoped>
/* Adjust positioning and z-index for the multiselect dropdown */
.multiselect__content {
  position: absolute;
  z-index: 9999;
  top: 100%;
}

.multiselect-full-width .multiselect__select {
  width: 100%;
}

.no-arrows {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: none;
  /* Remove default appearance */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* Chrome, Safari, Edge */
  margin: 0;
  /* Remove margin */
}
</style>